import React, { useEffect, useState } from "react";
import ApiEndPoints from "../ApiEndpoints";
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";

const responsive = [
  {
    breakpoint: 4000,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 3000,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 0,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

const CarouselPage2 = ({ memorial }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate()
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  return (
    <div>
      <div className="flex md:flex-wrap md:gap-[68px] sm:flex-col flex-row sm:gap-10 items-center justify-between mt-[34px] w-full">
        {memorial.slice(0, 5).map((item, index) => (
          <div key={index} className="relative" >
            <img
              className="h-[311px] sm:w-full md:w-[40%] w-[275px] md:h-auto object-cover rounded-[15px] cursor-pointer"
              src={ApiEndPoints.Image_URL + "/" + item.ProfileImgUrl}
              alt="User's Profile Picture"
              onClick={() => openModal(ApiEndPoints.Image_URL + "/" + item.ProfileImgUrl)}
            />
            <div   onClick={() => navigate("/Obituaries/" +item.MemorialAddress )} className="absolute w-full bottom-0 left-0 right-0 bg-gray-500/75 text-slate-950 text-center py-2">
              <p className="text-lg font-bold">{`${item.Firstname} ${item.MiddleName} ${item.LastName}`}</p>
            </div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
            border: 'none',
            background: 'none',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
      >
        <img
          src={selectedImage}
          alt="Full Size"
          style={{ maxHeight: '90vh', maxWidth: '90vw' }}
          onClick={closeModal}
        />
      </Modal>
    </div>
  );
};

export default CarouselPage2;
