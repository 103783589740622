import  { useState  , useRef} from "react";
import { Button, Img, Input, Line, Text } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/important.css";
import { useNavigate } from 'react-router-dom';
import { Header } from "../../components/Header";
import ApiEndPoints from "../../ApiEndpoints";
export default function Page() {


    const Naviagte = useNavigate()
    const formRef = useRef(null);



  const [fname ,setFname] =useState();
  const [lname ,setLname] =useState();
   const [usernemail , setUseremail] = useState();
   const [userPass , setUserPass] = useState();
 
   const handleChange = (e) => {
     const { name, value } = e.target;
   
     console.log("name", name);
     console.log("value", value);
   
     if (name === "email") {
       setUseremail(value);
       console.log("useremail", usernemail);
     } else if (name === "password") {
       setUserPass(value);
       console.log("userPass", userPass);
     }
     else if (name === "firstName") {
       setFname(value);
       console.log("userPass", fname);
     } else if (name === "lastName") {
       setLname(value);
       console.log("userPass", lname);
     }
   };
   
   const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
  
    // Check if all fields are filled
    if (!usernemail || !userPass || !fname || !lname) {
      toast("All fields are required");
      return;
    }
  
    console.log("email", usernemail, userPass, fname, lname);
    const response = await fetch(ApiEndPoints.register, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: usernemail,
        password: userPass,
        firstName: fname,
        lastName: lname,
      }),
    });
  
    if (response.status === 201) {
       setFname('');
       setLname('');
       setUserPass('');
       setUseremail('')

showResponse();

} else if (response.status === 400) {
      toast("Email already registered");
      setUseremail("");
      setUserPass("");
      setFname("");
      setLname("");
      formRef.current.reset();

    } else {
      toast("Please Try again Later");
      console.log("Registration failed");
    }
  
    console.log(response);
  };
  
  const showResponse=()=>{
    toast("Registered Successfully");
    console.log("Registered Successfully");
    setTimeout(()=>{
      Naviagte("/login");

    },1000)
  }
   
  
  return (
    <>
      <ToastContainer />
      <div className="bg-white-A700 flex flex-col font-playfairdisplay items-center justify-start mx-auto w-full">
        <div className="md:h-[1349px] h-[532px] sm:h-[959px] md:px-5 relative w-full">
          <Img
            className="h-[532px] m-auto object-cover w-full"
            src="images/img_bgcremationjpg_532x1600.png"
            alt="bgcremationjpg"
          />
          <div className="absolute bg-gradient  flex flex-col h-full inset-[0] items-start justify-center m-auto pb-[129px] w-full">
          <Header />
            <Text
              className="md:mx-[20px] ml-[162px] sm:mt-[20px] sm:mx-[20px] mt-[111px] md:text-5xl text-6xl text-gray-50"
              size="txtPlayfairDisplayRomanMedium60"
            >
              Sign in
            </Text>
            <Text
              className="md:mx-[20px] sm:mt-[20px] sm:mx-[20px]  ml-[167px] mt-3.5 text-3xl sm:text-[26px] md:text-[28px] text-black-900"
              size="txtPlayfairDisplayRomanMedium30"
            >
              <span className="text-white-A700 font-playfairdisplay text-left font-medium">
                Home
              </span>
              <span className="text-black-900 font-playfairdisplay text-left font-medium">
                {" "}
              </span>
              <span className="text-white-A700 font-playfairdisplay text-left font-medium">
                /
              </span>
              <span className="text-black-900 font-playfairdisplay text-left font-medium">
                {" "}
              </span>
              <span className="text-light_blue-A400 font-playfairdisplay text-left font-medium">
              Sign in
              </span>
            </Text>
          </div>
        </div>
        <div className="bg-white-A700 flex flex-col items-start justify-start max-w-[1168px] mt-[102px]  max-h-[800px]  mx-auto md:px-5 rounded-[20px] shadow-bs1 w-full">
          <div className="flex md:flex-row sm:flex-col flex-row md:gap-10  items-center justify-start w-full md:w-full">
            <div className="md:h-[862px] sm:h-[300px]  w-[48%] sm:w-full">
              {" "}
              <Img
                className="  object-cover rounded-bl-[20px] rounded-tl-[20px] h-[700px] w-full  md:h-full sm:rounded-[20px]"
                src="images/img_rectangle88.png"
                alt="rectangleEightyEight"
              />
            </div>
            <div className="flex flex-col gap-[19px] m-auto w-[48%] sm:w-full lg:w-full ">
              <div className=" sm:w-full sm:pr-0 md:pr-[30px] ">
                <Text
                  className="text-4xl text-center sm:text-[32px] md:text-[34px] text-blue_gray-700"
                  size="txtPlayfairDisplayBold36"
                >
                  Sign in
                </Text>
                <form  ref={formRef}  onSubmit={handleSubmit}>
                  <div className="flex flex-col items-start justify-start w-full">
                  <Text
                      className="text-blue_gray-700_01 mb-[10px] popinsfont text-xl"
                      size="txtPoppinsRegular20"
                    >
                      First Name
                    </Text>
                    <input
                      placeholder="Enter your first name"
                      className="popinsfont px-[25px] text-xl h-[47px] border-solid border-2 rounded-[10px] border-gray-600 leading-[normal] p-0 placeholder:text-gray-500_01 text-left text-xl w-full"
                      wrapClassName="border border-gray-600 border-solid mt-1.5 w-full"
                      type="text" name="firstName" onChange={handleChange}
                      shape="round"
                      size="sm"
                    />
                  <Text
                      className="text-blue_gray-700_01 mb-[10px] popinsfont text-xl"
                      size="txtPoppinsRegular20"
                    >
                      Last Name
                    </Text>
                    <input
                   type="text" name="lastName" onChange={handleChange} 
                   className="popinsfont px-[25px] text-xl h-[47px] border-solid border-2 rounded-[10px] border-gray-600 leading-[normal] p-0 placeholder:text-gray-500_01 text-left text-xl w-full"
                   wrapClassName="border border-gray-600 border-solid mt-1.5 w-full"
    
                      placeholder="Enter Last Name"
                      shape="round"
                      size="sm"
                    />
                    <Text
                      className="text-blue_gray-700_01 mb-[10px] popinsfont text-xl"
                      size="txtPoppinsRegular20"
                    >
                      Email
                    </Text>
                    <input
                      placeholder="Enter your email"
                      className="popinsfont px-[25px] text-xl h-[47px] border-solid border-2 rounded-[10px] border-gray-600 leading-[normal] p-0 placeholder:text-gray-500_01 text-left text-xl w-full"
                      wrapClassName="border border-gray-600 border-solid mt-1.5 w-full"
                      type="text"
                      name="email"
                      onChange={handleChange}
                      shape="round"
                      size="sm"
                    />
                    <Text
                      className="mt-[13px] popinsfont mb-[10px] text-blue_gray-700_01 text-xl"
                      size="txtPoppinsRegular20"
                    >
                      Password
                    </Text>
                    <input
                      placeholder="Enter your Password"
                      className="popinsfont px-[25px] text-xl h-[47px] border-solid border-2 rounded-[10px] border-gray-600 leading-[normal] p-0 placeholder:text-gray-500_01 text-left text-xl w-full"
                      wrapClassName="border border-gray-600 border-solid mt-1.5 w-full"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      shape="round"
                      size="md"
                    />
                    <button
                     className="common-pointer text-xl rounded-[10px] mont-regular bg-light_blue-A400 h-[47px] cursor-pointer flex items-center justify-center w-full sm:min-w-full mt-[14px] "
                     color="light_blue_A400"
                      size="xl"
                      variant="fill"
                      type="submit"
                    >
                      SignUp
                    </button>
                 
                    <Button
                      className="common-pointer text-xl rounded-[10px] mont-regular bg-red-600 h-[47px] cursor-pointer flex items-center justify-center w-full sm:min-w-full mt-[14px] "
                      color="light_blue_A400"                      onClick={() => Naviagte("/login")}
                    >
                      forget your Password
                    </Button>
                    <Button
className="common-pointer text-xl rounded-[10px] mont-regular bg-light_blue-A400 h-[47px] cursor-pointer flex items-center justify-center w-full sm:min-w-full mt-[14px] "
color="light_blue_A400"                      onClick={() => Naviagte("/login")}
                    >
                    Login
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <footer className="flex font-montserrat items-center justify-center mt-[65px] md:px-5 w-full">
          <div className="flex flex-col items-center justify-center w-full">
            <div className="md:h-[1039px] h-[381px] relative w-full">
              <Img
                className="h-[381px] m-auto object-cover w-full"
                src="images/img_rectangle4_1.png"
                alt="rectangleFour"
              />
              <div className="absolute bg-black-900_77 flex flex-col h-full inset-[0] items-center justify-center m-auto p-[63px] md:px-10 sm:px-5 w-full">
                <div className="flex md:flex-col flex-row md:gap-10 gap-[99px] items-center justify-start mb-[15px] w-4/5 md:w-full">
                  <div className="h-60 md:h-[193px] relative w-[41%] md:w-full">
                    <ul className="absolute bottom-[0] flex flex-col inset-x-[0] items-start justify-end mx-auto md:pr-10 sm:pr-5 pr-[110px] pt-[110px] w-full common-column-list">
                      <li>
                        <a href="" className="text-base text-white-A700">
                          <Text size="txtMontserratRegular16">
                            1802 Tuna Street, Westland, MI 48185
                          </Text>
                        </a>
                      </li>
                      <li>
                        <a
                          href=""
                          className="mt-[13px] text-base text-white-A700"
                        >
                          <Text size="txtMontserratRegular16">
                            1-800-123-45-67
                          </Text>
                        </a>
                      </li>
                      <li>
                        <a
                          href=""
                          className="mt-[15px] text-base text-white-A700 underline"
                        >
                          <Text size="txtMontserratRegular16">
                            milele@example.com
                          </Text>
                        </a>
                      </li>
                    </ul>
                    <Img
                      className="absolute h-32 left-[0] object-cover top-[0] w-[32%]"
                      src="images/img_mm121.png"
                      alt="mm121"
                    />
                  </div>
                  <div className="flex flex-col font-playfairdisplay gap-[29px] items-start justify-start px-3.5 w-[18%] md:w-full">
                    <Text
                      className="text-3xl sm:text-[26px] md:text-[28px] text-white-A700"
                      size="txtPlayfairDisplayBold30"
                    >
                      About
                    </Text>
                    <ul className="flex flex-col font-montserrat items-start justify-start md:w-full common-column-list">
                      <li>
                        <a href="" className="text-base text-white-A700">
                          <Text size="txtMontserratRegular16">Our Story</Text>
                        </a>
                      </li>
                      <li>
                        <a
                          href=""
                          className="mt-[15px] text-base text-white-A700"
                        >
                          <Text size="txtMontserratRegular16">
                            Gallery Regular
                          </Text>
                        </a>
                      </li>
                      <li>
                        <a href="" className="mt-3.5 text-base text-white-A700">
                          <Text size="txtMontserratRegular16">Services</Text>
                        </a>
                      </li>
                      <li>
                        <a
                          href=""
                          className="mt-[17px] text-base text-white-A700"
                        >
                          <Text size="txtMontserratRegular16">FAQ</Text>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="flex flex-col font-playfairdisplay gap-[27px] items-start justify-start px-[15px] w-[18%] md:w-full">
                    <Text
                      className="text-3xl sm:text-[26px] md:text-[28px] text-white-A700"
                      size="txtPlayfairDisplayBold30"
                    >
                      Links
                    </Text>
                    <ul className="flex flex-col font-montserrat gap-[17px] items-start justify-start md:w-full common-column-list">
                      <li>
                        <a href="" className="text-base text-white-A700">
                          <Text size="txtMontserratRegular16">Team</Text>
                        </a>
                      </li>
                      <li>
                        <a href="" className="text-base text-white-A700">
                          <Text size="txtMontserratRegular16">Pricing</Text>
                        </a>
                      </li>
                      <li>
                        <a href="" className="text-base text-white-A700">
                          <Text size="txtMontserratRegular16">Blog</Text>
                        </a>
                      </li>
                      <li>
                        <a href="" className="text-base text-white-A700">
                          <Text size="txtMontserratRegular16">Contacts</Text>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="flex flex-col font-playfairdisplay gap-6 items-start justify-start px-[15px] w-[18%] md:w-full">
                    <Text
                      className="text-3xl sm:text-[26px] md:text-[28px] text-white-A700"
                      size="txtPlayfairDisplayBold30"
                    >
                      Categories
                    </Text>
                    <ul className="flex flex-col font-montserrat gap-4 items-start justify-start md:w-full common-column-list">
                      <li>
                        <a href="" className="text-base text-white-A700">
                          <Text size="txtMontserratRegular16">Funeral</Text>
                        </a>
                      </li>
                      <li>
                        <a href="" className="text-base text-white-A700">
                          <Text size="txtMontserratRegular16">Burrial</Text>
                        </a>
                      </li>
                      <li>
                        <a href="" className="text-base text-white-A700">
                          <Text size="txtMontserratRegular16">Eco Funeral</Text>
                        </a>
                      </li>
                      <li>
                        <a href="" className="text-base text-white-A700">
                          <Text size="txtMontserratRegular16">Cremations</Text>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:h-[73px] h-[74px] relative w-full">
              <div className="absolute bg-black-900_01 h-[73px] inset-[0] justify-center m-auto w-full"></div>
              <div className="absolute flex flex-col h-full inset-[0] items-center justify-center m-auto p-[25px] sm:px-5">
                <Text
                  className="mt-0.5 text-base text-center text-white-A700"
                  size="txtMontserratRegular16"
                >
                  © 2024 Сopyright
                </Text>
              </div>
            </div>
          </div>
        </footer>
      </div>
          
    </>
  );
}