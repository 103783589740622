import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import './page1.css'
const Page = () => {
    return (
        <>
            <Header />
            <div className="pt-6">
                <h1 className="" style={{ fontSize: '30px', textAlign: 'center', paddingBottom: '20px' }}>
                    Plans and Features for Online Memorial Page</h1>
                <div className="w-[90%] bg-white-A700  m-auto py-10"
                    style={{ borderRadius: '20px' }}  >
                    <section class="bg-white dark:bg-gray-900">
                        <div class=" px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6"

                        >
                            <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                                <h2 class=" text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white"></h2>
                            </div>
                            <div

                                class="cardContainer"

                            >

                                {/* <div class="flex flex-col p-6 mx-auto  mx-4 max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <h3 class="mb-4 text-2xl font-semibold">BASIC</h3>
              <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">No credit card required.</p>
              <div class="flex justify-center items-baseline my-8">
                  <span class="mr-2 text-5xl font-extrabold">Free</span>
                  <span class="text-gray-500 dark:text-gray-400">/month</span>
              </div>
              
              <ul role="list" class="mb-8 space-y-4 text-left">
                  <li class="flex items-center space-x-3">
                     
                      <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>Individual configuration</span>
                  </li>
                  <li class="flex items-center space-x-3">
                     
                      <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>No setup, or hidden fees</span>
                  </li>
                  <li class="flex items-center space-x-3">
                     
                      <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>Our basic website features are always free.</span>
                  </li>
                 
              </ul>
              <a href="#" class="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Get started</a>
          </div> */}
                                <div class="flex flex-col p-6 mx-4   max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white "
                                    style={{
                                        border: '2px solid', borderColor: "black"
                                    }}
                                >
                                    <h3 class="mb-4 text-2xl font-semibold">BASIC</h3>
                                    <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">No credit card required.</p>
                                    <div class="flex justify-center items-baseline my-8">
                                        <span class="mr-2 text-5xl font-extrabold">Free</span>
                                        <span class="text-gray-500 dark:text-gray-400">/month</span>
                                    </div>

                                    <ul role="list" class="mb-8 space-y-4 text-left">
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>All features.</span>
                                        </li>
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>Full access to all premium website features <br></br> for one year.</span>
                                        </li>
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>Renews annually.</span>
                                        </li>

                                    </ul>
                                    <a href="#" class="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900" style={{ backgroundColor: 'rgb(59 130 246 / 50%)' }}>Get started</a>
                                </div>

                                <div class="flex flex-col p-6  mx-4   max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white "
                                    style={{ border: '2px solid', borderColor: "black" }}

                                >
                                    <h3 class="mb-4 text-2xl font-semibold">PREMIUM</h3>
                                    <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">$6.24 per month (save 36%)</p>
                                    <div class="flex justify-center items-baseline my-8">
                                        <span class="mr-2 text-5xl font-extrabold">$50.99 /yr</span>
                                        <span class="text-gray-500 dark:text-gray-400">/month</span>
                                    </div>

                                    <ul role="list" class="mb-8 space-y-4 text-left">
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>All features.</span>
                                        </li>
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>Full access to all premium website features <br></br> for one year.</span>
                                        </li>
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>Renews annually.</span>
                                        </li>

                                    </ul>
                                    <a href="#" class="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900" style={{ backgroundColor: 'rgb(59 130 246 / 50%)' }}>Get started</a>
                                </div>

                                <div class="flex flex-col p-6  mx-4  max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white "
                                    style={{
                                        border: '2px solid', borderColor: "black"
                                    }}
                                >
                                    <h3 class="mb-4 text-2xl font-semibold">LIFETIME</h3>
                                    <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">Best for large scale uses </p>
                                    <div class="flex justify-center items-baseline my-8">
                                        <span class="mr-2 text-5xl font-extrabold">$499</span>
                                        <span class="text-gray-500 dark:text-gray-400">/month</span>
                                    </div>

                                    <ul role="list" class="mb-8 space-y-4 text-left">
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>One-time payment</span>
                                        </li>
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>All features. Best value.</span>
                                        </li>
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>Full access to all premium website features
                                                <br></br>
                                                for life.

                                            </span>
                                        </li>

                                    </ul>
                                    <a href="#" class="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900" style={{ backgroundColor: 'rgb(59 130 246 / 50%)' }}>Get started</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className="pt-6">
                <h1 className="" style={{ fontSize: '30px', textAlign: 'center', paddingBottom: '20px' }}>
                    Plans and Features for Obituaries</h1>
                <div className="w-[90%] bg-white-A700  m-auto py-10"
                    style={{ borderRadius: '20px' }}  >
                    <section class="bg-white dark:bg-gray-900">
                        <div class=" px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                            <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                                <h2 class=" text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white"></h2>
                            </div>
                            <div class="cardContainer"   >
                                <div class="flex flex-col p-6 mx-4   max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white "
                                    style={{
                                        border: '2px solid', borderColor: "black"
                                    }}
                                >
                                    <h3 class="mb-4 text-2xl font-semibold">BASIC</h3>
                                    <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">No credit card required.</p>
                                    <div class="flex justify-center items-baseline my-8">
                                        <span class="mr-2 text-5xl font-extrabold">Free</span>
                                        <span class="text-gray-500 dark:text-gray-400">/month</span>
                                    </div>

                                    <ul role="list" class="mb-8 space-y-4 text-left">
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>All features.</span>
                                        </li>
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>Full access to all premium website features <br></br> for one year.</span>
                                        </li>
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>Renews annually.</span>
                                        </li>

                                    </ul>
                                    <a href="#" class="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900" style={{ backgroundColor: 'rgb(59 130 246 / 50%)' }}>Get started</a>
                                </div>

                                <div class="flex flex-col p-6  mx-4   max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white "
                                    style={{ border: '2px solid', borderColor: "black" }}

                                >
                                    <h3 class="mb-4 text-2xl font-semibold">PREMIUM</h3>
                                    <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">$6.24 per month (save 36%)</p>
                                    <div class="flex justify-center items-baseline my-8">
                                        <span class="mr-2 text-5xl font-extrabold">$50.99 /yr</span>
                                        <span class="text-gray-500 dark:text-gray-400">/month</span>
                                    </div>

                                    <ul role="list" class="mb-8 space-y-4 text-left">
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>All features.</span>
                                        </li>
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>Full access to all premium website features <br></br> for one year.</span>
                                        </li>
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>Renews annually.</span>
                                        </li>

                                    </ul>
                                    <a href="#" class="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900" style={{ backgroundColor: 'rgb(59 130 246 / 50%)' }}>Get started</a>
                                </div>

                                <div class="flex flex-col p-6  mx-4  max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white "
                                    style={{
                                        border: '2px solid', borderColor: "black"
                                    }}
                                >
                                    <h3 class="mb-4 text-2xl font-semibold">LIFETIME</h3>
                                    <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">Best for large scale uses </p>
                                    <div class="flex justify-center items-baseline my-8">
                                        <span class="mr-2 text-5xl font-extrabold">$499</span>
                                        <span class="text-gray-500 dark:text-gray-400">/month</span>
                                    </div>

                                    <ul role="list" class="mb-8 space-y-4 text-left">
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>One-time payment</span>
                                        </li>
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>All features. Best value.</span>
                                        </li>
                                        <li class="flex items-center space-x-3">

                                            <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>Full access to all premium website features
                                                <br></br>
                                                for life.

                                            </span>
                                        </li>

                                    </ul>
                                    <a href="#" class="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900" style={{ backgroundColor: 'rgb(59 130 246 / 50%)' }}>Get started</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Page;
