import React, { useState, useEffect } from 'react';
import './AdminstyleNew.css';
import { RxDashboard } from 'react-icons/rx';
import { SiGoogleforms } from 'react-icons/si';
import { CiCircleList } from 'react-icons/ci';
import { IoIosSettings } from 'react-icons/io';
import { CiLogout } from 'react-icons/ci';
import { GrUserAdmin } from 'react-icons/gr';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Hamburger from 'hamburger-react';

function AdminSidebar() {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    navigate('/login');
    window.localStorage.clear();
  };

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       navigate('/404'); // Navigate to 404 page if token is not present
//     }
//   }, []);




  return (

      <div class="sidebar">
        
        <div class="logo-details">
          <i class='bx bxl-c-plus-plus'></i>
          <GrUserAdmin style={{ color: 'white' }} />
          <span class="logo_name mx-3" style={{ fontSize: '15px' }}>Admin</span>
        </div>
        <ul class="nav-links">
          <li>
            <Link to="/adminhome" className={location.pathname === '/adminhome' ? 'active' : ''}>
              <RxDashboard class='mx-3' style={{ color: 'white' }} />
              <span class="links_name mx-3">Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/adminmemorial" className={location.pathname === '/adminmemorial' ? 'active' : ''}>
              <SiGoogleforms class='mx-3' style={{ color: 'white' }} />
              <span class="links_name mx-3"> Memoriails</span>
            </Link>
          </li>
          <li>
            <Link to="/adminmobituiry" className={location.pathname === '/adminmobituiry' ? 'active' : ''}>
              <CiCircleList style={{ color: 'white' }} class='mx-3' />
              <span class="links_name mx-3"> Obituaris</span>
            </Link>
          </li>
          <li>
            <Link to="/admintestimonials" className={location.pathname === '/admintestimonials' ? 'active' : ''}>
              <IoIosSettings style={{ color: 'white' }} class='mx-3' />
              <span class="links_name mx-3"> Testimonials</span>
            </Link>
          </li>
          <li>
            <Link to="/adminUsers" className={location.pathname === '/adminUsers' ? 'active' : ''}>
              <IoIosSettings style={{ color: 'white' }} class='mx-3' />
              <span class="links_name mx-3"> Users</span>
            </Link>
          </li>
          <li>
            <Link to="/adminNotification" className={location.pathname === '/adminNotification' ? 'active' : ''}>
              <IoIosSettings style={{ color: 'white' }} class='mx-3' />
              <span class="links_name mx-3"> Help Center</span>
            </Link>
          </li>
          <li>
            <a onClick={handleLogout} style={{ color: 'white', cursor: 'pointer' }}>
              <CiLogout style={{ color: 'white' }} class='mx-3' />
              <span class="links_name mx-3">Logout</span>
            </a>
          </li>
        </ul>
      </div>
   
  );
}

export default AdminSidebar;
