import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Img, Input, Text } from "../../components";
import "../../styles/important.css";
import MyCarousel from "../Slider";
import MyCarousel2 from "../Slider2";

import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import PinnedObituariesSilder from "../../components/PinnedObituariesSilder";
import ApiEndPoints from "../../ApiEndpoints";
import { isUserLoggedIn } from "../../utils/auth";
import moment from "moment";

const responsive = [
  {
    breakpoint: 4000,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 3000,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 0,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

export default function Home() {
  const Naviagte = useNavigate()
  const loggedIn = isUserLoggedIn();
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: responsive,
  };
  const [obituaries, setObituaries] = useState([])
  const [dailyObituariesDatas, setDailyObituariesData] = useState([]);
  const [pinnedMemorialData, setPinnedMemorialData] = useState([]);
  const [memorialData, setMemorialData] = useState([]);



  const fetchObituaries = async (event) => {


    try {
      const response = await fetch(ApiEndPoints.getObituary);

      if (response.ok) {
        const data = await response.json();
        console.log(" get Obituaries successfully:", data);
        setObituaries(data)
      } else {
        const errorData = await response.json();
        console.error("Error creating memorial:", errorData.error);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const fetchPinnedData = async () => {
    try {
      const response = await fetch(ApiEndPoints.getMemorial + '?type=pinned');
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setPinnedMemorialData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDailyData = async () => {
    try {
      const response = await fetch(ApiEndPoints.getMemorial + '?type=Daily');
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setMemorialData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };




  useEffect(() => {
    fetchObituaries();
    fetchDailyData();
    fetchPinnedData();
  }, [])


  useEffect(() => {
    const today = moment().format('YYYY-MM-DD'); // Get today's date in "YYYY-MM-DD" format
    console.log(today);
    const filteredObituaries = obituaries.filter(obituary => {
      if (obituary.CreatedOn) {
        const obituaryDate = moment(obituary.CreatedOn).format('YYYY-MM-DD');
        console.log(obituaryDate, today);
        return obituaryDate === today;
      }
      return false; // If CreatedOn is null or undefined, exclude the obituary from the filtered list
    });
    setDailyObituariesData(filteredObituaries);
    console.log("dailyObe", filteredObituaries);
  }, [obituaries]);

  console.log("dailyObituariesDatas", dailyObituariesDatas);
  
  return (
    <  >
      <div className="bg-white-A700 flex flex-col  font-montserrat items-center justify-start mx-auto w-full overflow-x-hidden">
        <div className="sm:h-[837px] md:h-[1682px] h-[705px] md:px-5 relative w-full">
          <div
            className="absolute  bg-center bg-no-repeat flex sm:h-[837px] md:h-[1682px] h-[705px] inset-[0] justify-end m-auto w-full"
            style={{
              backgroundSize: "100% 100%",
              backgroundImage: "url('images/img_bgcremationjpg_705x1600.png')",
            }}
          >
            <div className=" bg-gradient flex flex-col h-full items-center  md:pb-[39rem] pb-36 w-full sm:pb-[30rem] md:justify-center md:m-auto">
              <div className="flex flex-col items-start justify-start w-full">
                <Header />
                <div className="flex md:flex-col flex-row font-playfairdisplay md:gap-10 items-start justify-between ml-5 md:ml-[0] mt-[71px] w-[81%] md:w-full">
                  <div className="flex flex-col gap-3.5 items-start justify-start w-[3%] md:w-full sm:hidden ">
                    <Button
                      className="flex h-7 items-center justify-center ml-0.5 md:ml-[0] w-7"
                      shape="circle"
                      color="white_A700"
                      size="sm"
                      variant="fill"
                    >
                      <Img src="images/img_facebook.svg" alt="facebook_Two" />
                    </Button>
                    <Button
                      className="flex h-7 items-center justify-center w-7"
                      shape="circle"
                      color="white_A700"
                      size="sm"
                      variant="fill"
                    >
                      <Img src="images/img_twitter.svg" alt="twitter_Two" />
                    </Button>
                    <Button
                      className="flex h-7 items-center justify-center w-7"
                      shape="circle"
                      color="white_A700"
                      size="sm"
                      variant="fill"
                    >
                      <Img src="images/img_youtube.svg" alt="youtube_Two" />
                    </Button>
                    <Button
                      className="flex h-7 items-center justify-center w-7"
                      shape="circle"
                      color="white_A700"
                      size="sm"
                      variant="fill"
                    >
                      <Img src="images/img_instagram.svg" alt="instagram_Two" />
                    </Button>
                  </div>
                  <div className="sm:h-[173px] h-[300px] md:h-[301px] md:mt-0 mt-9 relative w-[79%] md:w-full">
                    <Text
                      className=" inset-x-[0] mx-auto md:text-5xl text-[100px] text-center text-white-A700 mt-[-34px] sm:mt-0 w-max"
                      size="txtPlayfairDisplayRomanBold100"
                    >
                      Milele Moyoni
                    </Text>
                    <div className=" bottom-[0] flex flex-col font-montserrat inset-x-[0] items-center justify-start mx-auto w-full">
                      <Text
                        className="sm:text-[21px] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[10.00px] uppercase"
                        size="txtMontserratMedium25"
                      >
                        Forever etched in our hearts
                      </Text>
                      <Text
                        className="mont-regular capitalize leading-[32.00px]   mt-3 text-center text-white-A700 text-xl w-full"
                        size="txtMontserratMedium20"
                      >
                        Creating heartfelt memorials that honor and celebrate
                        the lives of your loved ones, providing a timeless space
                        for cherished memories to endure.
                      </Text>
                      <div className="   flex md:flex-col flex-row gap-4  items-center justify-center mt-[18px]  md:w-full">
                        <div className="flex flex-col font-montserrat items-center justify-start w-[56%] md:w-full">
                          <Button
                            className="mont-regular capitalize cursor-pointer font-medium font-poppins leading-[normal] min-w-[351px] text-center text-lg"
                            shape="round"
                            color="red_A700"
                            size="md"
                            variant="fill"
                            onClick={() => Naviagte("/creatememorial")}
                          >
                            Create an Online Memorial Page
                          </Button>
                        </div>
                        {!loggedIn && <>

                          <Button
                            className="mont-regular common-pointer capitalize cursor-pointer font-medium font-poppins leading-[normal] min-w-[123px] text-center text-lg"
                            onClick={() => Naviagte("/login")}
                            shape="round"
                            color="light_blue_A400"
                            size="md"
                            variant="fill"
                          >
                            Login
                          </Button>
                          <Button
                            className="mont-regular common-pointer capitalize cursor-pointer font-medium font-poppins leading-[normal] min-w-[123px] text-center text-lg"
                            onClick={() => Naviagte("/signup")}
                            shape="round"
                            color="red_A700"
                            size="md"
                            variant="fill"
                          >
                            Sign In
                          </Button>   </>}
                        <Button
                          className=" mont-regular capitalize cursor-pointer font-medium font-poppins leading-[normal] min-w-[351px] text-center text-lg"
                          shape="round"
                          color="light_blue_A400"
                          size="md"
                          variant="fill"
                          onClick={() => Naviagte("/createObituaries")}
                        >
                          Post an Obituary here
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col font-opensans items-center justify-start  mt-[19px] px-[40px] md:px-5 w-full">
          <div className="flex flex-col items-center justify-start w-full">
            <div className="flex md:flex-col flex-row md:gap-10 items-start justify-between w-full">
              <div className="flex md:flex-1 flex-col items-start justify-start mb-2 w-[41%] md:w-full">
                <div className="flex flex-col font-playfairdisplay items-center justify-start">
                  <Text
                    className="sm:text-[34px] md:text-[37px] text-[37px] text-light_green-300"
                    size="txtPlayfairDisplayBold44"
                  >
                    <span className="text-red-A700 font-playfairdisplay text-left font-bold">
                      Keep their
                    </span>
                    <span className="text-blue_gray-700 font-playfairdisplay text-left font-bold">
                      {" "}
                      Memories Alive
                    </span>
                  </Text>
                </div>
                <div className="md:h-[374px] h-[381px] sm:h-[392px] mt-[11px] relative w-[97%] sm:w-full">
                  <div className="flex relative flex-col md:gap-10 gap-[135px] h-full justify-start m-auto w-full">
                    <div className="bg-light_blue-A400  sm:right-[-7px] absolute md:top-0 md:right-0 h-[123px]  ml-[368px] w-[30%]"></div>
                    <div className="bg-red-A700 h-[123px] absolute sm:bottom-0 sm:left-[-2%] mr-[391px] bottom-0 w-[30%]"></div>
                  </div>
                  <Img
                    className="absolute h-[363px] inset-[0] justify-center m-auto object-cover w-[97%]"
                    src="images/img_about21.png"
                    alt="aboutTwentyOne"
                  />
                </div>
                <Text
                  className="mont-regular capitalize italic leading-[32.00px] mt-[23px] text-black-900 text-xl w-full"
                  size="txtMontserratItalicMedium20"
                >
                  Honor the cherished memories of your loved ones with our
                  compassionate memorial creation platform, creating timeless
                  tributes that celebrate and preserve their unique legacies
                </Text>
              </div>
              <div className="flex md:flex-1 flex-col items-center justify-start md:mt-0 mt-[59px] w-[53%] md:w-full">
                <div className="bg-blue_gray-300_3d flex flex-col gap-12 justify-center p-[27px] sm:px-5 rounded-[20px] shadow-bs1 w-full">
                  <div className="flex flex-col gap-[9px] items-center justify-start mt-[62px]">
                    <Text
                      className="sm:text-[34px] open-sans  text-4xl text-blue_gray-900 text-center tracking-[-0.20px]"
                      size="txtOpenSansSemiBold44"
                    >
                      Create a Online Memorial Page
                    </Text>
                    <Text
                      className="text-blue_gray-500 open-sans text-center text-xl tracking-[0.10px] uppercase"
                      size="txtOpenSansRegular20"
                    >
                      Preserve and share memories of your loved one
                    </Text>
                  </div>
                  <div className="flex flex-col gap-[37px] justify-start mb-[42px] mr-9 w-[95%] md:w-full">
                    <div className="flex md:flex-col flex-row gap-[30px] items-end justify-between w-full">
                      <div className="flex md:flex-1 flex-col gap-[15px] items-start justify-start w-[48%] md:w-full">
                        <Text
                          className="text-blue_gray-900 open-sans text-center text-lg tracking-[0.20px]"
                          size="txtOpenSansRegular18"
                        >
                          I want to share memories of
                        </Text>
                        <div className="bg-gray-50 border border-blue_gray-100 border-solid flex flex-col items-center justify-start p-[13px] rounded-[29px] w-full">
                          <div className="flex flex-col items-start justify-start mb-2.5 pt-[3px] px-[3px]">
                            <Text
                              className="text-base open-sans text-blue_gray-400"
                              size="txtOpenSansRegular16"
                            >
                              <input
                                style={{ outline: "none" }}
                                className="w-[100%] h-[25px] bg-transparent  border-0"
                                placeholder="First Name"
                              />{" "}
                            </Text>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 border border-blue_gray-100 border-solid flex md:flex-1 flex-col items-center justify-start md:mt-0 mt-10 p-[13px] rounded-[29px] w-[48%] md:w-full">
                        <div className="flex flex-col items-start justify-start mb-2.5 pt-0.5 px-0.5">
                          <Text
                            className="mt-0.5 text-base open-sans text-blue_gray-400"
                            size="txtOpenSansRegular16"
                          >
                            <input
                              className="w-[100%] h-[25px] bg-transparent outline-none border-0"
                              placeholder="Last Name"
                            />{" "}
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-[17px] items-center justify-start md:ml-[0] ml-[130px] w-[67%] md:w-full">
                      <Button
                        className="mont-regular capitalize cursor-pointer  font-semibold min-w-[447px] sm:min-w-full shadow-bs2 text-center text-lg"
                        shape="round"
                        color="red_A700"
                        size="lg"
                        variant="fill"
                        onClick={() => Naviagte("/creatememorial")}
                      >
                        Click to create an Online Memorial Page
                      </Button>
                      <Button
                        className="open-sans cursor-pointer flex items-center justify-center min-w-[252px]"
                        rightIcon={
                          <Img
                            className="h-[18px] mt-1 ml-1.5"
                            src="images/img_frame.svg"
                            alt="Frame"
                          />
                        }
                        shape="round"
                        color="light_blue_A400"
                        size="lg"
                        variant="fill"
                        onClick={() => Naviagte("/createObituaries")}
                      >

                        <div className="open-sans font-semibold text-base text-center">
                          Click to post an Obituary
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-100  flex flex-col items-center pb-5 justify-start px-[15px] mt-[38px] mx-auto md:px-5 w-full">
          <Text
            className="sm:text-[34px] md:text-[40px] text-[40px] text-center text-light_green-300"
            size="txtPlayfairDisplayBold44"
          >
            <span className="text-red-A700 font-playfairdisplay font-bold">
              Pinned{" "}
            </span>
            <span className="text-blue_gray-700 font-playfairdisplay font-bold">
              Memorials
            </span>
          </Text>
          <div className="flex md:flex-wrap md:gap-[68px] sm:flex-col flex-row sm:gap-10 items-center justify-between mt-[34px] w-full">
            {pinnedMemorialData.slice(0,5).map((memorial, index) => (
              <Img
                key={index}
                className="h-[311px] sm:w-full md:w-[40%] w-[275px] md:h-auto object-cover rounded-[15px]"
                src={ApiEndPoints.Image_URL + "/" + memorial.ProfileImgUrl || "images/img_rectangle21.png"}
                alt={`Rectangle ${index + 1}`} // Assuming you want to add alt text dynamically
                onClick={() => Naviagte("/memorial/" + memorial.MemorialAddress)} // Adjust the navigation logic accordingly
              />
            ))}
          </div>
          <Button
            className="common-pointer capitalize cursor-pointer font-medium popinsfont leading-[normal] min-w-[208px] mt-[25px] sm:text-[21px] md:text-[23px] text-[25px] text-center"
            onClick={() => Naviagte(`/pinnedMemorials`)}
            shape="round"
            color="red_A700"
            size="sm"
            variant="fill"

          >
            VIEW ALL
          </Button>
        </div>
        <div className="flex flex-col items-center pb-5 justify-start px-[15px] mt-[38px] mx-auto md:px-5 w-full">
          {/* <Text
            className="sm:text-[34px] md:text-[40px] text-[40px] text-center text-light_green-300"
            size="txtPlayfairDisplayBold44"
          >
            <span className="text-red-A700 font-playfairdisplay font-bold">
              Pinned{" "}
            </span>
            <span className="text-blue_gray-700 font-playfairdisplay font-bold">
              Obituaries
            </span>
          </Text> */}
       
          {/* <div className="flex flex-wrap md:flex-row sm:flex-col gap-10 items-center justify-around mt-5 w-full">
      {obituaries.slice(0,5).map((obituary) => (
        <div key={obituary.id} className="relative m-2" onClick={() => Naviagte("/Obituaries/" + obituary.MemorialAddress)}>
          <Img
            className="h-[311px] w-full md:w-[150px] object-cover rounded-[15px]"
            src={ApiEndPoints.Image_URL + "/" + (obituary.ProfileImgUrl || "images/img_rectangle21.png")}
            alt={`${obituary.Firstname} ${obituary.LastName}`} // Dynamic alt text
          />
          <div className="absolute bottom-0 left-0 right-0 bg-gray-500/75 text-slate-950 text-center py-2">
            <p className="text-lg font-bold">{`${obituary.Firstname} ${obituary.LastName}`}</p>
          </div>
        </div>
      ))}
    </div> */}
          {/* <Button
            className="common-pointer capitalize cursor-pointer font-medium popinsfont leading-[normal] min-w-[208px] mt-[25px] sm:text-[21px] md:text-[23px] text-[25px] text-center"
            onClick={() => Naviagte("/obituaries")}
            shape="round"
            color="red_A700"
            size="sm"
            variant="fill"

          >
            VIEW ALL
          </Button> */}
        </div>

          <div className="bg-gray-100  font-playfairdisplay flex flex-col items-center pb-5 mt-4 relative w-full"  >
          <div className="md:h-[440px] m-auto w-full">
            <div className="absolute bg-gray-100_01 flex flex-col inset-x-[0] items-center justify-start mx-auto p-[17px] top-[0] w-full">
            <Text
            className="sm:text-[34px] md:text-[40px] text-[40px] text-center text-light_green-300"
            size="txtPlayfairDisplayBold44"
          >
            <span className="text-red-A700 font-playfairdisplay font-bold">
              Pinned{" "}
            </span>
            <span className="text-blue_gray-700 font-playfairdisplay font-bold">
              Obituaries
            </span>
          </Text>
            </div>
            <div className="mt-24 px-20px">
            <MyCarousel memorial={obituaries} url="/Obituaries/" />
            </div>
          </div>
          <Button
            className="common-pointer capitalize cursor-pointer font-medium popinsfont leading-[normal] min-w-[208px] mt-[25px] sm:text-[21px] md:text-[23px] text-[25px] text-center"
            onClick={() => Naviagte("/obituaries")}
            shape="round"
            color="red_A700"
            size="sm"
            variant="fill"
          >
            VIEW ALL
          </Button>
        </div>

        {/* <div className="bg-gray-100  flex flex-col items-center pb-5 justify-start px-[15px] mt-[38px] mx-auto md:px-5 w-full">
          <Text
            className="sm:text-[34px] md:text-[40px] text-[40px] text-center text-light_green-300"
            size="txtPlayfairDisplayBold44"
          >
            <span className="text-red-A700 font-playfairdisplay font-bold">
              Daily{" "}
            </span>
            <span className="text-blue_gray-700 font-playfairdisplay font-bold">
              Obituaries
            </span>
          </Text>
          <PinnedObituariesSilder  data={dailyObituariesDatas}/>
          <Button
            className="common-pointer capitalize cursor-pointer font-medium popinsfont leading-[normal] min-w-[208px] mt-[25px] sm:text-[21px] md:text-[23px] text-[25px] text-center"
            onClick={() => Naviagte("/allObituaries")}
            shape="round"
            color="red_A700"
            size="sm"
            variant="fill"
          >
            VIEW ALL
          </Button>
        </div> */}
        
   
          <div className="bg-gray-100  font-playfairdisplay flex flex-col items-center pb-5 mt-4 relative w-full"  >

          <div className="m-auto w-full">
            <div className="bg-gray-100_01 flex flex-col items-center justify-start mx-auto p-[17px]  w-full">
              <Text
                className=" sm:text-[34px] md:text-[37px] text-[37px] text-center text-light_green-300"
                size="txtPlayfairDisplayBold44"
              >
                <span className="text-red-A700 font-playfairdisplay font-bold">
                Daily
                </span>
                <span className="text-blue_gray-700 font-playfairdisplay font-bold">
                  {" "}
                  Obituaries
                </span>
              </Text>
            </div>
            {dailyObituariesDatas.length > 0 &&
             <div className="mt-24 px-20px">
                   <MyCarousel2 memorial={dailyObituariesDatas} /> 
            </div> }
          </div>
          <Button
            className="common-pointer capitalize cursor-pointer font-medium popinsfont leading-[normal] min-w-[208px] mt-[30px] sm:text-[21px] md:text-[23px] text-[25px] text-center"
            onClick={() => Naviagte("/allObituaries")}
            shape="round"
            color="red_A700"
            size="sm"
            variant="fill"
          >
            VIEW ALL
          </Button>
        </div>    
        <div className="flex flex-col items-center justify-start mt-[27px] p-[26px] sm:px-5 w-full">
          <div className="flex md:flex-col flex-row md:gap-10 gap-[65px] items-center justify-start max-w-[1373px] mx-auto md:px-5 w-full">
            <div className="md:h-[479px] sm:h-[333px] h-[479px] relative w-[48%] md:w-full">
              <div className="absolute bottom-[10px] flex flex-col sm:w-full  sm:h-auto items-center justify-start left-[46px] ">
                <Img
                  className=" sm:h-full md:h-auto object-cover w-[415px] sm:w-full"
                  src="images/img_img2png.png"
                  alt="img2png"
                />
              </div>
              <Img
                className="absolute sm:h-full sm:hidden md:right-[70px] object-cover right-[43px] top-[13px] w-[400px]"
                src="images/img_section.png"
                alt="section"
              />
            </div>
            <div className="flex md:flex-1 flex-col items-start justify-start w-[48%] md:w-full">
              <Text
                className="sm:text-[34px] md:text-[38px] text-[38px] text-light_green-300"
                size="txtPlayfairDisplayBold44"
              >
                <span className="text-red-A700 font-playfairdisplay text-left font-bold">
                  Experience
                </span>
                <span className="text-blue_gray-700 font-playfairdisplay text-left font-bold">
                  {" "}
                  the love
                </span>
              </Text>
              <Text
                className=" mont-regular leading-[24.00px] ml-1.5 md:ml-[0] mt-[57px] text-gray-900 text-lg w-full"
                size="txtMontserratRegular18"
              >
                In the journey of life, love is the thread that binds us, and
                memories are the colors that paint our shared journey. At Milele
                Moyoni, we celebrate the enduring power of love, preserving
                cherished memories to weave a timeless tribute to those we hold
                dear. In the garden of remembrance, every story blossoms, and
                every memory becomes a perennial flower, ensuring that the
                essence of our loved ones lives on forever.
              </Text>
              <div className="flex flex-col font-montserrat items-center justify-start mt-[60px] w-[68%] md:w-full">
                <div className="flex sm:flex-col flex-row sm:gap-10 gap-[87px] items-end justify-start w-auto sm:w-full">
                  <div className="flex flex-col items-start justify-start w-[43%] sm:w-full">
                    <div className="bg-light_blue-A400 h-[3px] w-[21%]"></div>
                    <Text
                      className=" mont-regular mt-[17px] text-blue_gray-700 text-xl tracking-[10.00px]"
                      size="txtMontserratMedium20Bluegray700"
                    >
                      Memorials
                    </Text>
                    <Text
                      className="mt-6 mont-regular sm:text-[21px] md:text-[23px] text-[25px] text-red-A700"
                      size="txtMontserratBold25"
                    >
                      {pinnedMemorialData && pinnedMemorialData.length}
                    </Text>
                  </div>
                  <div className="flex flex-col items-start justify-start w-[35%] sm:w-full">
                    <div className="bg-light_blue-A400 h-[3px] w-1/4"></div>
                    <Text
                      className="mt-[17px] mont-regular text-blue_gray-700 text-xl tracking-[10.00px]"
                      size="txtMontserratMedium20Bluegray700"
                    >
                      Obituaries
                    </Text>
                    <Text
                      className="mt-6 mont-regular sm:text-[21px] md:text-[23px] text-[25px] text-red-A700"
                      size="txtMontserratBold25"
                    >
                     {obituaries && obituaries.length}
                    </Text>
                  </div>
                </div>
              </div>
              <Button
                className="border mont-regular border-light_green-300 border-solid capitalize cursor-pointer font-montserrat font-semibold min-w-[331px] mt-6 rounded-[29px] text-center text-lg"
                color="red_A700"
                size="xl"
                variant="fill"
                onClick={() => Naviagte(`/allmemorial`)}
              >
                Browse all online memorials
              </Button>
            </div>
          </div>
        </div>
        <div className="bg-gray-100  font-playfairdisplay flex flex-col items-center pb-5 mt-4 relative w-full"  >
          <div className="md:h-[440px] m-auto w-full">
            <div className="absolute bg-gray-100_01 flex flex-col inset-x-[0] items-center justify-start mx-auto p-[17px] top-[0] w-full">
              <Text
                className="mb-[347px] sm:text-[34px] md:text-[37px] text-[37px] text-center text-light_green-300"
                size="txtPlayfairDisplayBold44"
              >
                <span className="text-red-A700 font-playfairdisplay font-bold">
                  RECENT
                </span>
                <span className="text-blue_gray-700 font-playfairdisplay font-bold">
                  {" "}
                  ONLINE MEMORIALS
                </span>
              </Text>
            </div>
            <div className="mt-24 px-20px">
              <MyCarousel memorial={memorialData}  url={"/memorial/"}/>
            </div>
          </div>
          <Button
            className="common-pointer capitalize cursor-pointer font-medium popinsfont leading-[normal] min-w-[208px] mt-[25px] sm:text-[21px] md:text-[23px] text-[25px] text-center"
            onClick={() => Naviagte("/allmemorial")}
            shape="round"
            color="red_A700"
            size="sm"
            variant="fill"
          >
            VIEW ALL
          </Button>
        </div>
   
        <div className="flex flex-col font-playfairdisplay items-center justify-start mt-[30px] p-[29px] sm:px-5 w-full ">
          <div className="flex flex-col gap-[47px] items-center justify-start  max-w-[1478px] mb-[26px] mx-auto md:px-5 ">
            <Text
              className="sm:text-[34px] text-[37px] text-center text-light_green-300"
              size="txtPlayfairDisplayBold44"
            >
              <span className="text-red-A700 font-playfairdisplay font-bold">
                MEMORIAL
              </span>
              <span className="text-blue_gray-700 font-playfairdisplay font-bold">
                {" "}
                GALLERIES
              </span>
            </Text>
            <div className="flex flex-col   sm:items-start  font-montserrat items-center justify-start md:w-full lg:w-full">
              <div className="flex flex-col items-center justify-start sm:w-[100%] w-full">
                <div className="gap-10 md:gap-5 grid md:grid-cols-1 grid-cols-2 justify-center min-h-[auto]  w-full">
                  <div className="hover:cursor-pointer h-[159px]  hover:relative relative hover:shadow-bs3 hover:w-full w-full">
                    <div
                      className="bg-cover bg-gray-300 bg-no-repeat hover:cursor-pointer flex flex-1 sm:flex-col flex-row sm:gap-10 h-[159px] items-center justify-between p-4 rounded-md hover:shadow-bs3 shadow-bs3 hover:w-full w-full"
                      style={{
                        backgroundImage: "url('images/img_group85.svg')",
                      }}
                      onClick={() => Naviagte("/list", { state: " Children - Little Angels Memorials" })}
                    >
                      <div className="flex flex-col items-center justify-start ml-6 sm:ml-[0]">
                        <div className="flex flex-col items-center justify-start w-full">
                          <div className="flex flex-col gap-[15px] items-start justify-start w-full">
                            <Text
                              className="capitalize mont-regular leading-[36.00px] text-3xl sm:text-[26px] md:text-[28px] text-blue_gray-700 w-full"
                              size="txtMontserratRomanSemiBold30"
                            >
                              Children - Little Angels Memorials
                            </Text>
                            <Text
                              className="capitalize mont-regular text-lime-700 text-xl"
                              size="txtMontserratBold20"
                            >
                              Read More
                            </Text>
                          </div>
                        </div>
                      </div>
                      <Img
                        className="h-[122px] w-[25%] sm:hidden  md:h-auto mb-[5px]  object-cover"
                        src="images/MGR01C01.png"
                        alt="pngtreecupidh"
                      />
                    </div>
                  </div>
                  <div className="hover:cursor-pointer h-[159px]  hover:relative relative hover:shadow-bs3 hover:w-full w-full">
                    <div
                      className="bg-cover bg-gray-300 bg-no-repeat hover:cursor-pointer flex flex-1 sm:flex-col flex-row sm:gap-10 h-[159px] items-center justify-between p-4 rounded-md hover:shadow-bs3 shadow-bs3 hover:w-full w-full"
                      style={{
                        backgroundImage: "url('images/img_group85.svg')",
                      }}
                      onClick={() => Naviagte("/list", { state: "Students Memorials" })}
                    >
                      <div className="flex flex-col items-center justify-start ml-6 sm:ml-[0]">
                        <div className="flex flex-col items-center justify-start w-full">
                          <div className="flex flex-col gap-[15px] items-start justify-start w-full">
                            <Text
                              className="capitalize mont-regular leading-[36.00px] text-3xl sm:text-[26px] md:text-[28px] text-blue_gray-700 w-full"
                              size="txtMontserratRomanSemiBold30"
                            >
                              Students Memorials
                            </Text>
                            <Text
                              className="capitalize mont-regular text-lime-700 text-xl"
                              size="txtMontserratBold20"
                            >
                              Read More
                            </Text>
                          </div>
                        </div>
                      </div>
                      <Img
                        className="h-[122px] w-[25%] sm:hidden   md:h-auto mb-[5px]  object-cover"
                        src="images/MGR01C02.png"
                        alt="pngtreecupidh"
                      />
                    </div>
                  </div>
                  <div
                    className="bg-cover bg-gray-300 bg-no-repeat hover:cursor-pointer flex flex-1 sm:flex-col flex-row sm:gap-10 h-[159px] items-center justify-between p-4 rounded-md hover:shadow-bs3 shadow-bs3 hover:w-full w-full"
                    style={{ backgroundImage: "url('images/img_group85.svg')" }}
                    onClick={() => Naviagte("/list", { state: " Mashujaa & Hero's Memorials" })}
                  >
                    <div className="flex flex-col items-center justify-start ml-6 sm:ml-[0]">
                      <div className="flex flex-col items-center justify-start w-full">
                        <div className="flex flex-col gap-[15px] items-start justify-start w-full">
                          <Text
                            className="capitalize mont-regular leading-[36.00px] text-3xl sm:text-[26px] md:text-[28px] text-blue_gray-700 w-full"
                            size="txtMontserratRomanSemiBold30"
                          >
                            Mashujaa & Hero's Memorials
                          </Text>
                          <Text
                            className="capitalize mont-regular text-lime-700 text-xl"
                            size="txtMontserratBold20"
                          >
                            Read More
                          </Text>
                        </div>
                      </div>
                    </div>
                    <Img
                      className="h-[122px] w-[20%] sm:hidden  md:h-auto mb-[5px] mr-[13px] object-cover"
                      src="images/MGR02C01.png"
                      alt="pngtreecupidh"
                    />
                  </div>
                  <div
                    className="bg-cover bg-gray-300 bg-no-repeat hover:cursor-pointer flex flex-1 sm:flex-col flex-row sm:gap-10 h-[159px] items-center justify-between p-4 rounded-md hover:shadow-bs3 shadow-bs3 hover:w-full w-full"
                    style={{ backgroundImage: "url('images/img_group85.svg')" }}
                    onClick={() => Naviagte("/list", { state: "Veterans and Disciplined Forces Memorials" })}
                  >
                    <div className="flex flex-col items-center justify-start ml-6 sm:ml-[0]">
                      <div className="flex flex-col items-center justify-start w-full">
                        <div className="flex flex-col gap-[15px] items-start justify-start w-full">
                          <Text
                            className="capitalize mont-regular leading-[36.00px] text-3xl sm:text-[26px] md:text-[28px] text-blue_gray-700 w-full"
                            size="txtMontserratRomanSemiBold30"
                          >
                            Veterans and Disciplined Forces Memorials
                          </Text>
                          <Text
                            className="capitalize mont-regular text-lime-700 text-xl"
                            size="txtMontserratBold20"
                          >
                            Read More
                          </Text>
                        </div>
                      </div>
                    </div>
                    <Img
                      className="h-[132px] w-[20%] sm:hidden  md:h-auto  mr-[13px] object-contain"
                      src="images/MGR02C02_1.png"
                      alt="pngtreecupidh"
                    />
                  </div>

                  <div
                    className="bg-cover bg-gray-300 bg-no-repeat hover:cursor-pointer flex flex-1 sm:flex-col flex-row sm:gap-10 h-[159px] items-center justify-between p-4 rounded-md hover:shadow-bs3 shadow-bs3 hover:w-full w-full"
                    style={{ backgroundImage: "url('images/img_group85.svg')" }}
                    onClick={() => Naviagte("/list", { state: "Covid-19 Corona Virus Victim Memorials" })}
                  >
                    <div className="flex flex-col items-center justify-start ml-6 sm:ml-[0]">
                      <div className="flex flex-col items-center justify-start w-full">
                        <div className="flex flex-col gap-[15px] items-start justify-start w-full">
                          <Text
                            className="capitalize mont-regular leading-[36.00px] text-3xl sm:text-[26px] md:text-[28px] text-blue_gray-700 w-full"
                            size="txtMontserratRomanSemiBold30"
                          >
                            Covid-19 Corona Virus Victim Memorials
                          </Text>
                          <Text
                            className="capitalize mont-regular text-lime-700 text-xl"
                            size="txtMontserratBold20"
                          >
                            Read More
                          </Text>
                        </div>
                      </div>
                    </div>
                    <Img
                      className="h-[122px] w-[20%] sm:hidden   md:h-auto mb-[5px] mr-[13px] object-left object-cover"
                      src="images/MGR03C01.png"
                      alt="pngtreecupidh"
                    />
                  </div>

                  <div
                    className="bg-cover bg-gray-300 bg-no-repeat hover:cursor-pointer flex flex-1 sm:flex-col flex-row sm:gap-10 h-[159px] items-end justify-between p-4 rounded-md hover:shadow-bs3 shadow-bs3 hover:w-full w-full"
                    style={{ backgroundImage: "url('images/img_group85.svg')" }}
                    onClick={() => Naviagte("/list", { state: "First Responders Memorials" })}
                  >
                    <div className="flex flex-col items-center justify-start mb-1.5 ml-6 sm:ml-[0] sm:mt-0 mt-5">
                      <div className="flex flex-col items-center justify-start w-full">
                        <div className="flex flex-col gap-[34px] items-start justify-start w-full">
                          <Text
                            className="capitalize mont-regular text-3xl sm:text-[26px] md:text-[28px] text-blue_gray-700"
                            size="txtMontserratRomanSemiBold30"
                          >
                            First Responders Memorials
                          </Text>
                          <Text
                            className="capitalize mont-regular text-lime-700 text-xl"
                            size="txtMontserratBold20"
                          >
                            Read More
                          </Text>
                        </div>
                      </div>
                    </div>
                    <Img
                      className="h-[122px] w-[20%] sm:hidden   md:h-auto mb-[5px] mr-[13px] object-cover"
                      src="images/MGR03C02.png"
                      alt="pngtreecupidh"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
