import { Button } from "../../components";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pricing from "../../components/planPricing";
import ApiEndPoints from "../../ApiEndpoints";
import { isUserLoggedIn } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Page = () => {
  const Naviagte = useNavigate()
  const [profileImage, setProfileImage] = useState(null);
  const [isMemorialFormSelected, setIsMemorialFormSelected] = useState(null);
  const [custumClass, setCustumClass] = useState("hidden");
  const [custumClass1, setCustumClass1] = useState("hidden");
  const loggedIn = isUserLoggedIn();
  const UserID = localStorage.getItem("UserID");
  const today = moment().format('YYYY-MM-DD'); // Get today's date in "YYYY-MM-DD" format
  console.log("today" , today)
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    Firstname: "",
    MiddleName: "",
    LastName: "",
    Gender: "",
    Relationship: "",
    MemorialDesignation: "",
    MemorialAddress: "",
    PrivacyOptions: "",
    Country: "",
    State: "",
    City: "",
    Dcountry: "",
    Dstate: "",
    Dcity: "",
    DateOfBirth :"",
    DateOfDeath:"",
    CreatedOn:today ,
  });

  const toggleClass = () => {
    setCustumClass((prevClass) => (prevClass === "hidden" ? "" : "hidden"));
  };

  const toggleClass1 = () => {
    setCustumClass1((prevClass) => (prevClass === "hidden" ? "" : "hidden"));
  };

  const checkUserLogin = () => {

    if (!loggedIn) {

      toast.error("please login first");

      setTimeout(() => {
        Naviagte("/login")
      }, [7000])
    }

  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onFileChange = event => {
    setProfileImage(event.target.files[0])
  };

  const handleFormSubmit1 = async (event) => {
    event.preventDefault();
    if (!localStorage.getItem("UserID")) {
      toast("You Need to Login First ")
      return;
    }
    try {
      const response = await fetch(ApiEndPoints.addObituary, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "addMemorial",
          user_id: UserID,
          Plan: "free",
          ...formData,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Memorial created successfully:", data);
        toast.success("obituary created successfully");
        await onUploadProfileImage(data.memorial.id)
        setFormData({
          user_id: '1',
          Firstname: "",
          MiddleName: "",
          LastName: "",
          Gender: "",
          Relationship: "",
          MemorialDesignation: "",
          MemorialAddress: "",
          PrivacyOptions: "",
          DateOfBirth: "",
          Country: "",
          State: "",
          City: "",
          DateOfDeath: "",
          Dcountry: '',
          Dstate: "",
          Dcity: "",
        
        });
        setStep(1);
      } else {
        const errorData = await response.json();
        console.error("Error creating obituary:", errorData.error);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const onUploadProfileImage = async (Id) => {
    console.log("Id  :- ", Id)
    const formDImage = new FormData();
    formDImage.append('file', profileImage);
    formDImage.append('obituaryId', Id);

    try {

      const response = await fetch(ApiEndPoints.updateObituaryProfileImage, {
        method: "POST",
        body: formDImage
      });
      console.log('File Uploaded', response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleNext = (e) => {
    console.log("func");
    e.preventDefault();
    if (
      formData.Firstname.length > 0 &&
      formData.LastName.length > 0 &&
      formData.MiddleName.length > 0
    ) {
      setStep(step + 1);
      console.log(step);
    } else {
      console.log("please fill all deteils");
      toast.error("please fill all deteils");
    }
  };

  const handleMemNext = (e) => {
    setIsMemorialFormSelected('Mem')
    handleNext(e)
  }
  const handleOctNext = (e) => {
    setIsMemorialFormSelected('obt')
    handleNext(e)
  }
  const handleBack = (e) => {
    console.log("func");
    e.preventDefault();
    setStep(step - 1);
    console.log(step);
  };
  const handleNext1 = (e) => {
    console.log("func");
    e.preventDefault();
    if (!localStorage.getItem("UserID")) {
      toast("You Need to Login First ")
      return;
    }
    if (
      formData.Gender.length > 0 &&
      formData.Relationship.length > 0 &&
      formData.MemorialDesignation.length > 0
    ) {
      setStep(step + 1);
      console.log(step);
    } else {
      console.log("please fill all deteils");
      toast.error("please fill all deteils");
    }
  };
  const handleBack1 = (e) => {
    console.log("func");
    e.preventDefault();
    setStep(step - 1);
    console.log(step);
  };

  useEffect(() => {

    checkUserLogin();


  }, [])

  return (
    <>
      <ToastContainer />
      <Header />

      {isMemorialFormSelected !== "obt" && (
        <div className="pt-6">
          <h1
            style={{
              fontSize: "30px",
              textAlign: "center",
              paddingBottom: "20px",
            }}
          >
            Create a  Obituaries page
          </h1>

          {/* <p className="max-w-screen-md mx-auto text-center text-gray-500 md:text-lg">
        Please fill in the details below so that we can get in contact with you.
      </p> */}

          <div className="text-gray-600">
            <div className="container flex flex-col flex-wrap px-5 py-4 ">
              <div className="flex flex-wrap justify-center">
                {step != 1 ? (
                  <a
                    className="
              inline-flex
              items-center
              justify-center
             w-1/5
              py-3
              font-medium
              leading-none
              tracking-wider
             text-indigo-500
              bg-gray-100
              border-b-2 border-indigo-500
              rounded-t
              sm:px-6 sm:w-auto sm:justify-start
              title-font
              mx-2
            "
                  >
                    About your loved one
                  </a>
                ) : (
                  <a
                    className="
            inline-flex
            items-center
            justify-center
           w-1/5
            py-3
            font-medium
            leading-none
            tracking-wider
           text-indigo-500
            bg-gray-100
            border-b-2 border-indigo-500
            rounded-t
            sm:px-6 sm:w-auto sm:justify-start
            title-font
            mx-2
          "
                  >
                    About your loved one
                  </a>
                )}
                {step != 1 ? (
                  <a
                    className="
              inline-flex
              items-center
              justify-center
             w-1/5
              py-3
              font-medium
              leading-none
              tracking-wider
              text-indigo-500
              bg-gray-100
              border-b-2 border-indigo-500
              border-b-2 border-gray-200
              sm:px-6 sm:w-auto sm:justify-start
              title-font
              hover:text-gray-900
              mx-4
            "
                  >
                    Choose your plan
                  </a>
                ) : (
                  <a
                    className="
              inline-flex
              items-center
              justify-center
              w-1/5
              py-3
              font-medium
              leading-none
              tracking-wider
              border-b-2 border-gray-200
              sm:px-6 sm:w-auto sm:justify-start
              title-font
              hover:text-gray-900
              mx-4
              bg-gray-100
            "
                  >
                    Choose your plan
                  </a>
                )}
                {step == 3 ? (
                  <a
                    className="
          inline-flex
          items-center
          justify-center
         w-1/5
          py-3
          font-medium
          leading-none
          tracking-wider
          text-indigo-500
          bg-gray-100
          border-b-2 border-indigo-500
          border-b-2 border-gray-200
          sm:px-6 sm:w-auto sm:justify-start
          title-font
          hover:text-gray-900
          mx-3
          "
                  >
                    Privacy Options
                  </a>
                ) : (
                  <a
                    className="
             inline-flex
             items-center
             justify-center
             w-1/5
             py-3
             font-medium
             leading-none
             tracking-wider
             border-b-2 border-gray-200
             sm:px-6 sm:w-auto sm:justify-start
             title-font
             hover:text-gray-900
             mx-3
             bg-gray-100

           "
                  >
                    Privacy Options
                  </a>
                )}
              </div>

              <div className="flex flex-col w-full text-center">
                <div className="py-6 bg-white sm:py-8 lg:py-12">
                  <div
                    className="px-4 mx-auto max-w-screen-2xl md:px-8 formWidth"
                    style={{
                      backgroundColor: "white",
                      paddingTop: "40px",
                      paddingBottom: "40px",
                      borderRadius: "20px",
                      paddingLeft: "50px",
                      paddingRight: "50px",
                    }}
                  >
                    {/* form - start */}
                    {/* <h2
                    style={{ textAlign: 'left', fontSize: '30px', paddingBottom: '15px' }}
                  >This memorial is dedicated to</h2> */}
                    <form
                      className="max-w-screen-md mx-auto"
                      onSubmit={handleFormSubmit1}
                    >
                      {step == 1 && (
                        <>
                          <div className="flex flex-col mb-2 text-left">
                            <h2
                              style={{
                                textAlign: "left",
                                fontSize: "30px",
                                paddingBottom: "15px",
                              }}
                            >
                              This obituary is dedicated to:
                            </h2>
                            {/* <div>
                            Would you like to share your memorial ?
                          </div> */}
                          </div>
                          <div className="flex flex-col mb-4">
                            <label
                              htmlFor="name"
                              className="inline-flex mb-2 text-sm text-gray-800"
                            >
                              First Name
                            </label>
                            <input
                              name="Firstname"
                              className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      "
                              onChange={handleChange}
                              value={formData.Firstname}
                            />
                          </div>

                          <div className="flex flex-col mb-4">
                            <label
                              htmlFor="phone"
                              className="inline-flex mb-2 text-sm text-gray-800"
                            >
                              Middle name
                            </label>
                            <input
                              name="MiddleName"
                              onChange={handleChange}
                              value={formData.MiddleName}
                              className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      "
                            />
                          </div>

                          <div className="flex flex-col mb-2">
                            <label
                              htmlFor="company"
                              className="inline-flex mb-2 text-sm text-gray-800"
                            >
                              Last name
                            </label>
                            <input
                              name="LastName"
                              type="text"
                              onChange={handleChange}
                              value={formData.LastName}
                              className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      "
                            />
                          </div>

                          <div className="flex  mb-4">
                            <label className="block text-gray-800">
                              {" "}
                              Select Gender
                            </label>
                            <div className="mx-4">
                              <label className="inline-flex items-center">
                                <input
                                  type="radio"
                                  name="Gender"
                                  value="Male"
                                  checked={formData.Gender === "Male"}
                                  onChange={handleChange}
                                  className="form-radio h-5 w-5 text-blue-500"
                                />
                                <span className="ml-2">Male</span>
                              </label>
                              <label className="inline-flex items-center ml-6">
                                <input
                                  type="radio"
                                  name="Gender"
                                  value="Female"
                                  checked={formData.Gender === "Female"}
                                  onChange={handleChange}
                                  className="form-radio h-5 w-5 text-pink-500"
                                />
                                <span className="ml-2">Female</span>
                              </label>
                              <label className="inline-flex items-center ml-6">
                                <input
                                  type="radio"
                                  name="Gender"
                                  value="Other"
                                  checked={formData.Gender === "Other"}
                                  onChange={handleChange}
                                  className="form-radio h-5 w-5 text-gray-500"
                                />
                                <span className="ml-2">Other</span>
                              </label>
                            </div>
                          </div>
                          <div className="flex  mb-4">
                            <label className="block text-gray-800">
                              {" "}
                              Profile Image
                            </label>
                            <div className="mx-4">
                              {/* <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">Upload file</label> */}
                              <input className="block w-full text-sm text-blue-500 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none "
                                id="file_input"
                                type="file"
                                onChange={onFileChange}
                                name="ProfileImgUrl"
                              />

                            </div>
                          </div>

                          <div className="flex flex-col mb-2">
                            <label
                              htmlFor="company"
                              className="inline-flex mb-2 text-sm text-gray-800"
                            >
                              Relationship
                            </label>
                            <input
                              name="Relationship"
                              type="text"
                              onChange={handleChange}
                              value={formData.Relationship}
                              className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      "
                            />
                          </div>
                          <div className="flex flex-col mb-2">
                            <label
                              htmlFor="company"
                              className="inline-flex mb-2 text-sm text-gray-800"
                            >
                              Obituaries designation
                            </label>
                            <input
                              name="MemorialDesignation"
                              type="text"
                              onChange={handleChange}
                              value={formData.MemorialDesignation}
                              className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                        
                      "
                            />
                          </div>

                          <div className="flex flex-col mb-4">
                            <label
                              htmlFor="phone"
                              className="inline-flex mb-2 text-sm text-gray-800"
                            >
                              Address
                            </label>
                            <input
                              name="MemorialAddress"
                              type="text"
                              onChange={handleChange}
                              value={formData.MemorialAddress}
                              className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      "
                            />
                          </div>

                          <div
                            id="accordion-collapse"
                            data-accordion="collapse"
                            style={{}}
                          >
                            <h2 id="accordion-collapse-heading-1">
                              <button
                                type="button"
                                style={{ border: "1px solid #97978C" }}
                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
                                data-accordion-target="#accordion-collapse-body-1"
                                aria-expanded="true"
                                aria-controls="accordion-collapse-body-1"
                                onClick={toggleClass}
                              >
                                <span>Birth deteils</span>
                                <svg
                                  data-accordion-icon
                                  class="w-3 h-3 rotate-180 shrink-0"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 10 6"

                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M9 5 5 1 1 5"
                                  />
                                </svg>
                              </button>
                            </h2>
                            <div
                              id="accordion-collapse-body-1"
                              class={custumClass}
                              aria-labelledby="accordion-collapse-heading-1"
                              className="  px-3
                        py-2"
                            >
                              <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                                <div className="flex flex-col mb-4">
                                  <label
                                    htmlFor="phone"
                                    className="inline-flex mb-2 text-sm text-gray-800"
                                  >
                                    Date of birth
                                  </label>
                                  <input
                                    name="DateOfBirth"
                                    type="date"
                                    onChange={handleChange}
                                    value={formData.DateOfBirth}
                                    className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      "
                                  />
                                </div>

                                <div className="flex flex-col mb-4">
                                  <label
                                    htmlFor="phone"
                                    className="inline-flex mb-2 text-sm text-gray-800"
                                  >
                                    Country
                                  </label>
                                  <input
                                    name="Country"
                                    type="text"
                                    onChange={handleChange}
                                    value={formData.Country}
                                    className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      "
                                  />
                                </div>

                                <div className="flex flex-col mb-4">
                                  <label
                                    htmlFor="phone"
                                    className="inline-flex mb-2 text-sm text-gray-800"
                                  >
  State/county
                                  </label>
                                  <input
                                    name="State"
                                    type="text"
                                    onChange={handleChange}
                                    value={formData.State}
                                    className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      "
                                  />
                                </div>

                                <div className="flex flex-col mb-4">
                                  <label
                                    htmlFor="phone"
                                    className="inline-flex mb-2 text-sm text-gray-800"
                                  >
                                    City
                                  </label>
                                  <input
                                    name="City"
                                    type="text"
                                    onChange={handleChange}
                                    value={formData.City}
                                    className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      "
                                  />
                                </div>
                              </div>
                            </div>
                            <h2 id="accordion-collapse-heading-2">
                              <button
                                type="button"
                                onClick={toggleClass1}
                                style={{
                                  marginTop: "32px",
                                  border: "1px solid #97978C",
                                }}
                                class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
                                data-accordion-target="#accordion-collapse-body-2"
                                aria-expanded="false"
                                aria-controls="accordion-collapse-body-2"
                              >
                                <span>Pass away deteils</span>
                                <svg
                                  data-accordion-icon

                                  class="w-3 h-3 rotate-180 shrink-0"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 10 6"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M9 5 5 1 1 5"
                                  />
                                </svg>
                              </button>
                            </h2>
                            <div
                              id="accordion-collapse-body-2"
                              class={custumClass1}
                              aria-labelledby="accordion-collapse-heading-2"
                            >
                              <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                                <div className="flex flex-col mb-4">
                                  <label
                                    htmlFor="phone"
                                    className="inline-flex mb-2 text-sm text-gray-800"
                                  >
                                    Date of pass
                                  </label>
                                  <input
                                    name="DateOfDeath"
                                    type="date"
                                    onChange={handleChange}
                                    value={formData.DateOfDeath}
                                    className="
                         w-full
                         px-3
                         py-2
                         text-gray-800
                         border
                         rounded
                         outline-none
                         bg-gray-50
                         focus:ring
                         ring-indigo-300
                       "
                                  />
                                </div>

                                <div className="flex flex-col mb-4">
                                  <label
                                    htmlFor="phone"
                                    className="inline-flex mb-2 text-sm text-gray-800"
                                  >
                                    Country
                                  </label>
                                  <input
                                    name="Dcountry"
                                    type="text"
                                    onChange={handleChange}
                                    value={formData.Dcountry}
                                    className="
                         w-full
                         px-3
                         py-2
                         text-gray-800
                         border
                         rounded
                         outline-none
                         bg-gray-50
                         focus:ring
                         ring-indigo-300
                       "
                                  />
                                </div>

                                <div className="flex flex-col mb-4">
                                  <label
                                    htmlFor="phone"
                                    className="inline-flex mb-2 text-sm text-gray-800"
                                  >
                                    State/county
                                  </label>
                                  <input
                                    name="Dstate"
                                    type="text"
                                    onChange={handleChange}
                                    value={formData.Dstate}
                                    className="
                         w-full
                         px-3
                         py-2
                         text-gray-800
                         border
                         rounded
                         outline-none
                         bg-gray-50
                         focus:ring
                         ring-indigo-300
                       "
                                  />
                                </div>

                                <div className="flex flex-col mb-4">
                                  <label
                                    htmlFor="phone"
                                    className="inline-flex mb-2 text-sm text-gray-800"
                                  >
                                    City
                                  </label>
                                  <input
                                    name="Dcity"
                                    type="text"
                                    onChange={handleChange}
                                    value={formData.Dcity}
                                    className="
                         w-full
                         px-3
                         py-2
                         text-gray-800
                         border
                         rounded
                         outline-none
                         bg-gray-50
                         focus:ring
                         ring-indigo-300
                       "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center justify-between">
                            <button
                              style={{ visibility: "hidden" }}
                              className="
                        inline-flex
                        items-center
                        px-6
                        py-2
                        text-sm text-gray-800
                        rounded-lg
                        shadow
                        outline-none
                        gap-x-1
                        hover:bg-gray-100
                      "
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                                />
                              </svg>
                              Back
                            </button>
                            <button
                              className="
                        px-6
                        py-2
                        text-sm text-white
                        bg-indigo-500
                        rounded-lg
                        outline-none
                        hover:bg-indigo-600
                        ring-indigo-300
                        my-2
                      "
                              onClick={handleMemNext}
                              style={{ color: "white" }}
                            >
                              Next
                            </button>
                          </div>
                        </>
                      )}
                      {step == 2 && (
                        <>
                          {/* <div className="flex flex-col mb-4">
                    <label
                      htmlFor="phone"
                      className="inline-flex mb-2 text-sm text-gray-800"
                    >
                      Please enter a phone number
                    </label>
                    <input
                      name="phone"
                      className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      "
                    />
                  </div> */}
                          <div className="flex flex-col mb-2 text-left">
                            <h2
                              style={{
                                textAlign: "left",
                                fontSize: "30px",
                                paddingBottom: "15px",
                              }}
                            >
                              Choose your payment plan:
                            </h2>
                            <div>Would you like to share your Obituaries ?</div>
                          </div>
                          <Pricing />
                          <div className="flex items-center justify-between">
                            <button
                              className="
                        inline-flex
                        items-center
                        px-6
                        py-2
                        text-sm text-gray-800
                        rounded-lg
                        shadow
                        outline-none
                        gap-x-1
                        hover:bg-gray-100
                        my-2
                      "
                              onClick={handleBack1}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                                />
                              </svg>
                              Back
                            </button>
                            <button
                              className="
                        px-6
                        py-2
                        text-sm text-white
                        bg-indigo-500
                        rounded-lg
                        outline-none
                        hover:bg-indigo-600
                        ring-indigo-300
                        my-2
                      "
                              onClick={handleNext1}
                              style={{ color: "white" }}
                            >
                              Next
                            </button>
                          </div>
                        </>
                      )}
                      {step == 3 && (
                        <>
                          <div className="flex flex-col mb-2 text-left">
                            <h2
                              style={{
                                textAlign: "left",
                                fontSize: "30px",
                                paddingBottom: "15px",
                              }}
                            >
                              Privacy options:
                            </h2>
                            <div>
                              Would you like to share your Obituaries with others
                              or keep it private?
                            </div>
                            <div className="my-2">
                              (This can be changed later.)
                            </div>
                          </div>
                          <div className="flex flex-col mb-2">
                            <label
                              htmlFor="company"
                              className="inline-flex mb-2 text-sm text-gray-800"
                            >
                              Select Privacy Options
                            </label>
                            <select
                              name="PrivacyOptions"
                              onChange={handleChange}
                              value={formData.PrivacyOptions}
                              className="mt-1 p-2 border rounded-md w-full"
                              required
                            >
                              <option value="">Select Privacy Options</option>
                              <option value="public">Public</option>
                              <option value="private">Private</option>
                            </select>
                          </div>

                          <div className="flex items-center justify-between">
                            <button
                              className="
                        inline-flex
                        items-center
                        px-6
                        py-2
                        text-sm text-gray-800
                        rounded-lg
                        shadow
                        outline-none
                        gap-x-1
                        hover:bg-gray-100
                        my-2
                      "
                              onClick={handleBack}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                                />
                              </svg>
                              Back
                            </button>
                            {step == 3 ? (
                              <button
                                className="
                       px-6
                       py-2
                       text-sm text-white
                       bg-indigo-500
                       rounded-lg
                       outline-none
                       hover:bg-indigo-600
                       ring-indigo-300
                     "
                                type="submit"
                                style={{ color: "white" }}
                              >
                                Submit
                              </button>
                            ) : (
                              <button
                                className="
                       px-6
                       py-2
                       text-sm text-white
                       bg-indigo-500
                       rounded-lg
                       outline-none
                       hover:bg-indigo-600
                       ring-indigo-300
                     "
                                onClick={handleNext}
                                style={{ color: "white" }}
                              >
                                Next
                              </button>
                            )}
                          </div>
                        </>
                      )}
                    </form>
                    {/* form - end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default Page;
