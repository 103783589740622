


import React,{useState , useEffect} from 'react'

import './AdminstyleNew.css'
import { FaSearchLocation } from "react-icons/fa";
import { RxDashboard } from "react-icons/rx";
import { SiGoogleforms } from "react-icons/si";
import { CiCircleList } from "react-icons/ci";
import { IoIosSettings } from "react-icons/io";
import { CiLogout } from "react-icons/ci";
import { GrUserAdmin } from "react-icons/gr";
import { Link  , useNavigate} from 'react-router-dom';
import Hamburger from 'hamburger-react'
import AdminSidebar from './AdminSidebar';
import AdminMobileSidebar from './AdminMobileSidebar';
import ApiEndPoints from '../../ApiEndpoints';
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function AdminTestimonials() {

    const [Data, setData] = useState([]);
    const [Data1, setData1] = useState([]);
    const [Data2, setData2] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [editedData, setEditedData] = useState({});



    const [usermsg , setUsersMsg] = useState([]);
    // const [isOpen, setOpen] = useState(false)
    const [open, setOpen] = useState(false);


    const navigate = useNavigate();

   
  

    const fetchData = async () => {
      try {
        const response = await fetch(ApiEndPoints.getTestimonils);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const datas = await response.json();
        setData(datas);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    useEffect(()=>{
      fetchData()
      
    },[])





  const handleLogout=()=>{
    navigate('/');
    window.localStorage.clear();

  }


  const handleDelete = async (id) => {
    try {
        const response = await fetch(`${ApiEndPoints.deleteTestimonial}/${id}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        // After successful deletion, refetch data to update the UI
        fetchData();
    } catch (error) {
        console.error('Error deleting contact:', error);
    }
};

const filteredData = Data.filter((user) => {
  const fullName = `${user.createdBy}`;
  return fullName.toLowerCase().includes(searchQuery.toLowerCase());
});

const handleEdit = (user) => {
  setEditedData(user);
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};

const handleInputChange = (e) => {
  const { name, value } = e.target;
  setEditedData({ ...editedData, [name]: value });
};

console.log(editedData);

const handleSaveChanges = async () => {
  try {
    const response = await fetch(`${ApiEndPoints.updateTestimonils}/${editedData.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedData),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    // Refetch data after update
    fetchData();
    setOpen(false);
  } catch (error) {
    console.error("Error updating memorial:", error);
  }
};


  return (
   <>
 
 <body >
  <AdminSidebar/>
   
 
  <section class="home-section">
    <AdminMobileSidebar/>

  
    <div class="home-content">
            <div class="sales-boxes">
              <div class="recent-sales box">
              <div class="title">All Testimonials</div>
                {/* Search input field */}
                <div class="search-box">
                <div>
            {/* <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone number</label> */}
            <input type="text" id="phone" class=" mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
               placeholder="Search by Name"
               value={searchQuery}
               onChange={(e) => setSearchQuery(e.target.value)}
            />        </div>

                </div>
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-10 mb-5">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
                <th scope="col" class="px-6 py-3">
                Created-By
                </th>
                <th scope="col" class="px-6 py-3">
                Created-On
                </th>
                <th scope="col" class="px-6 py-3">
                reviewDescription
                </th>
                
                <th scope="col" class="px-6 py-3">
                    Action
                </th>
                <th scope="col" class="px-6 py-3">
                    Edit
                </th>
            </tr>
        </thead>
        <tbody>
                                        {filteredData.map((user) => (
                                            <tr
                                                key={user.id}
                                                class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                                            >
                                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {user.createdBy}
       
                                                </td>
                                                <td class="px-6 py-4">         {new Date(user.createdOn).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC'
  })}
</td>
                                                <td class="px-6 py-4"> {user.reviewDescription}</td>
                                                 <td className="px-6 py-4">
                                                 <button
                                                     className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                                     onClick={() => handleDelete(user.id)}
                                                 >
                                                     Delete
                                                 </button>
                                             </td>
                                             <td className="px-6 py-4">
                                                 <button
                                                     className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                                     onClick={() => handleEdit(user)}
                                                 >
                                                     Edit
                                                 </button>
                                             </td>
                                            </tr>
                                        ))}
                                    </tbody>
    </table>
                <div class="button">
                  <Link
                    to="#"
                    
                    className="mx-2"
                    
                  >
                    Previous
                  </Link>
                  <Link
                    to="#"
                  
                    className="mx-2"
                  
                  >
                    Next
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Edit Testimonials
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            {/* Close icon */}
          </IconButton>
          <DialogContent dividers>
            <form className="formCustom">
              {/* Render form fields with current data */}
              <div className="flex flex-col mb-4 ">
                <label htmlFor="name" className="inline-flex mb-2 text-sm text-gray-800">
                  
Created By
                </label>
                <input
                  name="createdBy"
                  value={editedData.createdBy || ""}
                  onChange={handleInputChange}
                  style={{ height: "30px" }}
                  className="w-full px-3 py-2 text-gray-800 border rounded outline-none bg-gray-50 focus:ring ring-indigo-300"
                />
              </div>
              <div className="flex flex-col mb-4">
                  <label
                    htmlFor="name"
                    className="inline-flex mb-2 text-sm text-gray-800"
                  >
                   Description
                  </label>
                  <textarea
                    name = 'reviewDescription'
                   value={editedData.reviewDescription
                    || ""}
                   onChange={handleInputChange}
                    style={{ height: "90px" }}
                    className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      
                      "
                  />
                </div>{" "}
                
              
            </form>
          </DialogContent>
          <DialogActions>
          
            <Button variant="contained"  onClick={handleSaveChanges}>Save</Button>

          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
  </section>


</body>
   
   </>

  )
}

export default AdminTestimonials
