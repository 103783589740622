import { Header } from "../../components/Header";
// import "../../styles/important.css";
import { Footer } from "../../components/Footer";
import { Button, Img, Text } from "../../components";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ApiEndPoints from "../../ApiEndpoints";

const Page = () => {
  const [memorials, setMemorials] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredMemorials, setFilteredMemorials] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(ApiEndPoints.getMemorial);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const datas = await response.json();
      setMemorials(datas);
      setFilteredMemorials(datas); // Initialize filteredMemorials with all memorials
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSearchButtonClick = (e) => {
    e.preventDefault();
    
    fetchData();
  };


  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = memorials.filter((memorial) => {
      const fullName = `${memorial.Firstname} ${memorial.MiddleName} ${memorial.LastName}`;
      return fullName.toLowerCase().includes(query.toLowerCase());
    });
    setFilteredMemorials(filtered);
  };

  return (
    <>
      <Header />
      <div className="pt-6">
        <div className="w-[90%] m-auto flex flex-row justify-between">
          <div className="w-52 pr-2"></div>
          <h1
            style={{
              fontSize: "30px",
              textAlign: "center",
              paddingBottom: "20px",
            }}
          >
            All Memorial{" "}
          </h1>
          <div className="font-montserrat">
            <Button
              className="mont-regular capitalize cursor-pointer font-medium font-poppins leading-[normal] min-w-[300px] text-center text-md"
              shape="round"
              color="red_A700"
              size="lg"
              variant="fill"
              onClick={() => navigate("/creatememorial")}
            >
              Create an Online Memorial Page
            </Button>
          </div>
        </div>

        <div
          className="w-[90%] bg-white-A700  m-auto py-10"
          style={{ borderRadius: "20px" }}
        >
           <form className="flex items-center mx-auto" style={{ paddingTop: '2px', paddingBottom: '18px', maxWidth: '45rem' }}>
            <label htmlFor="voice-search" className="sr-only">Search</label>
            <div className="relative w-full">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.15 5.6h.01m3.337 1.913h.01m-6.979 0h.01M5.541 11h.01M15 15h2.706a1.957 1.957 0 0 0 1.883-1.325A9 9 0 1 0 2.043 11.89 9.1 9.1 0 0 0 7.2 19.1a8.62 8.62 0 0 0 3.769.9A2.013 2.013 0 0 0 13 18v-.857A2.034 2.034 0 0 1 15 15Z" />
                </svg>
              </div>
              <input
                type="text"
                id="voice-search"
                value={searchQuery}
                onChange={handleSearch}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search by name..."
                required
              />
            </div>
            <Button
              className="mont-regular capitalize mx-2 cursor-pointer font-medium font-poppins leading-[normal] min-w-[100px] text-center text-lg"
              shape="round"
              color="light_blue_A400"
              size="md"
              variant="fill"
              onClick={handleSearchButtonClick}
            >
              Search
            </Button>
          </form>
          <div className="w-[85%] m-auto pt-10">
            {filteredMemorials.map(({ Firstname = "", LastName = "", MiddleName = "", MemorialAddress = "", ProfileImgUrl }, index) => (
              <div key={index} className="shadow-md border border-gray-200 p-4 rounded-md rounded-xl mb-10">
                <div className="flex h-full content-stretch items-center">
                  <div className="w-1/4">
                    {/* Content for the right column (6:4 ratio) */}
                    <Img
                      src={ApiEndPoints.Image_URL + "/" + ProfileImgUrl || "images/img_rectangle21.png"}
                      className="w-full p-2 md:h-auto object-cover rounded-[15px]"
                      alt="rectangleTwenty"
                    />
                  </div>
                  <div className="w-3/4 h-full px-6 p-2 flex justify-center mont-regular">
                    <div className="flex flex-col content-between ">
                      <div className=" flex justify-between">  <div className="text-xl font-bold" > {Firstname} {MiddleName} {LastName} </div> <p className="text-right ">24/01/2024</p> </div>

                      <Text
                        className="text-lg text-light_blue-A400 cursor-pointer py-2"
                        size="txtMontserratRomanMedium18"
                        onClick={() => navigate("/memorial/" + MemorialAddress)}

                      >
                        <span style={{ cursor: 'pointer' }}>milelemoyoni.com/memorial/{MemorialAddress}</span>
                      </Text>

                      <p className="py-5">
                        I never imagined a day I'd use this site but I guess it
                        came too early for me.
                      </p>
                      <p className="text-right ">Kia</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Page;
