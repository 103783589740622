import { Img } from '../../components';
import React, { useEffect, useState } from "react";
import ApiEndPoints from "../../ApiEndpoints";
import { ToastContainer, toast } from "react-toastify";

import { Footer } from '..//../components/Footer';
import { Header } from '../../components/Header';
import Tabs from '../../components/Tabs';

const Tab = ({ id, label, onClick, isActive }) => (
  <li className={`me-2`} role="presentation">
    <button
      className={`inline-block p-4 border-b-2 rounded-t-lg ${isActive ? 'border-blue-500' : ''
        }`}
      id={`${id}-tab`}
      onClick={() => onClick(id)}
      type="button"
      role="tab"
      aria-controls={id}
      aria-selected={isActive}
    >
      {label}
    </button>
  </li>
);

const TabContent = ({ id, children, isActive }) => (
  <div
    className={`hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800`}
    id={id}
    role="tabpanel"
    aria-labelledby={`${id}-tab`}
  >
    {isActive && children}
  </div>
);




const Page = () => {

  return (
    <>
      <Header />
      <div className="bg-cover bg-center h-72 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500"
        style={{
          backgroundSize: "100% 100%",
          backgroundImage: "url('images/memorialFuneral.webp')",
        }}
      >
        <div className="flex h-full  items-center px-32">
          <div className="w-2/6 bg-opacity-75 bg-white text-center ">
            {/* Content for the right column (6:4 ratio) */}
            <Img
              className="h-[65%]  w-[55%] mx-auto my-auto  object-cover rounded-[15px]"
              src="images/img_rectangle20.png"
              alt="rectangleTwenty"
            />
          </div>
          <div className="w-3/6  bg-opacity-75 bg-white ">
            {/* Content for the left column (6:4 ratio) */}
            <div className='flex h-full flex-col content-evenly' >
             <h1 className="text-3xl text-red-900 font-bold   tracking-widest text-slate-50 text-center ">Celebrating the life of</h1>
              <h2 className="text-6xl my-6  text-slate-50  text-center ">Carlos Chavez</h2>
              <p className=" text-2xl text-slate-50 tracking-wide text-center"> SUNRISE 9999 - SUNSET 9999</p>
            </div>
          </div>
          <div className="w-1/6 bg-opacity-75 bg-white "></div>

        </div>
      </div>
      <Tabs />
    
      {/* <Footer /> */}
    </>
  );
};

export default Page;
