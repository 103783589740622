import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";

const Page = () => {
  return (
    <>
      <Header />
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded shadow-md">
          <h1 className="text-2xl font-bold mb-4">Page is under development </h1>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Page;
