import React, { useEffect, useState } from "react";
import { Button, Img, Text } from "..";
import ApiEndPoints from "../../ApiEndpoints";
import { toast } from "react-toastify";
import Tributes from "../Tribute";

const AboutSection = () => {
  const [data, setData] = useState([]);
  const [dataId, setDataId] = useState(localStorage.getItem("memorialId"));
  const PrevData = (localStorage.setItem("PrevData" , dataId));


  

  const [pageLoaded, setPageLoaded] = useState(false); // State to track page load

  useEffect(() => {
    fetchData();
    if(PrevData == dataId){
      console.log("same Daata")
    }
    else{
      console.log(" no same Daata")
     
    }
  }, [dataId]); // Add dataId as a dependency to re-run effect when it changes

 

  const fetchData = async () => {
    try {
      const response = await fetch(ApiEndPoints.getAllMemorialTraibuteById(dataId));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const datas = await response.json();
      setData(datas);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async ({ selectedDiv, reviewDescription, createdBy }) => {
    if (!selectedDiv || !reviewDescription || !createdBy) {
      toast("Please fill all fields");
      return;
    }

    try {
      const response = await fetch(ApiEndPoints.Addtribute, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          createdBy: createdBy,
          reviewDescription: reviewDescription,
          userId: "1",
          isActive: true,
          createdOn: new Date(),
          traibuteIcon: selectedDiv,
          memorail_id: dataId,
          postType : "memorial"
        }),
      });

      if (response.status === 201) {
        toast("Tribute added successfully");
        
        fetchData();
      } else {
        toast("Failed to add Tribute. Please try again later.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast("An error occurred. Please try again later.");
    }
  };



  return (
    <div>
      <div className="flex flex-col">
        <Text className=" mb-4 text-[30px] italic" style={{ color: "black" }}>
          Forever etched in our hearts
        </Text>
        <ul
          className=" m-4 space-y-1 text-gray-500 list-disc list-outside dark:text-black-400"
          style={{ color: "black" }}
        >
          <li style={{ color: "black" }}>51 years old</li>
          <li style={{ color: "black" }}>
            Born on November 25, 1972 in San Francisco, California, United
            States
          </li>
          <li style={{ color: "black" }}>
            Passed away on January 28, 2024 in Baton Rouge, Louisiana, United
            States
          </li>
        </ul>

        <Text className=" my-4 text-black ">
          This memorial website was created in memory of our loved one, Carlos
          Chavez, 51 years old, born on November 25, 1972, and passed away on
          January 28, 2024. Carlos was a strong man of faith and went home to
          our lord and savior Jesus Christ, whole and healed.
        </Text>

        <Text className=" my-4 text-black  ">
          This memorial website was created in memory of our loved one, Carlos
          Chavez, 51 years old, born on November 25, 1972, and passed away on
          January 28, 2024. Carlos was a strong man of faith and went home to
          our lord and savior Jesus Christ, whole and healed.
        </Text>

        <div className="ml-4">
          <ul className=" m-4 space-y-1 text-gray-500 list-disc list-outside ">
            <li>
              <span
                className="font-bold text-black "
                style={{ color: "black" }}
              >
                Gonzales, Louisiana on February 8th, 2024 from 6 pm - 8 pm at
                41025 LA-621 Gonzales, La 70737.{" "}
              </span>
              <span style={{ color: "black" }}>
                Please come as you are, there will be Carlos's favorite foods
                and sharing about Carlos and his life. While a pastor will open
                for us, no formal service or ceremony is planned. Carlos lived
                in Louisiana from 2011 until his passing and has many friends
                here to celebrate with us.
              </span>
            </li>
            <li>
              <span className="font-bold text-black" style={{ color: "black" }}>
                {" "}
                San Mateo, California on March 16th from 11 am - 1 pm at
                Waypoint Church of San Mateo. Waypoint is located at 194 W 25th
                Avenue San Mateo, Ca 94403. The celebration of life will be held
                in the hall (not the sanctuary) where Carlos & Sarah's wedding
                reception was held 26 years ago.
              </span>{" "}
              <span style={{ color: "black" }}>
                Please come as you are, there will be Carlos's favorite foods
                and sharing about Carlos and his life. While a pastor will open
                for us, no formal service or ceremony is planned. Carlos was
                born in San Francisco and lived in the SF Bay Area until he
                moved with his wife Sarah and daughter Amber to Louisiana. Many
                of our family members and friends remain in the area and we'll
                look forward to seeing them.
              </span>
            </li>
            <li>
              <span className="font-bold text-black" style={{ color: "black" }}>
                {" "}
                Duluth, Minnesota on May 25th at 11 am, taking place graveside
                in the Union Cemetary on Getchell Road (Hermantown) Rd Cross
                Street)
              </span>
              <span style={{ color: "black" }}>
                . Afterward, everyone is welcome to join the family at Barker's
                Island for lunch together. Carlos' wife Sarah was born in
                Minnesota and has a lot of family in both north and south
                Minnesota. Carlos quickly became family to everyone in the
                family he met. Sarah plans to be buried in the family section of
                a cemetery there, and Carlos asked to be buried there so they
                can be together eternally eventually.
              </span>
            </li>
          </ul>
        </div>
        <Text className=" my-4 text-black  " style={{ color: "black" }}>
          All who knew Carlos, Sarah, or Amber are welcome to join one or all of
          the Celebrations of Life events. We intend to make them fun and not
          sad, a sharing of stories and memories while making new memories
          together.
        </Text>
        <Text className=" my-4 text-black  " style={{ color: "black" }}>
          Questions? Email Sarah directly at thesarahchavez@gmail.com.
        </Text>
      </div>
      <Tributes data={data} handleSubmit={handleSubmit} />
    </div>
  );
};

export default AboutSection;
