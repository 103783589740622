import { Button } from "../../components";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { useState } from "react";
import "./page.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import UserMemorial from "../UserMemorial";
import UserObitiury from "../UserObitiury";

const Page = () => {
  const [selectedImage, setSelectedImage] = useState(null);
    const Naviagte = useNavigate()

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    Firstname: "",
    MiddleName: "",
    LastName: "",
    Gender: "",
    Relationship: "",
    MemorialDesignation: "",
    MemorialAddress: "",
    PrivacyOptions: "",
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit1 = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("/api/user/memorial", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "addMemorial",
          user_id: 1,
          Plan: "free",
          ...formData,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Memorial created successfully:", data);
        toast.success("Memorial created successfully");
        setFormData({
          Firstname: "",
          MiddleName: "",
          LastName: "",
          Gender: "",
          Relationship: "",
          MemorialDesignation: "",
          MemorialAddress: "",
          PrivacyOptions: "",
        });
        setStep(1);
      } else {
        const errorData = await response.json();
        console.error("Error creating memorial:", errorData.error);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const handleNext = (e) => {
    console.log("func");
    e.preventDefault();
    if (
      formData.Firstname.length > 0 &&
      formData.LastName.length > 0 &&
      formData.MiddleName.length > 0
    ) {
      setStep(step + 1);
      console.log(step);
    } else {
      console.log("please fill all deteils");
      toast.error("please fill all deteils");
    }
  };
  const handleBack = (e) => {
    console.log("func");
    e.preventDefault();
    setStep(step - 1);
    console.log(step);
  };

  const handleNext1 = (e) => {
    console.log("func");
    e.preventDefault();
    if (
      formData.Gender.length > 0 &&
      formData.Relationship.length > 0 &&
      formData.MemorialDesignation.length > 0
    ) {
      setStep(step + 1);
      console.log(step);
    } else {
      console.log("please fill all deteils");
      toast.error("please fill all deteils");
    }
  };
  const handleBack1 = (e) => {
    console.log("func");
    e.preventDefault();
    setStep(step - 1);
    console.log(step);
  };

  const CreateMemoreil=()=>{
    Naviagte('/creatememorial')
  }

  return (
    <>
      <ToastContainer />
      <Header />
      <div className="mt-8">
        <h2 className="mb-4 text-2xl font-bold text-center text-gray-800 lg:text-3xl md:mb-6">
          My Account
        </h2>

        <div className="text-gray-600">
          <div className="container flex flex-col flex-wrap px-5 py-4 mx-auto">
            <div className="flex flex-col w-full text-center">
              <div className=" bg-white sm:py-8 lg:py-12">
                <div className="px-4 mx-auto max-w-screen-2xl bg-white-A700 py-10 rounded-[20px] formWidth">
                  <div className="flex justify-around border-b border-solid text-[20px]">
                    <div className="pb-5 border-b-2 border-indigo-500"> 
                      <h2>MY MEMORIALS</h2>
                    </div>
                    <div>
                      <h2 style={{cursor:'pointer'}}  onClick={() => Naviagte("/user_Information")}
                     
                      >ACCOUNT INFORMATION</h2>
                    </div>
                  </div>
                  <div className="flex sm:flex-col justify-around my-8">
                    <div>
                      <div>
                        <div className="sm:my-8 flex justify-start">
                          <div>
                            <img
                              src="images/img_mm121.png"
                              style={{
                                width: "100px",
                                height: "auto",
                                padding: "5px",
                              }}
                            />
                          </div>
                          <div className="mx-4 my-1 text-justify">
                            <h2>test test</h2>
                            <p className="text-blue-500">
                              {" "}
                              {/* <PublicIcon className="sm:block" />{" "} */}
                              test-testrererere.forevermissed.com
                            </p>
                            <p>Current plan:Free</p>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                    <div>
                      <div>
                        <div className="sm:my-8 flex">
                          <div className="">
                            {/* <StarIcon className="sm:text-[40px] text-[20px] ml-4 text-yellow-300 " /> */}
                          </div>
                          <div className="mx-4 my-1 text-justify">
                            <p className="text-blue-500"> Upgrade to Premium</p>
                          </div>
                        </div>
                        <div className=" sm:my-8 flex justify-start">
                          <div className="">
                            {/* <DeleteIcon className="sm:text-[40px] text-[20px] ml-4" /> */}
                          </div>
                          <div className="mx-4 my-1 text-justify">
                            <p className="text-blue-500"> Delete </p>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>

                  <div>
                    <div className="my-4">
                      <div className="text-center text-[28px]">
                        <h2>Create an online memorial for your loved one:</h2>
                      </div>
                    </div>
                    <div className="my-4 flex justify-center gap-6 sm:flex-col align-middle">
                      <input
                        className="inputClass my-2"
                        placeholder="First name"
                      />
                      <input
                        placeholder="First name"
                        className="inputClass my-2"
                      />
                      <button className="formbtn mt-[12px]" onClick={CreateMemoreil} >BEGIN</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <UserMemorial/>
      <UserObitiury/> */}
      <Footer />
    </>
  );
};

export default Page