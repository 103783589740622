import logo from './logo.svg';
import './App.css';
import PublicRoutes from './PublicRoutes';
import { ToastContainer , toast } from 'react-toastify';
function App() {
  return (
    <div >
      <ToastContainer/>
       <PublicRoutes />
   {/* <p class="text-red-700">The quick brown fox...</p> */}
    </div>
  );
}

export default App;
