import React from "react";

const sizeClasses = {
  txtMontserratMedium20: "font-medium font-montserrat",
  txtMontserratItalicMedium24: "font-medium font-montserrat italic",
  txtMontserratMedium25: "font-medium font-montserrat",
  txtMontserratItalicMedium20: "font-medium font-montserrat italic",
  txtPlayfairDisplayExtraBoldItalic40:
    "font-extrabold font-playfairdisplay italic",
  txtPlayfairDisplayRomanMedium60: "font-medium font-playfairdisplay",
  txtPlayfairDisplayBold43: "font-bold font-playfairdisplay",
  txtPlayfairDisplayBold44: "font-bold font-playfairdisplay",
  txtMontserratRegular18: "font-montserrat font-normal",
  txtPlayfairDisplayBold36: "font-bold font-playfairdisplay",
  txtOpenSansRegular20: "font-normal font-opensans",
  txtMontserratRegular16: "font-montserrat font-normal",
  txtMontserratRomanMedium18: "font-medium font-montserrat",
  txtMontserratRomanSemiBold30: "font-montserrat font-semibold",
  txtPoppinsMedium22Black900: "font-medium font-poppins",
  txtMontserratBold25: "font-bold font-montserrat",
  txtMontserratBold20: "font-bold font-montserrat",
  txtOpenSansRegular18: "font-normal font-opensans",
  txtOpenSansRegular16: "font-normal font-opensans",
  txtPlayfairDisplayRomanMedium30: "font-medium font-playfairdisplay",
  txtPlayfairDisplayBold30: "font-bold font-playfairdisplay",
  txtPoppinsMedium22: "font-medium font-poppins",
  txtOpenSansSemiBold28: "font-opensans font-semibold",
  txtPlayfairDisplayRomanBold100: "font-bold font-playfairdisplay",
  txtOpenSansSemiBold44: "font-opensans font-semibold",
  txtPoppinsRegular20: "font-normal font-poppins",
  txtMontserratRomanMedium18Black900: "font-medium font-montserrat",
  txtMontserratMedium20Bluegray700: "font-medium font-montserrat",
  txtMontserratItalicMedium20Gray700: "font-medium font-montserrat italic",
  txtMontserratRomanMedium24: "font-medium font-montserrat",
};

const Text = ({ children, className = "", size, as, ...restProps }) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
