import { useParams } from 'react-router-dom';
import { Img } from '../../components';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import Tabs from '../../components/Tabs';
import React, { useEffect, useState } from 'react';
import ApiEndPoints from '../../ApiEndpoints';
import { changeDateFormate } from '../../utils';
import Modal from 'react-modal';
import SocialShareButton from '../../components/SocialShareButton';

const Page = () => {

  const { url } = useParams(); // `url` corresponds to the parameter name in the route path
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);


  const [memorialDetails, setMemorialDetails] = useState({
    id: "",
    user_id: "",
    Firstname: "",
    MiddleName: "",
    LastName: "",
    Gender: "",
    ProfileImgUrl: "",
    DateOfBirth: "",
    DateOfDeath: "",
    Relationship: "",
    MemorialDesignation: "",
    MemorialAddress: "",
    PrivacyOptions: "",
    Plan: "",
    CreatedBy: "",
    CreatedOn: "",
    Title: ""
  },);

  const fetchMemorialDetails = async () => {

    try {
      const response = await fetch(ApiEndPoints.getMemorialByAddress + '/' + url);

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("memorialId", data.id);
        console.log("data Idsss ", data.id)
        setMemorialDetails(data)
      } else {
        const errorData = await response.json();
        console.error("Error getting memorial:", errorData.error);
      }
    } catch (error) {
      console.error("Network error:", error);
    }




  }

  console.log(url, " - URl")
  useEffect(() => {
    fetchMemorialDetails();
  }, [])

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };
  return (
    <>
      <Header />
      <div className="bg-cover bg-center h-72 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500"
        style={{
          backgroundSize: "100% 100%",
          backgroundImage: "url('/images/memorialFuneral.webp')",
        }}
      >
        <div className="flex h-full  items-center">
          <div className="w-2/3 bg-opacity-75 bg-white p-6 flex justify-center">
            {/* Content for the left column (6:4 ratio) */}
            <div>
              <h1 className="text-5xl my-3  text-slate-50 ">{memorialDetails.Firstname} {memorialDetails.MiddleName} {memorialDetails.LastName}</h1>
              <p className=" text-5xl text-slate-50 "> {changeDateFormate(memorialDetails.DateOfBirth)}  - {changeDateFormate(memorialDetails.DateOfDeath)}</p>
            </div>
          </div>
          <div className="w-1/3 bg-opacity-75 bg-white p-6">
            {/* Content for the right column (6:4 ratio) */}
            <Img
              className="h-[60%]  w-[50%] md:h-auto object-cover rounded-[15px]"
              src={ApiEndPoints.Image_URL + "/" + memorialDetails.ProfileImgUrl || "images/img_rectangle21.png"}
              alt="rectangleTwenty"
              onClick={() => openModal(ApiEndPoints.Image_URL + "/" + memorialDetails.ProfileImgUrl)}
            />
          </div>
        </div>
      </div>
      <Tabs />
      <div className="  ml-16 flex  flex justify-between  my-5  md:w-full">

   
        <SocialShareButton />



      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
            border: 'none',
            background: 'none',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
      >
        <img
          src={selectedImage}
          alt="Full Size"
          style={{ maxHeight: '90vh', maxWidth: '90vw' }}
          onClick={closeModal}
        />
      </Modal>
      {/* <Footer /> */}
    </>
  );
};

export default Page;
