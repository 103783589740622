"use client";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Page = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    "content": "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit1 = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("/api/user/memorial", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "action": "addTextContentMemorial",
          "user_id": 1,
          "content_key": "Title",
          "memorial_id": 1,
          ...formData,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Memorial created successfully:", data);
        toast.success("Memorial created successfully");
        setFormData({ "content": "" }); // Reset only the content field
      } else {
        const errorData = await response.json();
        console.error("Error creating memorial:", errorData.error);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <Header />
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded shadow-md">
          <h1 className="text-2xl font-bold mb-4">Create Memorial Page</h1>
          <form onSubmit={handleFormSubmit1}>
            {/* Content input field */}
            <textarea
              name="content"
              placeholder="Enter Memorial Content"
              onChange={handleChange}
              value={formData.content}
              className="mt-1 p-2 border rounded-md w-full"
              required
            />

            {/* Submit button */}
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
              Create Memorial
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Page;
