import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Obituaries } from "../../components/Obituaries";
const Page = () => {
    return (
        <>
            <Header />
            <div className="pt-6"  >
                <h1 style={{ fontSize: '30px', textAlign: 'center', paddingBottom: '20px' }}>  Obituaries </h1>
                <div className="w-[90%] bg-white-A700  m-auto py-4"
                    style={{ borderRadius: '20px' }} >
                    <Obituaries />
                   
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Page;
