import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';


import { Button } from './Button';
import { Img } from './Img';

const SocialShareButton = () => {
  const [showOptions, setShowOptions] = useState(false);

  const handleClick = (platform) => {
    let shareUrl = '';
    const url = window.location.href;
    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${url}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
        break;
      default:
        break;
    }
    window.open(shareUrl, '_blank');
  };

  return (
    <div className="relative">
      <Tooltip title="Share">
        <Button
          className="mont-regular capitalize cursor-pointer font-medium font-poppins leading-[normal] min-w-[351px] text-center text-lg"
          shape="round"
          color="red_A700"
          size="md"
          variant="fill"
          onClick={() => setShowOptions(!showOptions)}
        >
          Share social media
        </Button>

      </Tooltip>
      {showOptions && (
        <div className="absolute flex space-x-4 mt-1 bg-white ">
          <div className="flex flex-row gap-3.5 items-start justify-start  w-full ">
            <Button
              className="flex h-7 items-center justify-center ml-0.5 md:ml-[0] w-7"
              shape="circle"
              color="white_A700"
              size="sm"
              variant="fill"
              onClick={()=>handleClick("facebook")}
            >
              <Img src="/images/img_facebook.svg" alt="facebook_Two" />
            </Button>
            <Button
              className="flex h-7 items-center justify-center w-7"
              shape="circle"
              color="white_A700"
              size="sm"
              variant="fill"
               onClick={()=>handleClick("twitter")}
            >
              <Img src="/images/img_twitter.svg" alt="twitter_Two" />
            </Button>
            <Button
              className="flex h-7 items-center justify-center w-7"
              shape="circle"
              color="white_A700"
              size="sm"
              variant="fill"
               onClick={()=>handleClick("youtube")}
            >
              <Img src="/images/img_youtube.svg" alt="youtube_Two" />
            </Button>
            <Button
              className="flex h-7 items-center justify-center w-7"
              shape="circle"
              color="white_A700"
              size="sm"
              variant="fill"
               onClick={()=>handleClick("facebook")}
            >
              <Img src="/images/img_instagram.svg" alt="instagram_Two" />
            </Button>
          </div>
        </div>
      )}

    </div>
  );
};

export default SocialShareButton;
