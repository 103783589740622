

import React,{useState , useEffect} from 'react'

import './AdminstyleNew.css'
import { FaSearchLocation } from "react-icons/fa";
import { RxDashboard } from "react-icons/rx";
import { SiGoogleforms } from "react-icons/si";
import { CiCircleList } from "react-icons/ci";
import { IoIosSettings } from "react-icons/io";
import { CiLogout } from "react-icons/ci";
import { GrUserAdmin } from "react-icons/gr";
import { Link  , useNavigate} from 'react-router-dom';
import Hamburger from 'hamburger-react'
import AdminSidebar from './AdminSidebar';
import AdminMobileSidebar from './AdminMobileSidebar';
import ApiEndPoints from '../../ApiEndpoints';
function AdminNotification() {

    const [Data, setData] = useState([]);
    const [Data1, setData1] = useState([]);
    const [Data2, setData2] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const [usermsg , setUsersMsg] = useState([]);
    const [isOpen, setOpen] = useState(false)

    const navigate = useNavigate();

   
    const fetchData = async () => {
      try {
        const response = await fetch(ApiEndPoints.getContact);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const datas = await response.json();
        setData(datas);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


   
    useEffect(()=>{
      fetchData()
    
    },[])

    const handleDeleteContact = async (id) => {
      try {
          const response = await fetch(`${ApiEndPoints.deleteContact}/${id}`, {
              method: 'DELETE',
          });
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          // After successful deletion, refetch data to update the UI
          fetchData();
      } catch (error) {
          console.error('Error deleting contact:', error);
      }
  };





  const handleLogout=()=>{
    navigate('/');
    window.localStorage.clear();

  }

  const filteredData = Data.filter((user) => {
    const fullName = `${user.name}`;
    return fullName.toLowerCase().includes(searchQuery.toLowerCase());
  });


  return (
   <>
 

 <body >
  <AdminSidebar/>
   
 
  <section class="home-section">
    <AdminMobileSidebar/>

  
    <div class="home-content">
            <div class="sales-boxes">
              <div class="recent-sales box">
              <div class="title">All Notifications</div>
                {/* Search input field */}
                <div class="search-box">
                <div>
            {/* <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone number</label> */}
            <input type="text" id="phone" class=" mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
               placeholder="Search by Name"
               value={searchQuery}
               onChange={(e) => setSearchQuery(e.target.value)}
            />        </div>

                </div>
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-10 mb-5">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                     Name
                </th>
                <th scope="col" class="px-6 py-3">
                    Email
                </th>
                <th scope="col" class="px-6 py-3">
                    Memorial Name
                </th>
                <th scope="col" class="px-6 py-3">
                Message
                </th>
                <th scope="col" class="px-6 py-3">
                    Action
                </th>
            </tr>
        </thead>
        <tbody>
                                        {filteredData.map((user) => (
                                            <tr
                                                key={user.id}
                                                class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                                            >
                                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {user.name}
       
                                                </td>
                                                <td class="px-6 py-4">  {user.email}
  
</td>
                                                <td class="px-6 py-4"> {user.memorialName}</td>
                                                <td class="px-6 py-4"> {user.message}</td>

                                                <td className="px-6 py-4">
                                                    <button
                                                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                                        onClick={() => handleDeleteContact(user.id)}
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
    </table>
                <div class="button">
                  <Link
                    to="#"
                    
                    className="mx-2"
                    
                  >
                    Previous
                  </Link>
                  <Link
                    to="#"
                  
                    className="mx-2"
                  
                  >
                    Next
                  </Link>
                </div>
              </div>
            </div>
          </div>
  </section>


</body>

   
   </>

  )
}

export default AdminNotification
