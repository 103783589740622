


import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Img, Input, Line, PagerIndicator, Text } from "../../components";
import ApiEndPoints from "../../ApiEndpoints";
import moment from "moment";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
const Page = () => {

    const ITEMS_PER_PAGE = 5
    const Naviagte = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [obituaries, setObituaries] = useState([])
    const [dailyObituariesDatas, setDailyObituariesData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');


    const dailyObituariesData = Array.from({ length: 50 }, (_, index) => ({
        id: index + 1,
        userName: `User ${index + 1}`,
    }));

    // Calculate the index range for the current page
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    // Get the items to display for the current page
    const currentDailyObituaries = dailyObituariesData.slice(startIndex, endIndex);

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };


    const fetchObituaries = async (event) => {
        try {
            const response = await fetch(ApiEndPoints.getObituary);

            if (response.ok) {
                const data = await response.json();
                console.log(" get Obituaries successfully:", data);
                setObituaries(data)
            } else {
                const errorData = await response.json();
                console.error("Error creating memorial:", errorData.error);
            }
        } catch (error) {
            console.error("Network error:", error);
        }
    };


    useEffect(() => {
        fetchObituaries();
    }, [])

    const chunkedObituaries = [];
    for (let i = startIndex; i < obituaries.length; i += 5) {
        chunkedObituaries.push(obituaries.slice(i, i + 5));
    }


    console.log(obituaries)

    const viewObituaryDetails = (obituaryId) => {
        Naviagte(`/obituarieView}`);



    };

    const filteredObituaries = obituaries.filter(obituary => {
        const fullName = `${obituary.Firstname} ${obituary.LastName}`.toLowerCase();
        return fullName.includes(searchQuery.toLowerCase());
    });

    // Event handler to update the search query
    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    // Clear search query
    const clearSearch = () => {
        setSearchQuery('');
    };


    useEffect(() => {
        const today = moment().format('YYYY-MM-DD'); // Get today's date in "YYYY-MM-DD" format
        console.log(today);
        const filteredObituaries = obituaries.filter(obituary => {
            if (obituary.CreatedOn) {
                const obituaryDate = moment(obituary.CreatedOn).format('YYYY-MM-DD');
                console.log(obituaryDate, today);
                return obituaryDate === today;
            }
            return false; // If CreatedOn is null or undefined, exclude the obituary from the filtered list
        });
        setDailyObituariesData(filteredObituaries);
        console.log("dailyObe", filteredObituaries);
    }, [obituaries]);
    return (
        <>
            <Header />
            <div className="pt-6"  >
            <div className="w-[90%] m-auto flex flex-row justify-between">
          <div className="w-52 pr-2"></div>
          <h1
            style={{
              fontSize: "30px",
              textAlign: "center",
              paddingBottom: "20px",
            }}
          >
            All Obituaries{" "}
          </h1>
          <div className="font-montserrat">
            <Button
              className="mont-regular capitalize cursor-pointer font-medium font-poppins leading-[normal] min-w-[300px] text-center text-lg"
              shape="round"
              color="light_blue_A400"
              size="md"
              variant="fill"
              onClick={() => Naviagte("/createObituaries")}
            >
              Post an Obituary here
            </Button>
          </div>
        </div>
                <div className="w-[90%] bg-white-A700  m-auto py-4"
                    style={{ borderRadius: '20px' }} >
                    <>
                        <form class="flex items-center my-4  mx-auto" onSubmit={clearSearch} style={{ paddingTop: '2px', paddingBottom: '18px', maxWidth: '45rem' }}>
                            <label for="voice-search" class="sr-only">Search</label>
                            <div class="relative w-full">
                                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.15 5.6h.01m3.337 1.913h.01m-6.979 0h.01M5.541 11h.01M15 15h2.706a1.957 1.957 0 0 0 1.883-1.325A9 9 0 1 0 2.043 11.89 9.1 9.1 0 0 0 7.2 19.1a8.62 8.62 0 0 0 3.769.9A2.013 2.013 0 0 0 13 18v-.857A2.034 2.034 0 0 1 15 15Z" />
                                    </svg>
                                </div>
                                <input type="text" id="voice-search"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search by name..." required />
                                <button type="button" class="absolute inset-y-0 end-0 flex items-center pe-3">

                                </button>
                            </div>

                        </form>

                        <div className=" flex flex-col items-center justify-start px-[15px] pb-[38px]  mx-auto md:px-5 w-full">

                            {/* <div className="text-center w-full">
                                <div className="bg-gray-300 text-center hover:cursor-pointer items-center  w-full">
                                    <Text
                                        className="sm:text-[14px] md:text-[20px] text-[24px] text-center text-light_green-300"
                                        size="txtPlayfairDisplayBold44"
                                    >
                                        <span className="text-red-A700 text-center font-playfairdisplay font-bold">
                                            All OBITUARIES
                                        </span>
                                    </Text>
                                </div>

                            </div> */}
                            {filteredObituaries.map((obituary, index) => (
                                index % 3 === 0 && (
                                    <div key={index} className="flex justify-around w-full">
                                        {filteredObituaries.slice(index, index + 3).map((obituary) => (
                                            <div key={obituary.id} className="relative m-2" onClick={() => viewObituaryDetails(obituary.id)}>
                                                <Img
                                                    className="h-64 w-64 object-cover rounded-lg"
                                                    src={ApiEndPoints.Image_URL + "/" + obituary.ProfileImgUrl || "images/img_rectangle21.png"}
                                                    alt={obituary.FirstName}
                                                />
                                                <div className="absolute bottom-0 left-0 right-0 bg-gray-500/75 text-slate-950 text-center py-2">
                                                    <p className="text-lg font-bold">{obituary.Firstname} {obituary.LastName}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )
                            ))}

                            <Button
                                className="common-pointer capitalize cursor-pointer font-medium popinsfont leading-[normal] min-w-[208px] mt-6 sm:text-[21px] md:text-[23px] text-[25px] text-center"
                                onClick={() => Naviagte("/alllistObituaries")}
                                shape="round"
                                color="red_A700"
                                size="sm"
                                variant="fill"
                            >
                                VIEW ALL
                            </Button>
                        </div>
                        <div className=" flex flex-col items-center justify-start px-[15px] pb-[38px] mx-auto md:px-5 w-full">
                            <div className="text-center w-full">
                                <div className="bg-gray-300 text-center hover:cursor-pointer items-center w-full">
                                    <Text
                                        className="sm:text-[14px] md:text-[20px] text-[24px] text-center text-light_green-300"
                                        size="txtPlayfairDisplayBold44"
                                    >
                                        <span className="text-red-A700 text-center font-playfairdisplay font-bold">
                                            DAILY OBITUARIES
                                        </span>
                                    </Text>
                                </div>
                            </div>

                            <div className="flex md:flex-wrap md:gap-[68px] sm:flex-col flex-row sm:gap-10 items-center justify-around mt-[34px] w-full">
                                {dailyObituariesDatas.slice(startIndex, endIndex).map(dailyObituary => (
                                    <div key={dailyObituary.id} className="relative m-2" onClick={() => viewObituaryDetails(dailyObituary.id)}>
                                        <Img
                                            className="h-64 w-64 object-cover rounded-lg"
                                            src={ApiEndPoints.Image_URL + "/" + dailyObituary.ProfileImgUrl || "images/img_rectangle21.png"}
                                            alt={dailyObituary.FirstName}
                                        />
                                        <div className="absolute bottom-0 left-0 right-0 bg-gray-500/75 text-slate-950 text-center py-2">
                                            <p className="text-lg font-bold">{dailyObituary.Firstname} {dailyObituary.LastName}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Pagination Controls */}
                            <div className="flex items-center justify-center mt-4">
                                <Button
                                    className="common-pointer font-medium popinsfont leading-[normal] min-w-[48px] text-center"
                                    onClick={handlePrevPage}
                                    shape="round"
                                    color="red_A700"
                                    size="sm"
                                    variant="outline"
                                    disabled={currentPage === 1}
                                >
                                    Prev
                                </Button>
                                <Text className="mx-4 text-lg font-medium">{currentPage}</Text>
                                <Button
                                    className="common-pointer font-medium popinsfont leading-[normal] min-w-[48px] text-center"
                                    onClick={handleNextPage}
                                    shape="round"
                                    color="red_A700"
                                    size="sm"
                                    variant="outline"
                                    disabled={endIndex >= dailyObituariesData.length}
                                >
                                    Next
                                </Button>
                            </div>

                            <Button
                                className="common-pointer capitalize cursor-pointer font-medium popinsfont leading-[normal] min-w-[208px] mt-6 sm:text-[21px] md:text-[23px] text-[25px] text-center"
                                onClick={() => Naviagte("/alllistObituaries")}
                                shape="round"
                                color="red_A700"
                                size="sm"
                                variant="fill"
                            >
                                VIEW ALL
                            </Button>
                        </div>

                    </>

                </div>
            </div>
            <Footer />
        </>
    );
};

export default Page;
