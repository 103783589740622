import React, { useState, useEffect } from "react";
import { Img, Text } from "..";
import Gallery from "../Gallery";
import LifeSection from "../LifeSection";
import StoriesSection from "../StoriesSection";
import Anniversaries from "../Anniversaries";
import Event from "../Event";
import AboutSection from "../aboutSection";

const Tab = ({ id, label, onClick, isActive }) => (
  <li className={`me-2`} role="presentation">
    <button
      className={`inline-block p-4 border-b-2 px-12 rounded-t-lg ${
        isActive ? "border-blue-500 bg-blue-500" : ""
      }`}
      id={`${id}-tab`}
      onClick={() => onClick(id)}
      type="button"
      role="tab"
      aria-controls={id}
      aria-selected={isActive}
    >
      {label}
    </button>
  </li>
);

const TabContent = ({ id, children, isActive }) => (
  <div
    className={` p-4 rounded-lg bg-gray-50 dark:bg-gray-800`}
    id={id}
    role="tabpanel"
    aria-labelledby={`${id}-tab`}
  >
    {isActive && children}
  </div>
);

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("about");
  const [audioElement, setAudioElement] = useState(null);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    const audio = document.getElementById("backgroundAudio");
    setAudioElement(audio);

    // Add event listener for user interaction (click)
    document.addEventListener("click", handleUserInteraction);

    return () => {
      // Clean up event listener on component unmount
      document.removeEventListener("click", handleUserInteraction);
    };
  }, []);

  // Function to handle user interaction
  const handleUserInteraction = () => {
    // Check if audio element exists and play the audio
    if (audioElement) {
      audioElement.play();
    }
  };

  const playAudio = () => {
    if (audioElement) {
      if (audioElement.paused) {
        audioElement.play();
      } else {
        audioElement.pause();
      }
    }
  };

  return (
    <>
      <div className="my-4 bg-gray-100 px-24 border-b border-gray-200 dark:border-gray-700">
        <ul
          className="flex flex-wrap  -mb-px text-sm font-medium text-center"
          id="default-tab"
          data-tabs-toggle="#default-tab-content"
          role="tablist"
        >
          <Tab
            id="about"
            label="About"
            onClick={handleTabClick}
            isActive={activeTab === "about"}
          />
          <Tab
            id="life"
            label="Journey of Life"
            onClick={handleTabClick}
            isActive={activeTab === "life"}
          />
          <Tab
            id="gallery"
            label="Life in Camera"
            onClick={handleTabClick}
            isActive={activeTab === "gallery"}
          />
          <Tab
            id="stories"
            label="Share Memories"
            onClick={handleTabClick}
            isActive={activeTab === "stories"}
          />
          {/* <Tab
            id="Event"
            label="Event"
            onClick={handleTabClick}
            isActive={activeTab === "Event"}
          /> */}

          <Tab
            id="Anniversaries"
            label="Anniversaries"
            onClick={handleTabClick}
            isActive={activeTab === "Anniversaries"}
          />

          <div className="audio-controls">
            <img
              onClick={playAudio}
              style={{
                top: "13px",
                position: " relative",
                height: "29px",
                cursor: "pointer",
              }}
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgsyEwiY7f8yx-UMMb1uVCSZJhxOxuCw_Q16UAGkpVreOKHV4SAC-U0xDmMSbjxMD8aMs&usqp=CAU"
            />
            {/* <button onClick={pauseAudio}>Pause</button> */}
          </div>
          <audio
            id="backgroundAudio"
            controls
            autoPlay
            loop
            style={{ height: "30px", visibility: "hidden" }}
          >
            <source
              src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
              type="audio/mpeg"
            />
            Your browser does not support the audio element.
          </audio>
        </ul>
      </div>
      <div id="default-tab-content">
        <div
          className="flex flex-row "
          style={{ paddingLeft: "6rem", paddingRight: "6rem" }}
        >
          <div className="w-9/12 pr-3 ">
            {activeTab === "about" && (
              <TabContent id="about" isActive={activeTab === "about"}>
              <AboutSection />
              </TabContent>
            )}
            {activeTab === "life" && (
              <TabContent id="life" isActive={activeTab === "life"}>
                <LifeSection />
              </TabContent>
            )}
            {activeTab === "gallery" && (
              <TabContent id="gallery" isActive={activeTab === "gallery"}>
                <Gallery />
              </TabContent>
            )}
            {activeTab === "stories" && (
              <TabContent id="stories" isActive={activeTab === "stories"}>
                <StoriesSection />
              </TabContent>
            )}
            {activeTab === "Anniversaries" && (
              <TabContent
                id="Anniversaries"
                isActive={activeTab === "Anniversaries"}
              >
                <Anniversaries />
              </TabContent>
            )}
            {activeTab === "Event" && (
              <TabContent id="Evnet" isActive={activeTab === "Event"}>
                <Event />
              </TabContent>
            )}
          </div>

          <div className="w-3/12 pl-3">
            {" "}
            <div className="  bg-slate-50  rounded-md p-4 mb-5">
              <strong className="text-[20px]">
                This website is administered by:
              </strong>{" "}
              <p>Test User Data</p>
            </div>
            <div className=" bg-slate-50  rounded-md p-4">
              <strong className="text-[20px]"> Photos </strong>{" "}
              <div className="flex justify-center my-4">
                <Img
                  className="h-[90%]  w-[95%] md:h-auto object-cover rounded-[15px]"
                  src="/images/img_rectangle20.png"
                  alt="rectangleTwenty"
                />
              </div>
              <strong className="text-[20px]">Add Photos </strong>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tabs;
