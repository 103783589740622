import React, { useState, useEffect } from 'react'

import './AdminstyleNew.css'
import { FaSearchLocation } from "react-icons/fa";
import { RxDashboard } from "react-icons/rx";
import { SiGoogleforms } from "react-icons/si";
import { CiCircleList } from "react-icons/ci";
import { IoIosSettings } from "react-icons/io";
import { CiLogout } from "react-icons/ci";
import { GrUserAdmin } from "react-icons/gr";
import { Link, useNavigate } from 'react-router-dom';
import Hamburger from 'hamburger-react'
import AdminSidebar from './AdminSidebar';
import AdminMobileSidebar from './AdminMobileSidebar';
import ApiEndPoints from '../../ApiEndpoints';
import { ToastContainer, toast } from "react-toastify";
import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function AdminUsers() {

  const [Data, setData] = useState([]);
  const [Data1, setData1] = useState([]);
  const [Data2, setData2] = useState([]);
  const [open, setOpen] = useState(false);
  const [openA, setOpenA] = useState(false);

  const [editedData, setEditedData] = useState({});
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [lastname, setLastname] = useState();
  const [password, setPassword] = useState();

  const [searchQuery, setSearchQuery] = useState('');


  const [usermsg, setUsersMsg] = useState([]);

  const navigate = useNavigate();


  const fetchData = async () => {
    try {
      const response = await fetch(ApiEndPoints.Allusers);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const datas = await response.json();
      setData(datas);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };




  useEffect(() => {
    fetchData()

  }, [])


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
    console.log(editedData)
  };





  const handleDelete = async (id) => {
    console.log("ID", id)
    try {
      const response = await fetch(`${ApiEndPoints.DeleteUsers}/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      else {
        toast("User Deleted")
        fetchData();
      }

    } catch (error) {
      console.error('Error deleting contact:', error);
    }
  };

  const filteredData = Data.filter((user) => {
    const fullName = `${user.firstName}  ${user.lastName}`;
    return fullName.toLowerCase().includes(searchQuery.toLowerCase());
  });


  const handleSaveChanges = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Check if all fields are filled
    if (!name || !lastname || !email || !password) {
      return;
    }

    const response = await fetch(ApiEndPoints.register, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        firstName: name,
        lastName: lastname,
      }),
    });

    if (response.status === 201) {
      setName('');
      setLastname('');
      setEmail('');
      setPassword('')
      toast("Registration Complete");
      handleClose();
      fetchData();


    } else if (response.status === 400) {
      toast("Email already registered");
      setName('');
      setLastname('');
      setEmail('');
      setPassword('')

    } else {
      toast("Please Try again Later");
      console.log("Registration failed");
    }

    console.log(response);
  };

  const handleEdit = (user) => {
    console.log(user)
    setEditedData(user);
    console.log(editedData)
    setOpen(true);
  };

  const handleView = (user) => {
    navigate('/user_Account')
  };




  const handleAdd = () => {

    setOpenA(true);
  };


  const handleEditProfile = async () => {
    try {
      const response = await fetch(`${ApiEndPoints.updateUserProfile}/${editedData.userid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedData),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      // Refetch data after update
      fetchData();
      setOpen(false);
    } catch (error) {
      console.error("Error updating memorial:", error);
    }
  };





  const handleClose = () => {
    setOpen(false);
    setOpenA(false);
  };

  return (
    <>


      <body >
        <AdminSidebar />


        <section class="home-section">
          <AdminMobileSidebar />


          <div class="home-content">
            <div class="sales-boxes">
              <div class="recent-sales box">
                <div class="title">All Users                 <Button variant="contained" onClick={handleAdd} >Add User</Button>
                </div>

                {/* Search input field */}
                <div class="search-box">
                  <div>
                    {/* <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone number</label> */}
                    <input type="text" id="phone" class=" mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search by Name"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>

                </div>
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-10 mb-5">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-3">
                        First Name
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Last Name
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Email
                      </th>
                      {/* <th scope="col" class="px-6 py-3">
                    Plan
                </th> */}
                      <th scope="col" class="px-6 py-3">
                        Action
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Edit
                      </th>
                      <th scope="col" class="px-6 py-3">
                        View
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((user) => (
                      <tr
                        key={user.id}
                        class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                      >
                        <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {user.firstName}
                        </td>
                        <td class="px-6 py-4">{user.lastName}</td>
                        {/* <td class="px-6 py-4">{user.Relationship}</td> */}
                        <td class="px-6 py-4">{user.email}</td>
                        <td className="px-6 py-4">
                          <button
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                            onClick={() => handleDelete(user.userid)}
                          >
                            Delete
                          </button>
                        </td>
                        <td className="px-6 py-4">
                          <button
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                            onClick={() => handleEdit(user)}
                          >
                            Edit
                          </button>
                        </td>
                        <td className="px-6 py-4">
                          <button
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                            onClick={() => handleView(user)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div class="button">
                  <Link
                    to="#"

                    className="mx-2"

                  >
                    Previous
                  </Link>
                  <Link
                    to="#"

                    className="mx-2"

                  >
                    Next
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <React.Fragment>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={openA}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Add User
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                {/* Close icon */}
              </IconButton>
              <DialogContent dividers>
                <form className="formCustom">
                  {/* Render form fields with current data */}
                  <div className="flex flex-col mb-4 ">
                    <label htmlFor="name" className="inline-flex mb-2 text-sm text-gray-800">
                      First Name
                    </label>
                    <input
                      name="Firstname"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      style={{ height: "30px" }}
                      className="w-full px-3 py-2 text-gray-800 border rounded outline-none bg-gray-50 focus:ring ring-indigo-300"
                    />
                  </div>

                  <div className="flex flex-col mb-4">
                    <label
                      htmlFor="name"
                      className="inline-flex mb-2 text-sm text-gray-800"
                    >
                      last Name
                    </label>
                    <input
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}

                      style={{ height: "30px" }}
                      className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      
                      "
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    <label
                      htmlFor="name"
                      className="inline-flex mb-2 text-sm text-gray-800"
                    >
                      email
                    </label>
                    <input

                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ height: "30px" }}
                      className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      
                      "
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    <label
                      htmlFor="name"
                      className="inline-flex mb-2 text-sm text-gray-800"
                    >
                      Password
                    </label>
                    <input

                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{ height: "30px" }}
                      className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      
                      "
                    />
                  </div>

                </form>
              </DialogContent>
              <DialogActions>

                <Button variant="contained" onClick={handleSaveChanges}>Save</Button>

              </DialogActions>
            </BootstrapDialog>
          </React.Fragment>
          <React.Fragment>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Edit User
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                {/* Close icon */}
              </IconButton>
              <DialogContent dividers>
                <form className="formCustom">
                  {/* Render form fields with current data */}
                  <div className="flex flex-col mb-4 ">
                    <label htmlFor="name" className="inline-flex mb-2 text-sm text-gray-800">
                      First Name
                    </label>
                    <input
                      name="firstName"
                      value={editedData.firstName || ""}
                      onChange={handleInputChange}
                      style={{ height: "30px" }}
                      className="w-full px-3 py-2 text-gray-800 border rounded outline-none bg-gray-50 focus:ring ring-indigo-300"
                    />
                  </div>

                  <div className="flex flex-col mb-4">
                    <label
                      htmlFor="name"
                      className="inline-flex mb-2 text-sm text-gray-800"
                    >
                      last Name
                    </label>
                    <input
                      name='lastName'
                      value={editedData.lastName || ""}
                      onChange={handleInputChange}

                      style={{ height: "30px" }}
                      className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      
                      "
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    <label
                      htmlFor="name"
                      className="inline-flex mb-2 text-sm text-gray-800"
                    >
                      email
                    </label>
                    <input

                      name='email'
                      value={editedData.email || ""}
                      onChange={handleInputChange}
                      style={{ height: "30px" }}
                      className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      
                      "
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    <label
                      htmlFor="name"
                      className="inline-flex mb-2 text-sm text-gray-800"
                    >
                      Password
                    </label>
                    <input
                      name='password'
                      value={editedData.
                        password || ""}
                      onChange={handleInputChange}
                      style={{ height: "30px" }}
                      className="
                        w-full
                        px-3
                        py-2
                        text-gray-800
                        border
                        rounded
                        outline-none
                        bg-gray-50
                        focus:ring
                        ring-indigo-300
                      
                      "
                    />
                  </div>

                </form>
              </DialogContent>
              <DialogActions>

                <Button variant="contained" onClick={handleEditProfile}>Save</Button>

              </DialogActions>
            </BootstrapDialog>
          </React.Fragment>


        </section>


      </body>


    </>

  )
}

export default AdminUsers
