import React, { useEffect, useState } from "react";
import ApiEndPoints from "../../ApiEndpoints";
import ReactPlayer from "react-player";
import Modal from 'react-modal';

const Tab = ({ id, label, onClick, isActive }) => (
  <li
    className={`me-2  w-[32%] border-b-2  ${
      isActive ? "border-blue-800 bg-blue-500" : ""
    } `}
    role="presentation"
  >
    <button
      className={`inline-block p-4 px-12 rounded-t-lg `}
      id={`${id}-tab`}
      onClick={() => onClick(id)}
      type="button"
      role="tab"
      aria-controls={id}
      aria-selected={isActive}
    >
      {label}
    </button>
  </li>
);

const TabContent = ({ id, children, isActive }) => (
  <div
    className={` p-4 rounded-lg bg-gray-50 dark:bg-gray-800`}
    id={id}
    role="tabpanel"
    aria-labelledby={`${id}-tab`}
  >
    {isActive && children}
  </div>
);

const Gallery = () => {
  const [activeTab, setActiveTab] = useState("image");
  const [ytLink, setYtLink] = useState("");
  let memorial = localStorage.getItem("memorialId");
  const [mediaData, setMediaData] = useState([]);
  let dataImage = [];
  let dataVideo = [];
  let dataAudio = [];
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    fetchMemorialMedia(activeTab);
  }, []);

  let fetchMemorialMedia = async (activeTab) => {
    let fileType = activeTab === "image" ? "images" : activeTab;
    let url =
      ApiEndPoints.getAllMediaMemorial +
      `?memorial=${memorial}&fileType=${fileType}`;
    const response = await fetch(url);

    if (response.ok) {
      let data = await response.json();
      let gridData = [];
      let rowData = [];
      let count = 0;
      data.map((item, index) => {
        count++;
        if (count == 3) {
          gridData.push([...rowData]);
          rowData = [];
          count = 0;
        } else {
          rowData.push(item);
        }
      });
      if (count != 0) {
        gridData.push([...rowData]);
      }
      setMediaData(gridData);

      console.log("File Uploaded gridData", gridData);
    }
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    fetchMemorialMedia(tabId);
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];
    let limt =  (activeTab ==="videos") ?  15 : 5
    const maxFileSize = limt * 1024 * 1024; // 5 MB

    if (file) {
      if (file.size > maxFileSize) {
        alert("File size exceeds the  limit.");
      } else {
        HandleUploadMediaMemorial(event.target.files[0]);
      }
    }
  };

  const HandleUploadMediaMemorial = async (file) => {
    console.log("memorialId ", memorial);
    const formDImage = new FormData();
    formDImage.append("file", file);
    formDImage.append("memorial", memorial);

    let url = ApiEndPoints.uploadMediaMemorial + activeTab;
    try {
      const response = await fetch(url, {
        method: "POST",
        body: formDImage,
      });

      if (response.ok) {
        fetchMemorialMedia(activeTab);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

 const  handleChange = async(e) =>{
  setYtLink(e.target.value);


  let url = ApiEndPoints.uploadMediaMemorial + "yt";
    try {
      const response = await fetch(url, {
        method: "POST",
        body: {
          memorial,
          filePath :e.target.value, fileType:"videos"

        },
      });

      if (response.ok) {
        fetchMemorialMedia(activeTab);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  
 }

  return (
    <>
      <div className="my-4 bg-gray-100 border-b mx-auto w-full border-gray-200 ">
        <ul
          className="flex flex-wrap w-full -mb-px text-sm font-medium mx-auto text-center"
          id="default-tab"
          data-tabs-toggle="#default-tab-content"
          role="tablist"
        >
          <Tab
            id="image"
            label="Photo"
            onClick={handleTabClick}
            isActive={activeTab === "image"}
          />
          <Tab
            id="videos"
            label="Video"
            onClick={handleTabClick}
            isActive={activeTab === "videos"}
          />
          <Tab
            id="audio"
            label="Audio"
            onClick={handleTabClick}
            isActive={activeTab === "audio"}
          />
        </ul>
      </div>
      <div id="default-tab-content">
        <div
          className="flex flex-row "
          style={{ paddingLeft: "6rem", paddingRight: "6rem" }}
        >
          <div className="w-full">
            {activeTab === "image" && (
              <TabContent id="image" isActive={activeTab === "image"}>
                <div className="grid grid-cols-3 sm:grid-cols-4 gap-4">
                  {mediaData.map((row, x) => (
                    <div key={x} className="grid gap-4">
                      {row.map((col, y) => (
                        <div key={`${x}-${y}`}>
                          <img
                           onClick={() => openModal( ApiEndPoints.Image_URL + "/" + col.filePath)}
                            className="h-auto max-w-full rounded-lg"
                            src={
                              ApiEndPoints.Image_URL + "/" + col.filePath ||
                              "images/img_rectangle21.png"
                            }
                            alt=""
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </TabContent>
            )}
            {activeTab === "videos" && (
              <TabContent id="videos" isActive={activeTab === "videos"}>
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                  {mediaData.map((row, x) => (
                    <div key={x} className="grid gap-2">
                      {row.map((col, y) => (
                        <div key={`${x}-${y}`}>
                          <ReactPlayer
                            controls
                            width={250}
                            height={200}
                            url={ApiEndPoints.Image_URL + "/" + col.filePath}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </TabContent>
            )}
            {activeTab === "audio" && (
              <TabContent id="audio" isActive={activeTab === "audio"}>
                <div className="grid grid-cols-3 sm:grid-cols-4 gap-4">
                  {mediaData.map((row, x) => (
                    <div key={x} className="grid gap-4">
                      {row.map((col, y) => (
                        <div key={`${x}-${y}`}>
                          <audio
                            className="h-auto max-w-full rounded-lg"
                            controls
                          >
                            <source
                              src={ApiEndPoints.Image_URL + "/" + col.filePath}
                              type="audio/mpeg"
                            />
                            Your browser does not support the audio tag.
                          </audio>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </TabContent>
            )}
          </div>
        </div>
        <div className="shadow-md border w-full bg-gray-100 border-gray-200 p-4 rounded-md rounded-xl mb-10">
          <div className="mont-regular text-2xl font-bold pt-2 pb-4">
            Add {activeTab}
          </div>
          <div className="flex items-center justify-center w-full">
            <label
              for="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                onChange={onFileChange}
              />
            </label>
          </div>
        </div>
        {
          activeTab === "videos" &&  
          <div className="flex flex-col mb-4">
          <label
            htmlFor="name"
            className="inline-flex mb-2 text-sm text-gray-800"
          >
           Youtube Link
          </label>
          <input
            name="Firstname"
            className="
      w-full
      px-3
      py-2
      text-gray-800
      border
      rounded
      outline-none
      bg-gray-50
      focus:ring
      ring-indigo-300
    "
            onChange={handleChange}
            value={ytLink}
          />
        </div>
        }


      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
            border: 'none',
            background: 'none',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
      >
        <img
          src={selectedImage}
          alt="Full Size"
          style={{ maxHeight: '90vh', maxWidth: '90vw' }}
          onClick={closeModal}
        />
      </Modal>
    </>
  );
};

export default Gallery;
