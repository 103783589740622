import { Button, Img, Input, Line, PagerIndicator, Text } from "../../components";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
const Footer = ({ ...restProps }) => {
    const Naviagte = useNavigate()
  return (
    <div className="flex flex-col font-montserrat mt-[3px] md:px-5 sm:p-0 relative w-full">
    <footer className="flex font-montserrat items-center justify-center mt-[15px] sm:p-0 md:px-5 w-full">
        <div className="flex flex-col items-center justify-center w-full">
            <div className=" h-[381px]  sm:h-[639px]   lg:h-[381px]  relative w-full">
                <Img
                    className="h-[381px] sm:h-[639px] m-auto object-cover w-full"
                    src="/images/img_rectangle4_381x1592.png"
                    alt="rectangleFour"
                />
                <div className="absolute bg-black-900_77 flex flex-col  h-full inset-[0] items-center justify-center m-auto p-[77px] md:px-10 sm:px-5 w-full">
                    <div className="flex  sm:flex-wrap flex-row md:gap-10 gap-[19px] items-center justify-start mb-[15px] w-[81%] md:w-full">
                        <div className="h-60 sm:h-[265px] md:h-[193px] relative w-[41%] sm:w-full md:w-full">
                            <ul className="absolute bottom-[0] flex flex-col inset-x-[0] items-start justify-end mx-auto md:pr-10 sm:pr-5 pr-[110px] pt-[110px] w-full common-column-list">
                                <li>
                                    <a href="" className="text-base text-white-A700">
                                        <Text
                                            className="mont-regular"
                                            size="txtMontserratRegular16"
                                        >
                                            1802 Tuna Street, Westland, MI 48185
                                        </Text>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="mt-[13px] text-base text-white-A700">
                                        <Text
                                            size="txtMontserratRegular16 "
                                            className="py-2 mont-regular"
                                        >
                                            1-800-123-45-67
                                        </Text>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href=""
                                        className="mt-[15px] text-base text-white-A700 underline"
                                    >
                                        <Text
                                            className="mont-regular"
                                            size="txtMontserratRegular16"
                                        >
                                            milele@example.com
                                        </Text>
                                    </a>
                                </li>
                            </ul>
                            <Img
                                className="absolute h-32 sm:mt-[22px] lg:h-[6rem] left-[0] object-cover top-[0]   sm:w-[50%]  lg:w-[32%]"
                                src="/images/img_mm121.png"
                                alt="mm121"
                            />
                        </div>
                        <div className="flex flex-col font-playfairdisplay gap-[27px] items-start justify-start px-[15px] sm:w-5/12 w-[18%] md:w-full">
                            <Text
                                className="text-3xl sm:text-[26px] md:text-[28px] text-white-A700"
                                size="txtPlayfairDisplayBold30"
                            >
                                About{" "}
                            </Text>
                            <ul className="flex flex-col font-montserrat gap-[17px] items-start justify-start md:w-full common-column-list">
                                <li>
                                    <a href="" className="text-base text-white-A700">
                                        <Text
                                            className="mont-regular"
                                            size="txtMontserratRegular16"
                                        >
                                            Our Story
                                        </Text>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="text-base text-white-A700">
                                        <Text
                                            className="mont-regular"
                                            size="txtMontserratRegular16"
                                        >
                                            Gallery Regular
                                        </Text>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="text-base text-white-A700">
                                        <Text
                                            className="mont-regular"
                                            size="txtMontserratRegular16"
                                        >
                                            Services
                                        </Text>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="text-base text-white-A700">
                                        <Text
                                            className="mont-regular"
                                            size="txtMontserratRegular16"
                                        >
                                            FAQ
                                        </Text>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="flex flex-col font-playfairdisplay gap-[27px] items-start justify-start px-[15px] sm:w-5/12 w-[18%] md:w-full">
                            <Text
                                className="text-3xl  sm:text-[26px] md:text-[28px] text-white-A700"
                                size="txtPlayfairDisplayBold30"
                            >
                                Links
                            </Text>
                            <ul className="flex flex-col font-montserrat gap-[17px] items-start justify-start md:w-full common-column-list">
                                <li>
                                    <a href="" className="text-base text-white-A700">
                                        <Text
                                            className="mont-regular"
                                            size="txtMontserratRegular16"
                                        >
                                            Team
                                        </Text>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="text-base text-white-A700">
                                        <Text
                                            className="mont-regular"
                                            size="txtMontserratRegular16"
                                        >
                                            Pricing
                                        </Text>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="text-base text-white-A700">
                                        <Text
                                            className="mont-regular"
                                            size="txtMontserratRegular16"
                                        >
                                            Blog
                                        </Text>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="text-base text-white-A700">
                                        <Text
                                            className="mont-regular"
                                            size="txtMontserratRegular16"
                                        >
                                            Contacts
                                        </Text>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="flex flex-col font-playfairdisplay gap-6 items-start justify-start px-[15px] sm:hidden w-[18%] md:w-full">
                            <Text
                                className="text-3xl sm:text-[26px] md:text-[28px] text-white-A700"
                                size="txtPlayfairDisplayBold30"
                            >
                                Categories
                            </Text>
                            <ul className="flex flex-col font-montserrat gap-4 items-start justify-start md:w-full common-column-list">
                                <li>
                                    <a href="" className="text-base  text-white-A700">
                                        <Text
                                            size="txtMontserratRegular16"
                                            className="mont-regular"
                                        >
                                            Funeral
                                        </Text>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="text-base  text-white-A700">
                                        <Text
                                            size="txtMontserratRegular16"
                                            className="mont-regular"
                                        >
                                            Burrial
                                        </Text>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="text-base  text-white-A700">
                                        <Text
                                            size="txtMontserratRegular16"
                                            className="mont-regular"
                                        >
                                            Eco Funeral
                                        </Text>
                                    </a>
                                </li>
                                <li>
                                    <a href="" className="text-base  text-white-A700">
                                        <Text
                                            size="txtMontserratRegular16"
                                            className="mont-regular"
                                        >
                                            Cremations
                                        </Text>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:h-[73px] h-[60px] relative w-full">
                <Img
                    className="absolute h-[73px] inset-[0] justify-center m-auto object-cover w-full"
                    src="/images/img_rectangle3.png"
                    alt="rectangleThree"
                />
                <div className="absolute flex flex-col h-full inset-[0] items-center justify-center m-auto p-[25px] sm:px-5">
                    <Text
                        className="mt-0.5 text-base text-center text-white-A700"
                        size="txtMontserratRegular16"
                    >
                        © 2024 Сopyright
                    </Text>
                </div>
            </div>
        </div>
    </footer>
</div>
  );
};
export { Footer };
