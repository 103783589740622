import { useEffect, useState } from "react";
import { Button, Img, Text } from "../../components";
import { Footer } from '../../components/Footer'
import { Header } from "../../components/Header";
import { Obituaries } from "../../components/Obituaries";
import { useNavigate, useParams } from 'react-router-dom';
import ApiEndPoints from "../../ApiEndpoints";
import AboutSection from "../../components/aboutSection";
import Modal from 'react-modal';
import { changeDateFormate } from "../../utils";
import TributeAboutSection from "../../components/TributeAboutSection ";
import SocialShareButton from "../../components/SocialShareButton";
const Page = () => {
    const Naviagte = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const { url } = useParams(); // `url` corresponds to the parameter name in the route path

  const [ memorialDetails , setMemorialDetails ]  =  useState({
    id:"",
    user_id:"",
    Firstname: "",
    MiddleName: "",
    LastName: "",
    Gender: "",
    ProfileImgUrl: "",
    DateOfBirth:"",
    DateOfDeath: "",
    Relationship: "",
    MemorialDesignation: "",
    MemorialAddress: "",
    PrivacyOptions: "",
    Plan: "",
    CreatedBy:"",
    CreatedOn: "",
    Title: ""
  },);

  const fetchMemorialDetails = async()=>{
    try {
      const response = await fetch(ApiEndPoints.getObituryAddress +'/'+url);
      console.log("data url " ,url)
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("memorialId" ,data.id);
        console.log("data Id " ,data.id)
        setMemorialDetails(data)
      } else {
        const errorData = await response.json();
        console.error("Error getting memorial:", errorData.error);
      }
    } catch (error) {
      console.error("Network error:", error);
    }




  }

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  console.log(url , " - URl")
  useEffect(()=>{
    fetchMemorialDetails();
  },[])

    return (
        <>
            <Header />
            <div className="w-[90%]  m-auto "
                style={{ borderRadius: '20px' }} >
                <div className="my-7">
                    <Text
                        className="sm:text-[34px] open-sans  text-[44px] text-blue_gray-900 text-center tracking-[-0.20px]"
                        size="txtOpenSansSemiBold44"
                    >
                        Death and Funeral Announcements:
                    </Text>
                </div>
                <div className="bg-white-A700 mb-10 w-[40%] shadow-bs1 p-2  mx-auto rounded-[15px] shadow-bs1">
                    <Img
                        className=" w-full object-cover "
                        src={ApiEndPoints.Image_URL + "/" + memorialDetails.ProfileImgUrl}
                        alt="rectangleTwenty"
                        onClick={() => openModal(ApiEndPoints.Image_URL + "/" + memorialDetails.ProfileImgUrl)}
                    />
                </div>
                <div className="bg-white-A700 flex flex-col gap-12 justify-center p-10 sm:px-5 rounded-[20px] shadow-bs1 w-full">
                    <div className="flex flex-col gap-[9px] items-center justify-start">
                        <Text
                            className="sm:text-[34px] open-sans  text-4xl text-blue_gray-900 text-center tracking-[-0.20px]"
                            size="txtOpenSansSemiBold44"
                        >
                           {memorialDetails.Firstname}   {memorialDetails.MiddleName}  {memorialDetails.LastName}
                        </Text>
                    </div>
                    <div className="flex justify-between ">
                        <Text
                            className="sm:text-[34px] open-sans  text-4xl text-blue_gray-900 text-center tracking-[-0.20px]"
                            size="txtOpenSansSemiBold44"
                        >
                            SUNRISE:   { changeDateFormate(memorialDetails.DateOfBirth) }
                        </Text>
                        <Text
                            className="sm:text-[34px] open-sans  text-4xl text-blue_gray-900 text-center tracking-[-0.20px]"
                            size="txtOpenSansSemiBold44"
                        >
                            SUNSET { changeDateFormate(memorialDetails.DateOfDeath) }
                        </Text>
                    </div>
                </div>

           
                <div className="flex flex-col bg-white-A700 my-8 p-10 rounded-[20px] shadow-bs1">
                <TributeAboutSection />
                </div>
                <div className="   flex  flex justify-between  my-5  md:w-full">

                    <Button
                        className="mont-regular capitalize cursor-pointer font-medium font-poppins leading-[normal] min-w-[351px] text-center text-lg"
                        shape="round"
                        color="red_A700"
                        size="md"
                        variant="fill"
                        onClick={() => Naviagte("/allmemorial")}
                    >
                        View Memorial Page
                    </Button>

                    <Button
                        className="mont-regular capitalize cursor-pointer font-medium font-poppins leading-[normal] min-w-[351px] text-center text-lg"
                        shape="round"
                        color="light_blue_A400"
                        size="md"
                        variant="fill"
                        onClick={() => Naviagte("/creatememorial")}
                    >
                        Create them a Memorial Page .
                    </Button>
                    <SocialShareButton  />
                   


                </div>
            </div>
            <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
            border: 'none',
            background: 'none',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
      >
        <img
          src={selectedImage}
          alt="Full Size"
          style={{ maxHeight: '90vh', maxWidth: '90vw' }}
          onClick={closeModal}
        />
      </Modal>
            <Footer />
        </>
    );
};

export default Page;
