import React, { useEffect, useState } from 'react'
import ApiEndPoints from '../ApiEndpoints'
import { useNavigate , useLocation } from 'react-router-dom';
function UserObitiury() {
    const [data , setData] = useState([]);
    const Naviagte = useNavigate()
    const location = useLocation();
   
   
    const fetchData = async () => {
      try {
        const userId =  localStorage.getItem("UserID"); 
        const response = await fetch(`${ApiEndPoints.getObiturayId}/${userId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const datas = await response.json();
        setData(datas);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
   
    useEffect(() => {
      fetchData();
    }, []);
   
    // useEffect to log data changes
    useEffect(() => {
      console.log('Data updated:', data);
    }, [data]);
   
    console.log(data)
   
   
    const handleUpdateClick = (item) => {
     // Navigate to the user information page with the selected item's ID
     Naviagte(`/user_Information`, { state: { selectedItem: item } });};


   
  const handleDelete = async (id) => {
    try {
        const response = await fetch(`${ApiEndPoints.deleteObituary}/${id}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        // After successful deletion, refetch data to update the UI
        fetchData();
    } catch (error) {
        console.error('Error deleting contact:', error);
    }
};


  return (
    <div>
        My Memorials
     

<div className="mt-8">
      

        <div className="text-gray-600">
          <div className="container flex flex-col flex-wrap px-5 py-4 mx-auto">
            <div className="flex flex-col w-full text-center">
              <div className=" bg-white sm:py-8 lg:py-12">
                <div className="px-4 mx-auto max-w-screen-2xl bg-white-A700 py-10 rounded-[20px] formWidth">
                  <div className="flex justify-around border-b border-solid text-[20px]">
                    <div className="pb-5 border-b-2 border-indigo-500"> 
                      <h2>MY Obiturey</h2>
                    </div>
                    <div>
                     <h2 style={{cursor:'pointer'}} 
                     
                      >ACCOUNT INFORMATION</h2>
                    </div>
                  </div>
                  <div className="flex sm:flex-col justify-center my-8">
                  <div>


<div class="relative overflow-x-auto">
<table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
<thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
    <tr>
        <th scope="col" class="px-6 py-3">
        First Name
        </th>
        <th scope="col" className="px-6 py-3">
          Middle Name
        </th>
        <th scope="col" class="px-6 py-3">
       Last Name
        </th>
        <th scope="col" class="px-6 py-3">
        Memorial Address
        </th>
        <th scope="col" class="px-6 py-3">
        Plan
        </th>
        <th scope="col" class="px-6 py-3">
        Action
        </th>
    </tr>
</thead>
<tbody>
{data.map((item, index) => (
        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <td className="px-6 py-4">{item.Firstname}</td>
          <td className="px-6 py-4">{item.MiddleName
}</td>
          <td className="px-6 py-4">{item.LastName}</td>
          <td className="px-6 py-4">{item.MemorialAddress}</td>
          <td className="px-6 py-4">{item.Plan}</td>
          <td className="px-6 py-4"><button                            onClick={() => handleUpdateClick(item)}
  type="button" class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900" > Update </button> <button   onClick={(e)=>handleDelete(item.id)}  type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"  > Delete </button></td>
          </tr>
          ))
}
</tbody>
</table>
</div>

</div> 
                    
                  </div>

                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        
    </div>
  )
}

export default UserObitiury