import React, { useState } from "react";
import ApiEndPoints from "../../ApiEndpoints";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Page = () => {
  const [formData, setFormData] = useState({
    typeOfInjury: "",
    name: "",
    email: "",
    memorialName: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(ApiEndPoints.creteContact, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Contact created successfully:", data);
        toast.success("Thank you for contacting us");
        setFormData({
          typeOfInjury: "",
          name: "",
          email: "",
          memorialName: "",
          message: "",
        });
      } else {
        const errorData = await response.json();
        console.error("Error creating contact:", errorData.error);
        toast.error("Error creating contact. Please try again later.");
      }
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error. Please try again later.");
    }
  };

  return (
    <>
      <ToastContainer />
      <Header />
      <div className="pt-6">
        <h1 style={{ fontSize: "30px", textAlign: "center", paddingBottom: "20px" }}>
          Contact Customer Support
        </h1>
        <div className="w-[90%] bg-white-A700 m-auto py-10" style={{ borderRadius: "20px" }}>
          <div className="w-[85%] m-auto pt-10">
            <div className="shadow-md border border-gray-200 p-4 rounded-md rounded-xl mb-10">
              <div className="p-5 mont-regular">
                <div className="flex sm:flex-col justify-between pb-5">
                  <div className="w-[75%] sm:w-full">
                    <p className="bg-pink-200 rounded-md p-4">
                      Do you have any questions or ideas to improve this website? Please <br />
                      email us at milelemoyoni@gmail.com or use the contact form below, and a customer service
                      representative will contact you shortly.
                    </p>
                    <form onSubmit={handleFormSubmit}>
                      {/* <label className="mt-5">Type of injury</label>
                      <select
                        className="w-full my-3"
                        name="typeOfInjury"
                        value={formData.typeOfInjury}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Injury 1">Injury 1</option>
                        <option value="Injury 2">Injury 2</option>
                        <option value="Injury 3">Injury 3</option>
                      </select> */}
                      <label>Your name</label>
                      <input
                        className="w-full my-3"
                        placeholder="Your name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                      <label>Email address</label>
                      <input
                        className="w-full my-3"
                        placeholder="Email Address"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      <label>Memorial name (if applies)</label>
                      <input
                        className="w-full my-3"
                        placeholder="Memorial name"
                        name="memorialName"
                        value={formData.memorialName}
                        onChange={handleChange}
                      />
                      <label>Your message</label>
                      <textarea
                        className="w-full my-3"
                        placeholder="Please add your message here"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      />
                      <button
                        className="px-6 py-2 text-sm text-white bg-indigo-500 rounded-lg outline-none hover:bg-indigo-600 ring-indigo-300"
                        style={{ color: "white" }}
                        type="submit"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                  <div className="w-[23%] sm:w-full">
                    <div className="bg-gray-300 rounded-md p-4 mb-5">
                      <strong className="text-[20px]">Affiliate with us:</strong>{" "}
                      <p>Support your customers with valuable service while sharing the profits.</p>
                      <p className="text-yellow-100">Learn more about ForeverMissed affiliate program ...</p>
                    </div>
                    <div className="bg-gray-300 rounded-md p-4">
                      <strong className="text-[20px]">Mail Address </strong>{" "}
                      <p>milelemoyoni 817 Broadway 5th Floor, New York, N.Y. 10003</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Page;
