import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
import ApiEndPoints from "../ApiEndpoints";
import Modal from 'react-modal';

const responsive = [

  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: true,
      dots: true
    }
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      initialSlide: 2
    }
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }

];

const CarouselPage = ({ memorial , url }) => {
  const settings = {
    infinite: false,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: responsive,
  };

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(ApiEndPoints.getMemorial);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const datas = await response.json();
      setData(datas);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  return (
    <div>
      <Slider {...settings}>
        {memorial.map((item) => (
          <div key={item.id}>
            <div className="relative row"  >
              <img
                 onClick={() => openModal(ApiEndPoints.Image_URL + "/" + item.ProfileImgUrl)}
                className="h-[311px] w-[233px] md:h-auto md:w-auto object-cover rounded-[15px] cursor-pointer"
                src={ApiEndPoints.Image_URL + "/" + item.ProfileImgUrl}
                alt="User's Profile Picture"
              />
              <div onClick={() => navigate( url +item.MemorialAddress )} className="absolute w-[233px] bottom-0 left-0 right-0 bg-gray-500/75 text-slate-950 text-center py-2">
                <p className="text-lg font-bold">{`${item.Firstname} ${item.MiddleName} ${item.LastName}`}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
            border: 'none',
            background: 'none',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
      >
        <img
          src={selectedImage}
          alt="Full Size"
          style={{ maxHeight: '90vh', maxWidth: '90vw' }}
          onClick={closeModal}
        />
      </Modal>
    </div>
  );
};

export default CarouselPage;
