
import React,{useState , useEffect} from 'react'

import './AdminstyleNew.css'
import { FaSearchLocation } from "react-icons/fa";
import { RxDashboard } from "react-icons/rx";
import { SiGoogleforms } from "react-icons/si";
import { CiCircleList } from "react-icons/ci";
import { IoIosSettings } from "react-icons/io";
import { CiLogout } from "react-icons/ci";
import { GrUserAdmin } from "react-icons/gr";
import { Link  , useNavigate} from 'react-router-dom';
import Hamburger from 'hamburger-react'
import AdminSidebar from './AdminSidebar';
import AdminMobileSidebar from './AdminMobileSidebar';
import ApiEndPoints from '../../ApiEndpoints';
function Page() {

    const [Data, setData] = useState([]);
    const [Data1, setData1] = useState([]);
    const [Data2, setData2] = useState([]);
    const [Data3, setData3] = useState([]);


    const [usermsg , setUsersMsg] = useState([]);
    const [isOpen, setOpen] = useState(false)

    const navigate = useNavigate();

   
    const fetchData = async () => {
      try {
        const response = await fetch(ApiEndPoints.getMemorial);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const datas = await response.json();
        setData(datas);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


    const fetchData1 = async () => {
      try {
        const response = await fetch(ApiEndPoints.getObituary);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const datas = await response.json();
        setData1(datas);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchData2 = async () => {
      try {
        const response = await fetch(ApiEndPoints.getTestimonils);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const datas = await response.json();
        setData2(datas);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchData3 = async () => {
      try {
        const response = await fetch(ApiEndPoints.Allusers);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const datas = await response.json();
        setData3(datas);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    useEffect(()=>{
      fetchData()
      fetchData1()
      fetchData2()
      fetchData3()

    },[])





  const handleLogout=()=>{
    navigate('/');
    window.localStorage.clear();

  }


  return (
   <>
 

<body >
  <AdminSidebar/>
   
 
  <section class="home-section">
    <AdminMobileSidebar/>

    <div class="home-content">
      <div class="overview-boxes">
        <div class="box">
          <div class="right-side">
            <div class="box-topic"> Total Memoriails</div>
            <div class="number" style={{fontSize:'26px'}}>{Data.length}</div>
            <div class="indicator">
              <i class='bx bx-up-arrow-alt'></i>
              <span class="text">Up from today</span>
            </div>
          </div>
          <i class='bx bx-cart-alt cart'></i>
        </div>
        <div class="box">
          <div class="right-side">
            <div class="box-topic">Total Obituaris</div>
            <div class="number">{Data1 && Data1.length}</div>
            <div class="indicator">
              <i class='bx bx-up-arrow-alt'></i>
              <span class="text">Up from yesterday</span>
            </div>
          </div>
          <i class='bx bxs-cart-add cart two' ></i>
        </div>
        <div class="box">
          <div class="right-side">
            <div class="box-topic">Total Testimonials</div>
            <div class="number">{Data2 && Data2.length}</div>
            <div class="indicator">
              <i class='bx bx-up-arrow-alt'></i>
              <span class="text">Up from yesterday</span>
            </div>
          </div>
          <i class='bx bx-cart cart three' ></i>
        </div>
        <div class="box">
          <div class="right-side">
            <div class="box-topic">Total Users</div>
            <div class="number">{Data3.length}</div>
            <div class="indicator">
              <i class='bx bx-down-arrow-alt down'></i>
              <span class="text">Down From Today</span>
            </div>
          </div>
          <i class='bx bxs-cart-download cart four' ></i>
        </div>
      </div>

      <div class="sales-boxes">
      <div class="recent-sales box">
  <div class="title">Recent Memoriails</div>
  

<div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    Memorial Name
                </th>
                <th scope="col" class="px-6 py-3">
                    Gender
                </th>
                <th scope="col" class="px-6 py-3">
                    Relationship
                </th>
                <th scope="col" class="px-6 py-3">
                    Plan
                </th>
              
            </tr>
        </thead>
        <tbody>
                                        {Data.slice(0, 5).map((user) => (
                                            <tr
                                                key={user.id}
                                                class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                                            >
                                                <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    {user.Firstname} {user.MiddleName} {user.LastName}
                                                </td>
                                                <td class="px-6 py-4">{user.Gender}</td>
                                                <td class="px-6 py-4">{user.Relationship}</td>
                                                <td class="px-6 py-4">{user.Plan}</td>
                                                
                                            </tr>
                                        ))}
                                    </tbody>
    </table>
</div>

  <div class="button mt-2">
    <Link to="/adminmemorial">See All</Link>
  </div>
</div>

    
      </div>
    </div>
  </section>


</body>

   
   </>

  )
}

export default Page
