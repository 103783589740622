import React, { useState } from "react";
import { Button, Img, Text } from "..";

const Tributes = ({ data = [], handleSubmit }) => {
  const [createdBy, setCreatedBy] = useState("");
  const [reviewDescription, setReviewDescription] = useState("");
  const [selectedDiv, setSelectedDiv] = useState("candle");
  
  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handlePClick = (value) => {
    // Function to clear the selected div when <p> tag is clicked
    setSelectedDiv(value);
  };

  const handelAddTribute = () => {
    handleSubmit({ selectedDiv, reviewDescription, createdBy });
    setReviewDescription("");
    setCreatedBy("");
  };



  return (
    <div className="ml-2">
      <h2 style={{ fontSize: "30px" }} className="pt-5 pb-2 font-bold">
        Tributes
      </h2>
      <div className="w-full m-auto">
        {data.map((item, index) => (
          <div
            key={index}
            style={{ backgroundColor: "bisque" }}
            className="shadow-md border border-gray-200 p-4 rounded-md rounded-xl mb-10"
          >
            <div className=" flex mont-regular p-2">
              <div className="w-1/6 p-2">
                {item.traibuteIcon === "candle" && (
                  <Img
                    className="h-[76px] "
                    src="/images/TRIBUTE_CANDLE_USE.jpeg"
                    alt="personalizewint"
                  />
                )}
                {item.traibuteIcon === "flower" && (
                  <Img
                    className="h-[76px] "
                    src="/images/wreath_FLOWERS.jpeg"
                    alt="personalizewint"
                  />
                )}
                {item.traibuteIcon === "note" && (
                  <Img
                    className="h-[76px] "
                    src="/images/tribute_leave NOTE.jpeg"
                    alt="personalizewint"
                  />
                )}
              </div>
              <div className="w-5/6">
                <div className="text-right "> {formatDate(item.createdOn)}</div>
                <p className="pb-8">{item.reviewDescription}</p>
                <p className="text-right ">{item.createdBy}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <h2 style={{ fontSize: "30px" }} className="pb-4 ">
        Leave a Tribute
      </h2>
      <div className="flex items-center "   style={{ backgroundColor: "bisque" }}>
        <div
          className=" w-full border border-gray-200   rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
        >
          <div className="py-4 pr-[15px]  pl-[15px]">
            <div className="space-y-6 pt-[15px]">
              <div
                className="d-flex "
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div
                  className={`bg-white border border-gray-200  rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 ${
                    selectedDiv === "candle" ? "bg-blue-500" : "bg-yellow-200" // Conditional class based on the selected div
                  }`}
                  onClick={() => handlePClick("candle")}
                  style={{ border: "2px ", padding: "29px", cursor: "pointer" }}
                >
                  <Img
                    className="h-[76px] "
                    src="/images/TRIBUTE_CANDLE_USE.jpeg"
                    alt="personalizewint"
                  />
                  <div>
                    <p style={{ marginLeft: "-16px", marginTop: "9px" }}>
                      Light a Candle
                    </p>
                  </div>
                </div>
                <div
                  className={`bg-white border border-gray-200  rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 ${
                    selectedDiv === "flower" ? "bg-blue-500" : "bg-yellow-200" // Conditional class based on the selected div
                  }`}
                  onClick={() => handlePClick("flower")}
                  style={{ border: "2px ", padding: "29px", cursor: "pointer" }}
                >
                  <Img
                    className="h-[76px] "
                    src="/images/wreath_FLOWERS.jpeg"
                    alt="personalizewint"
                  />
                  <div>
                    <p style={{ marginLeft: "-8px", marginTop: "9px" }}>
                      Lay a Flower
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => handlePClick("note")}
                  className={`bg-white border border-gray-200  rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 ${
                    selectedDiv === "note" ? "bg-blue-500" : "bg-yellow-200" // Conditional class based on the selected div
                  }`}
                  style={{ border: "2px ", padding: "29px", cursor: "pointer" }}
                >
                  <Img
                    className="h-[76px] "
                    src="/images/tribute_leave NOTE.jpeg"
                    alt="personalizewint"
                  />
                  <div>
                    <p
                      style={{
                        marginTop: "9px",
                      }}
                    >
                      Live a Note
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="email"
                  id="name"
                  value={createdBy}
                  onChange={(e) => setCreatedBy(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  placeholder="name@company.com"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  {" "}
                  Enter tribute message
                </label>
                <textarea
                  type="text"
                  name="password"
                  id="password"
                  value={reviewDescription}
                  onChange={(e) => setReviewDescription(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  required
                  placeholder="Add your tribute text here"
                  style={{ height: "300px" }}
                />
              </div>
              <button
                onClick={handelAddTribute}
                className="py-3  buttoncusome w-1/6 mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tributes;
