import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Img, Text } from "../../components";
import ApiEndPoints from "../../ApiEndpoints";

const MemorialsRows = ({ ...restProps }) => {
  const ITEMS_PER_ROW = 5;
  const Navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pinnedDetails, setPinnedDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const fetchPinnedDetails = async () => {
    try {
      const response = await fetch(ApiEndPoints.getMemorial);

      if (response.ok) {
        const data = await response.json();
        console.log("get Pinned Memorials successfully:", data);
        setPinnedDetails(data);
      } else {
        const errorData = await response.json();
        console.error("Error fetching pinned memorials:", errorData.error);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  useEffect(() => {
    fetchPinnedDetails();
  }, []);

  // Function to split array into chunks
  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  // Filter and chunk the pinned details
  const filteredObituaries = pinnedDetails.filter(obituary => {
    const fullName = `${obituary.Firstname} ${obituary.LastName}`.toLowerCase();
    return fullName.includes(searchQuery.toLowerCase());
  });

  const chunkedPinnedDetails = chunkArray(filteredObituaries, ITEMS_PER_ROW);

  // Event handler to update the search query
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // Clear search query
  const clearSearch = () => {
    setSearchQuery('');
  };

  return (
    <>
      <form className="flex items-center mx-auto" onSubmit={clearSearch} style={{paddingTop:'2px', paddingBottom:'18px', maxWidth:'45rem'}}>   
        <label htmlFor="voice-search" className="sr-only">Search</label>
        <div className="relative w-full">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.15 5.6h.01m3.337 1.913h.01m-6.979 0h.01M5.541 11h.01M15 15h2.706a1.957 1.957 0 0 0 1.883-1.325A9 9 0 1 0 2.043 11.89 9.1 9.1 0 0 0 7.2 19.1a8.62 8.62 0 0 0 3.769.9A2.013 2.013 0 0 0 13 18v-.857A2.034 2.034 0 0 1 15 15Z"/>
            </svg>
          </div>
          <input type="text" id="voice-search" 
            value={searchQuery}
            onChange={handleSearch} 
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search by name..." required 
          />
          <button type="button" className="absolute inset-y-0 end-0 flex items-center pe-3"></button>
        </div>
      </form>
      <div className="flex flex-col items-center justify-start px-[15px] pb-[38px] mx-auto md:px-5 w-full">
        <div className="text-center w-full">
          <div className="bg-gray-300 text-center hover:cursor-pointer items-center w-full">
            <Text className="sm:text-[14px] md:text-[20px] text-[24px] text-center text-light_green-300" size="txtPlayfairDisplayBold44">
              <span className="text-red-A700 text-center font-playfairdisplay font-bold">
                PINNED Memorials
              </span>
            </Text>
          </div>
        </div>
        {chunkedPinnedDetails.map((group, index) => (
          <div key={index} className="flex md:flex-wrap md:gap-[68px] sm:flex-col flex-row sm:gap-10 items-center justify-between mt-[20px] w-full">
            {group.map((memorial, idx) => (
              <div key={idx} className="relative m-2" onClick={() => Navigate("/memorial/" + memorial.MemorialAddress)}>
                <Img
                  className="h-[311px] sm:w-full md:w-[40%] w-[200px] md:h-auto object-cover rounded-[15px]"
                  src={ApiEndPoints.Image_URL + "/" + memorial.ProfileImgUrl || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSya5dwz7sppH6NGJVip5KpNHbO6feSmMVJoQ&usqp=CA"}
                  alt={`Rectangle ${idx + 1}`}
                />
                <div className="absolute bottom-0 left-0 right-0 bg-gray-500/75 text-slate-950 text-center py-2">
                  <p className="text-lg font-bold">{memorial.Firstname} {memorial.LastName}</p>
                </div>
              </div>
            ))}
          </div>
        ))}
        <Button
          className="common-pointer capitalize cursor-pointer font-medium popinsfont leading-[normal] min-w-[208px] mt-6 sm:text-[21px] md:text-[23px] text-[25px] text-center"
          onClick={() => Navigate("/allmemorial")}
          shape="round"
          color="red_A700"
          size="sm"
          variant="fill"
        >
          VIEW ALL
        </Button>
      </div>
    </>
  );
};

export { MemorialsRows };
