import React from "react";
import PropTypes from "prop-types";

const shapes = { round: "rounded-[24px]", circle: "rounded-[50%]" };
const variants = {
  fill: {
    red_A700: "bg-red-A700 text-white-A700",
    light_blue_A400: "bg-light_blue-A400 shadow-bs text-white-A700",
    red_A700_b2: "bg-red-A700_b2",
    white_A700: "bg-white-A700",
  },
  underline: { light_blue_A400: "text-light_blue-A400" },
};
const sizes = {
  xs: "py-[3px]",
  sm: "p-1",
  md: "p-2.5",
  lg: "p-[13px]",
  xl: "p-[18px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "",
  size = "",
  variant = "",
  color = "",
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ""} ${
        (size && sizes[size]) || ""
      } ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf(["round", "circle"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  variant: PropTypes.oneOf(["fill", "underline"]),
  color: PropTypes.oneOf([
    "red_A700",
    "light_blue_A400",
    "red_A700_b2",
    "white_A700",
  ]),
};

export { Button };
