import { Button, Img, Input, Line, PagerIndicator, Text } from "../../components";
// import { usePathname, useRouter } from "next/navigation";
import { useState } from "react";
import { useNavigate, useLocation , Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa6";
import { IoIosArrowUp } from "react-icons/io";

import { FaRegUserCircle } from "react-icons/fa";


const Header = ({ ...restProps }) => {
  const Naviagte = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const pathname = location.pathname;
  const userName = localStorage.getItem("UserFirstName");
  
  const verifyID =  localStorage.getItem("UserID");


  const handleNavigation = (path) => {
    Naviagte(path);
      setMenuOpen(false); // Close the menu after navigation
  };

  const isActiveLink = (path) => {
      return pathname === path ? 'light_blue-A400' : 'black-900';
  };
  // console.log("pathname" ,pathname)

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleProfileClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  const handleLogout=()=>{
    localStorage.removeItem("UserID")
    localStorage.removeItem("UserFirstName")

    localStorage.removeItem("IsLogin")
    Naviagte("/")
  }

  return (
    <>
      <div
        className={`ease-in-out duration-300 hidden sm:flex bg-white-A700_e0  fixed inset-0 bg-white-A700_e0 z-50 flex-col items-center justify-start p-[19px] w-full ${
          isMenuOpen ? "translate-x-0 " : "translate-x-full"
        } `}
      >
        <div className="flex flex-col items-center justify-start w-full">
          <div className="flex flex-col gap-2 items-start justify-between w-full">
            <Text
            className={`md:mt-0 mt-[5px] text-${isActiveLink("/")} text-lg hover:text-blue-400`}
            size="txtMontserratRomanMedium18Black900"
            onClick={() => handleNavigation("/")}
            style={{ cursor: "pointer" }}
            >
              <span style={{ cursor: "pointer" }}>Home</span>
            </Text>
            <Line className="bg-black-900 h-px w-full" />
            <Text
              className="md:mt-0 mt-[5px] text-black-900 text-lg hover:text-blue-500"
              size="txtMontserratRomanMedium18Black900"
              onClick={() => Naviagte("/allmemorial")}
            >
              <span style={{ cursor: "pointer" }}>Memorial Page</span>
            </Text>
            <Line className="bg-black-900 h-px w-full" />
            <Text
              className="md:mt-0 mt-[5px] text-black-900 text-lg hover:text-blue-500"
              size="txtMontserratRomanMedium18Black900"
              onClick={() => Naviagte("/allObituaries")}
            >
              <span style={{ cursor: "pointer" }}>Obituaries Page</span>
            </Text>
            <Line className="bg-black-900 h-px w-full" />
            <Text
              className="md:mt-0 mt-[3px] text-black-900 text-lg"
              size="txtMontserratRomanMedium18Black900"
              onClick={() => Naviagte("/plansFeatures")}
            >
              Plans & Features
            </Text>
            <Line className="bg-black-900 h-px w-full" />
            <Text
              className="common-pointer md:mt-0 mt-[3px] text-black-900 text-lg"
              size="txtMontserratRomanMedium18Black900"
              onClick={() => Naviagte("/about")}
            >
              About
            </Text>
            <Line className="bg-black-900 h-px w-full" />
            <Text
              className="md:mt-0 mt-[3px] text-black-900 text-lg"
              size="txtMontserratRomanMedium18Black900"
              onClick={() => Naviagte("/testimonials")}
            >
              Testimonials
            </Text>
            <Line className="bg-black-900 h-px w-full" />
            <Text
              className="md:mt-0 mt-1 text-black-900 text-lg"
              size="txtMontserratRomanMedium18Black900"
              onClick={() => Naviagte("/contact")}
            >
              Contact Us
            </Text>
            {  localStorage.getItem("UserID") &&
            <>
          
           <Line className="bg-black-900 h-px w-full" />
            <Text
              className="md:mt-0 mt-1 text-black-900 text-lg"
              size="txtMontserratRomanMedium18Black900"
              onClick={handleLogout}
            >
             Logout
            </Text>
            </>
}
          </div>
        </div>
      </div>
      <div className="bg-gradient1 flex sm:flex-col flex-row items-center justify-start p-3 w-full">
        {/* <Text
          className="hidden sm:flex text-[18px] text-white-A700"
          onClick={() => setMenuOpen(!isMenuOpen)}
        >
          Menu
        </Text> */}
        <div
          className="hidden w-full sm:flex p-1 justify-start "
          onClick={() => setMenuOpen(!isMenuOpen)}
        >
          <div className="w-5 h-5 mt-3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
            </svg>
          </div>
        </div>

        <Text
          className="italic sm:ml-[0] ml-[7px] sm:mt-0 mt-[5px] sm:text-4xl md:text-[38px] text-[40px] text-center text-white-A700"
          size="txtPlayfairDisplayExtraBoldItalic40"
          onClick={() => Naviagte("/")}
        >
          Milele Moyoni
        </Text>
        {/* <Input
          name="groupSeventyFive"
          placeholder="FIND A MEMORIAL"
          className="!placeholder:text-gray-500 !text-gray-500 font-medium font-montserrat leading-[normal] p-0 text-left text-xs w-full"
          wrapClassName="flex sm:flex-1 sm:mt-0 my-[9px] rounded-[19px] w-[21%] sm:w-full"
          prefix={
            <Img
              className="mt-auto mb-px h-4 mr-[7px]"
              src="images/img_rewind.svg"
              alt="rewind"
            />
          }
        ></Input> */}
      </div>

      <div className=" sm:hidden bg-white-A700_e0 flex flex-col items-center justify-start p-[19px] w-full" style={{position:'relative'}}>
        <div className="flex flex-col items-center justify-start w-[59%] md:w-full">
          <div className="flex md:flex-col flex-row md:gap-10 items-start justify-between w-full">
            <div className="flex flex-col items-center justify-start">
              <Text
                className={`md:mt-0 mt-[5px] text-black-900 text-${
                  pathname === "/" ? "light_blue-A400" : "black-900"
                } text-lg hover:text-blue-400`}
                size="txtMontserratRomanMedium18Black900"
                onClick={() => Naviagte("/")}
                style={{ pointer: "cursor" }}
              >
                <span style={{ cursor: "pointer" }}>Home</span>
              </Text>
              {pathname == "/" && (
                <Line className="bg-light_blue-A400 h-[3px] mt-[3px] w-[95%]" />
              )}
            </div>
            <Line className="bg-black-900 md:h-0.5 h-[27px] w-0.5 md:w-full" />
            <div className="flex flex-col items-center justify-start">
              <Text
                className={`md:mt-0 mt-[5px] text-${
                  pathname === "/creatememorial" ? "light_blue-A400" : "black-900"
                } text-lg hover:text-blue-400`}
                size="txtMontserratRomanMedium18Black900"
                onClick={() => Naviagte("/allmemorial")}
                style={{ pointer: "cursor" }}
              >
                <span style={{ cursor: "pointer" }}>Memorial Page</span>
              </Text>
              {pathname == "/creatememorial" && (
                <Line className="bg-light_blue-A400 h-[3px] mt-[3px] w-[95%]" />
              )}
            </div>
            
            <Line className="bg-black-900 md:h-0.5 h-[27px] w-0.5 md:w-full" />
            <div className="flex flex-col items-center justify-start">
              <Text
                className={`md:mt-0 mt-[5px] text-${
                  pathname === "/createobitury" ? "light_blue-A400" : "black-900"
                } text-lg hover:text-blue-400`}
                size="txtMontserratRomanMedium18Black900"
                onClick={() => Naviagte("/allObituaries")}
                style={{ pointer: "cursor" }}
              >
                <span style={{ cursor: "pointer" }}> Obituaries</span>
              </Text>
              {pathname == "/createobitury"   && (
                <Line className="bg-light_blue-A400 h-[3px] mt-[3px] w-[95%]" />
              )}
            </div>

            <Line className="bg-black-900 md:h-0.5 h-[27px] w-0.5 md:w-full" />
            <div className="flex flex-col items-center justify-start">
              <Text
                className={`md:mt-0 mt-[3px] text-${
                  pathname === "/plansFeatures"
                    ? "light_blue-A400"
                    : "black-900"
                } text-lg hover:text-blue-400`}
                size="txtMontserratRomanMedium18Black900"
                onClick={() => Naviagte("/plansFeatures")}
              >
                <span style={{ cursor: "pointer" }}>Plans & Features</span>
              </Text>
              {pathname == "/plansFeatures" && (
                <Line className="bg-light_blue-A400 h-[3px] mt-[3px] w-[95%]" />
              )}
            </div>
            <Line className="bg-black-900 md:h-0.5 h-[27px] w-0.5 md:w-full" />
            <div className="flex flex-col items-center justify-start">
              <Text
                className={`common-pointer md:mt-0 mt-[3px] text-${
                  pathname === "/about" ? "light_blue-A400" : "black-900"
                } text-lg hover:text-blue-400`}
                size="txtMontserratRomanMedium18Black900"
                onClick={() => Naviagte("/about")}
              >
                <span style={{ cursor: "pointer" }}> About </span>
              </Text>
              {pathname == "/about" && (
                <Line className="bg-light_blue-A400 h-[3px] mt-[3px] w-[95%]" />
              )}
            </div>
            <Line className="bg-black-900 md:h-0.5 h-[27px] w-0.5 md:w-full" />
            <div className="flex flex-col items-center justify-start">
              <Text
                className={`md:mt-0 mt-[3px] text-${
                  pathname === "/testimonials" ? "light_blue-A400" : "black-900"
                } text-lg hover:text-blue-400`}
                size="txtMontserratRomanMedium18Black900"
                onClick={() => Naviagte("/testimonials")}
              >
                <span style={{ cursor: "pointer" }}> Testimonials </span>
              </Text>
              {pathname == "/testimonials" && (
                <Line className="bg-light_blue-A400 h-[3px] mt-[3px] w-[95%]" />
              )}
            </div>
            <Line className="bg-black-900 md:h-0.5 h-[27px] w-0.5 md:w-full" />
            <div className="flex flex-col items-center justify-start">
              <Text
                className={`md:mt-0 mt-1 text-${
                  pathname === "/contact" ? "light_blue-A400" : "black-900"
                } text-lg hover:text-blue-400`}
                size="txtMontserratRomanMedium18Black900"
                onClick={() => Naviagte("/contact")}
              >
                <span style={{ cursor: "pointer" }}> Contact Us </span>
              </Text>
              {pathname == "/contact" && (
                <Line className="bg-light_blue-A400 h-[3px] mt-[3px] w-[95%]" />
              )}
            </div>{
              localStorage.getItem("UserID") &&
              <>
              <Line className="bg-black-900 md:h-0.5 h-[27px] w-0.5 md:w-full" />
            <div className="flex  items-center justify-start " onClick={handleProfileClick}>
            <div style={{marginLeft:'5px' , marginTop:'3px' , marginRight:'5px'}} className="hover:text-blue-400">
              <FaRegUserCircle/>
              </div>
              <div>
              <Text
                className={`md:mt-0 mt-1 
                } text-lg hover:text-blue-400`}
                size="txtMontserratRomanMedium18Black900"
               
              >
                <span style={{ cursor: "pointer" }}>
                  {userName ? userName : "Profile"}  </span>
              </Text>
              </div>
              {
                isDropdownOpen ?
                <div style={{marginLeft:'3px' , marginTop:'5px' , fontWeight:'500'}} className="hover:text-blue-400">
              <FaAngleDown/>
              </div>
              :
              <div style={{marginLeft:'3px' , marginTop:'5px'}} className="hover:text-blue-400">
                           <IoIosArrowUp/>

              </div>
              }
             
           

              
             
              <div id="dropdownInformation" style={{ position: 'absolute', top: "72px", backgroundColor: '#568da0', display: isDropdownOpen ? 'block' : 'none' }} className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
   
    <ul class="py-2 text-sm " aria-labelledby="dropdownInformationButton">
     {
      verifyID !== "001" ?
      <li>
        <Link to="/user_Account" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">My Account</Link>
      </li>:
      <li>
      <Link to="/adminhome" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">My Profile</Link>
    </li>
     }
      
    </ul>
    <div class="py-2" onClick={handleLogout}>
      <a href="#" class="block px-4 py-2 text-sm  hover:bg-gray-100 dark:hover:bg-gray-600  dark:hover:text-white">Log out</a>
    </div>
</div>

             
            </div>
              </>
            }
            
          </div>
        </div>
      </div>
    </>
  );
};
export { Header };
