import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './app/home/page';
import Login from './app/login/page' ;
import Signup from './app/signup/page'
import Allmemorial from './app/allMemoriails/page'
import About from './app/about/page'
import Memorial from './app/memorial/page'
import Memorialaddress from './app/MemorialAddress/page'
import Testimonials from './app/testimonials/page'
import Obituaries from './app/obituaries/page'
import PlanFeatures from './app/plansFeatures/page'
import Contact from './app/contact/page'
import PinnedMemorials from './app/pinnedMemorials/page'
import AllObituaries from './app/allObituaries/page'
 import AdminHome from './app/adminhome/page'
import Resources from './app/resources/page'
import Textmemory from './app/textmemory/page'
import User_Account from './app/user_Account/page';
import User_Information from './app/user_Information/page'
import View from './app/view/page'
import ObituarieView from './app/obituarieView/page'
import AdminMemorial from './app/adminhome/AdminMemorial';
import Adminobituiry from './app/adminhome/Adminobituiry';
import AdminTestimonials from './app/adminhome/AdminTestimonials';
import AdminNotification from './app/adminhome/AdminNotification';
import MemoriailsList from './app/MemoriailsList/page';
import AdminUsers from './app/adminhome/AdminUsers';
import CreateObitury from './app/CreateObitury/Page'
import UserMemoreilAdmin from './app/adminhome/UserMemoreilAdmin';
import UserObituaryAdmin from './app/adminhome/UserObiturayAdmin';
import AllGridObituaries from './app/allGridObituaries/page'
// import AdminHome from './app/adminhome/page'
  function PublicRoutes() {
    return (
      <>
        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/login' element={<Login/>} />
            <Route path='/signup' element={<Signup/>} />
            <Route path='/about'  element={<About/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/creatememorial' element={<Memorial/>} />
            <Route path='/memorial/:url' element={<Memorialaddress/>} />
            <Route path='/testimonials'  element={<Testimonials/>} /> 
            <Route path='/allmemorial' element={<Allmemorial/>} />
            <Route path='/obituaries' element={<Obituaries/>} />
            <Route path='/plansFeatures' element={<PlanFeatures/>} />
            <Route path='/pinnedMemorials' element={<PinnedMemorials/>} />
            <Route path='/allObituaries' element={<AllGridObituaries/>} />
            <Route path='/alllistObituaries' element={<AllObituaries/>} />
            <Route path='/resources' element={<Resources/>} />
            <Route path='/textmemory' element={<Textmemory/>} />
            <Route path='/list' element={<MemoriailsList/>} />
            <Route path='/user_Account' element={<User_Account/>} />
            <Route path='/user_Information' element={<User_Information/>} />
            <Route path='/view' element={<View/>} />
            <Route path='/adminhome' element={<AdminHome/>} />
            <Route path='/obituarieView' element={<ObituarieView/>} />
            <Route path='/Obituaries/:url' element={<ObituarieView/>} />
            <Route path='/adminmemorial' element={<AdminMemorial/>} />
            <Route path='/adminmobituiry' element={<Adminobituiry/>} />
            <Route path='/admintestimonials' element={<AdminTestimonials/>} />
            <Route path='/adminNotification' element={<AdminNotification/>} />
            <Route path='/adminUsers' element={<AdminUsers/>} />
            <Route path='/createObituaries' element={<CreateObitury/>} />
            <Route path='/AdminUserMemorials' element={<UserMemoreilAdmin/>} />
            <Route path='/AdminUserObituray' element={<UserObituaryAdmin/>} />
          </Routes>
        </Router>
      </>
    )
  }

  export default PublicRoutes