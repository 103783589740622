   const APPConfig = {
     API_URL: "https://milelemoyoni.com/api" , 
  //  API_URL:"http://localhost:4000/api"
   };
 
   const ApiEndPoints = {
     API_URL : 'https://milelemoyoni.com/api',
     Image_URL : 'https://milelemoyoni.com',
     login: `${APPConfig.API_URL}/users/login`,
     register: `${APPConfig.API_URL}/users/signup`,
     createMemorial:`${APPConfig.API_URL}/memorial/creatememorial`,
     updateProfileImage : `${APPConfig.API_URL}/memorial/upload/profile`,
     getMemorial:`${APPConfig.API_URL}/memorial/all`,
     getMemorialByAddress:`${APPConfig.API_URL}/memorial/get`,
     deleteMemorial:`${APPConfig.API_URL}/memorial/deleteMemorial`,
     getTestimonils:`${APPConfig.API_URL}/testimonial/all`,
     addTestimonial:`${APPConfig.API_URL}/testimonial/createTestimonial`,
     deleteTestimonial:`${APPConfig.API_URL}/testimonial/deleteestimonial`,
     addObituary: `${APPConfig.API_URL}/obituary/createObituary`,
     getObituary:`${APPConfig.API_URL}/obituary/all`,
     deleteObituary:`${APPConfig.API_URL}/obituary/deleteObituary`,
     creteContact:`${APPConfig.API_URL}/contact/createContact`,
     getContact:`${APPConfig.API_URL}/contact/getAllContacts`,
     deleteContact:`${APPConfig.API_URL}/contact/deletecontact` ,
     Addtribute:`${APPConfig.API_URL}/traibute/createTraibute` ,
     getAlltribute:`${APPConfig.API_URL}/traibute/all` ,
     getAllObituaryTraibuteById: (dataId) => `${APPConfig.API_URL}/traibute/obituary/${dataId}` ,
     getAllMemorialTraibuteById: (dataId) => `${APPConfig.API_URL}/traibute/memorial/${dataId}` ,
     deletetribute:`${APPConfig.API_URL}/traibute/deleteesTraibute` ,
     Allusers:`${APPConfig.API_URL}/users/allusers` ,
     DeleteUsers:`${APPConfig.API_URL}/users/deleteusers`  ,
     getMemorialbyId:`${APPConfig.API_URL}/memorial/getMemorialsByUserId` ,
     getMemorialbyMeoreilId:`${APPConfig.API_URL}/memorial/getMemorialsByMemoreilId` ,
     getObiturayId:`${APPConfig.API_URL}/obituary/getObituaryByUserId` ,
     UpdateMemorial:`${APPConfig.API_URL}/memorial/updateMemorial` ,
     getObituaryById: `${APPConfig.API_URL}/obituary/getObituaryById` ,
     updateObituryId:`${APPConfig.API_URL}/obituary/updateObituary`,
     updateTestimonils:`${APPConfig.API_URL}/testimonial/updatetimonial` ,
     updateUserProfile:` ${APPConfig.API_URL}/users/updateUser` ,

     getAllMediaMemorial:` ${APPConfig.API_URL}/memorial/gallery` ,
     uploadMediaMemorial:`${APPConfig.API_URL}/memorial/upload/`,
     getObituryAddress:`${APPConfig.API_URL}/obituary/get`,
     updateObituaryProfileImage : `${APPConfig.API_URL}/obituary/upload/profile`,
   }
   export default ApiEndPoints