'use client'
// import React from "react";
import { Button, Img, Input, Line, Text } from "../../components";
import { useNavigate } from 'react-router-dom';
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
export default function Page() {
    const Naviagte = useNavigate()

  return (
    <>
      <div className="bg-white-A700 flex flex-col items-center justify-start mx-auto w-full">
        <div className=" md:px-5 relative w-full">
          <div className=" w-full">
            <Img
              className="h-[532px] m-auto object-cover w-full"
              src="images/img_bgcremationjpg.png"
              alt="bgcremationjpg"
            />
            <div className="absolute bg-gradient  flex flex-col h-full inset-[0] justify-center m-auto pb-[132px] w-full">
              <div className="mt-[2.45rem]" >
                <Header />
              </div>

              <Text
                className="ml-40 md:ml-[0] mt-[183px] md:text-4xl text-5xl text-gray-50"
                size="txtPlayfairDisplayRomanMedium60"
              >
                About Milele Moyoni Online Memorial Platform
              </Text>
              <Text
                className="md:ml-[0] ml-[166px] mt-3 text-3xl sm:text-[26px] md:text-[28px] text-black-900"
                size="txtPlayfairDisplayRomanMedium30"
              >
                <span className="text-white-A700 font-playfairdisplay text-left font-medium">
                  Home
                </span>
                <span className="text-black-900 font-playfairdisplay text-left font-medium">
                  {" "}
                </span>
                <span className="text-white-A700 font-playfairdisplay text-left font-medium">
                  /
                </span>
                <span className="text-black-900 font-playfairdisplay text-left font-medium">
                  {" "}
                </span>
                <span className="text-light_blue-A400 font-playfairdisplay text-left font-medium">
                  About Founder
                </span>
              </Text>
            </div>
          </div>
        </div>
        <div  >
          {/* <div className=" flex flex-row items-center justify-evenly p-[30px]  w-full">

            <div className="flex flex-col content-evenly" >


              <Text
                className="text-[29px] my-2 text-center"

              >
                Milele Moyoni About
              </Text>
              <Button
                className=" my-2 mont-regular capitalize cursor-pointer font-medium font-poppins leading-[normal] min-w-[351px] text-center text-lg"
                shape="round"
                color="red_A700"
                size="md"
                variant="fill"
                onClick={() => Naviagte("/memorial")}
              >
                Create an Online Memorial Page
              </Button>
              <Button
                className=" my-2  mont-regular capitalize cursor-pointer font-medium font-poppins leading-[normal] min-w-[351px] text-center text-lg"
                shape="round"
                color="light_blue_A400"
                size="md"
                variant="fill"
                onClick={() => Naviagte("/memorial")}
              >
                Post an Obituary here
              </Button>
            </div>
            <div className="flex items-center mt-4"  >
              <Img
                className="sm:h-auto object-cover rounded-md w-[600px]"
                src="images/img_about.png"
                alt="personalizewint"
              />

            </div>
          </div> */}
          <div className="w-[90%] bg-white-A700  m-auto p-10 grid justify-items-stretch"
            style={{ borderRadius: '20px' }}
          >
            <div className="w-[90%] m-auto pt-5">
              <Text
                className="text-[29px] text-xl  text-center text-light_green-300 "
                size="txtMontserratRomanMedium24"
              >
                <span className="text-red-A700  text-left font-bold">
                  Losing a loved one is incredibly
                </span>
                <span className="text-blue_gray-700  text-left font-bold">
                  {" "}
                  painful, and very personal.
                </span>
              </Text>
              <Text
                className="leading-[32.00px] mt-4 text-xl text-gray-700  w-full"
                size="txtMontserratRomanMedium24"
              >
                At ForeverMissed, we provide a shared, easily-accessible virtual space
                where family members and friends can pay homage to a special life
                while helping each other heal by sharing their feelings, warm
                memories, and words of support.
              </Text>
              <Text
                className="leading-[32.00px] mt-5 text-xl text-gray-700  w-full"
                size="txtMontserratRomanMedium24"
              >
                <>
                  A Memorial Website takes minutes to set up, is super easy to use,
                  and never contains ads. We invite you to get started on your loved
                  one&#39;s unique tribute:
                </>
              </Text>
              <div className=" flex flex-col items-center justify-end mt-5 p-[20px] sm:px-5 w-full">
                <div className="flex flex-col items-center justify-start mt-1 md:px-5 w-[90%] md:w-full">
                  <Text
                    className="text-[29px]  text-center text-light_green-300"
                    size="txtPlayfairDisplayBold43"
                  >
                    <span className="text-red-A700 font-playfairdisplay font-bold">
                      Share your
                    </span>
                    <span className="text-blue_gray-700 font-playfairdisplay font-bold">
                      {" "}
                      memories
                    </span>
                  </Text>
                  <Text
                    className="leading-[32.00px] mt-[5px] px-20 text-2xl md:text-[22px] text-center text-gray-700 w-full"
                    size="txtMontserratRomanMedium24"
                  >
                    Share stories, tributes, photos, music, and videos celebrating the
                    life of a family member or friend who passed away.
                  </Text>
                  <Img
                    className="h-[1024px] sm:h-auto mt-7 object-cover w-full"
                    src="images/img_screenshot202.png"
                    alt="screenshot202"
                  />
                </div>
              </div>
              <div className=" flex flex-col items-center justify-end mt-5 p-[20px] sm:px-5 w-full">
                <div className="flex flex-col items-center justify-start mt-1 md:px-5 w-[90%] md:w-full">
                  <Text
                    className="text-[29px]  text-center text-light_green-300"
                    size="txtPlayfairDisplayBold43"
                  >
                    <span className="text-red-A700 font-playfairdisplay font-bold">
                      Personalize your
                    </span>
                    <span className="text-blue_gray-700 font-playfairdisplay font-bold">
                      {" "}
                      memories
                    </span>
                  </Text>
                  <Text
                    className="leading-[32.00px] mt-[5px] px-20 text-2xl md:text-[22px] text-center text-gray-700 w-full"
                    size="txtMontserratRomanMedium24"
                  >
                    Make it personal with a wide selection of themes, personalized
                    URL, the title phrase as well as a range of privacy and
                    notification options.
                  </Text>
                  {/* <Img
                  className="h-[833px] sm:h-auto mt-7 object-cover w-full"
                  src="images/img_personalizewinter.png"
                  alt="personalizewint"
                /> */}
                </div>
              </div>
              <div className=" flex flex-col items-center justify-end mt-5 p-[20px] sm:px-5 w-full">
                <div className="flex flex-col items-center justify-start mt-1 md:px-5 w-full">
                  <Text
                    className="text-[29px]  text-center text-light_green-300"
                    size="txtPlayfairDisplayBold43"
                  >
                    <span className="text-red-A700 font-playfairdisplay font-bold">
                      Invite and
                    </span>
                    <span className="text-blue_gray-700 font-playfairdisplay font-bold">
                      {" "}
                      collaborate
                    </span>
                  </Text>
                  <div className="flex items-center" >


                    <Text
                      className="leading-[32.00px] mr-6 text-2xl md:text-[22px] text-start text-gray-700 w-full"
                      size="txtMontserratRomanMedium24"
                    >
                      An online memorial is intended to be built collaboratively. You can invite family, friends, colleagues to participate in the creation of the website. This process provides a great way to heal and support one another in a difficult time.
                    </Text>
                    <Img
                      className="sm:h-auto mt-7 object-cover w-[600px]"
                      src="images/img_shaer.png"
                      alt="personalizewint"
                    />
                  </div>
                </div>
              </div>
              <div className=" flex flex-col items-center justify-end mt-5 p-[20px] sm:px-5 w-full">
                <div className="flex flex-col items-center justify-start mt-1 md:px-5 w-full">
                  <Text
                    className="text-[29px]  text-center text-light_green-300"
                    size="txtPlayfairDisplayBold43"
                  >
                    <span className="text-red-A700 font-playfairdisplay font-bold">
                      Control your
                    </span>
                    <span className="text-blue_gray-700 font-playfairdisplay font-bold">{" "}
                      privacy
                    </span>
                  </Text>
                  <div className="flex items-center mt-4"  >
                    <Text
                      className="leading-[32.00px] mr-6 text-2xl md:text-[22px] text-start text-gray-700 w-full"
                      size="txtMontserratRomanMedium24"
                    >
                      There is a choice of several privacy options for your website. Set it to be visible to all guests, or limit access only to the people you had invited. You can also make your memorial completely private until it is ready to be viewed by others.
                    </Text>
                    <Img
                      className="sm:h-auto object-cover w-[600px]"
                      src="images/img_privcy.png"
                      alt="personalizewint"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center w-full shadow-md border border-gray-200 p-4 rounded-md rounded-xl ">
                <Text
                  className="text-[29px]  text-center text-light_green-300"
                  size="txtPlayfairDisplayBold43"
                >
                  <span className="text-red-A700 font-playfairdisplay font-bold">
                    Our
                  </span>
                  <span className="text-blue_gray-700 font-playfairdisplay font-bold">
                    {" "}
                    Community
                  </span>
                </Text>
                <div className="flex sm:flex-col flex-row items-center p-4 justify-around w-full">
                  <div className="flex flex-col items-start justify-start  sm:w-full">
                    {/* <div className="bg-light_blue-A400 h-[3px] w-[25%]"></div> */}
                    <Text
                      className=" mont-regular sm:text-[21px] text-[30px] text-red-A700"

                    >
                      246,716
                    </Text>
                    <Text
                      className=" mont-regular text-blue_gray-700 text-2xl "

                    >
                      Memorials
                    </Text>
                  </div>
                  <div className="bg-light_blue-A400 h-24 w-0.5"> </div>
                  <div className="flex flex-col items-start justify-start sm:w-full">
                    <Text
                      className=" mont-regular sm:text-[21px] text-[30px] text-red-A700"
                    >
                      211,550,737
                    </Text>
                    <Text
                      className=" mont-regular text-blue_gray-700 text-2xl "
                    >
                      Tributes
                    </Text>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}