import  { useState } from "react";
import { Button, Img, Input, Line, Text } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/important.css";
import { useNavigate } from 'react-router-dom';
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import ApiEndPoints from "../../ApiEndpoints";
export default function Page() {
    const Naviagte = useNavigate()
  const [useremail, setUseremail] = useState();
  const [userPass, setUserPass] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log("name", name);
    console.log("value", value);

    if (name === "email") {
      setUseremail(value);
      console.log("useremail", useremail);
    } else if (name === "password") {
      setUserPass(value);
      console.log("userPass", userPass);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    console.log("email", useremail, userPass);
  
    if (useremail === "adminMilneyMoyoni@gmail.com" && userPass === "MilneyMoyoni@2024") {
      Naviagte("/adminhome");
      const userAdminID = "001";
      localStorage.setItem("UserID", userAdminID);
      localStorage.setItem("UserFirstName", "Admin");


      return;
    }
  
    try {
      const response = await fetch(ApiEndPoints.login, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: useremail,
          password: userPass,
        }),
      });
  
      const responseData = await response.json(); // Parse response body as JSON
  
      if (response.status === 200) {
       showResponse();
        console.log("User data:", responseData.userid); // Access user data
        localStorage.setItem("UserID", responseData.userid);
        localStorage.setItem("UserFirstName", responseData.firstName);

        localStorage.setItem("IsLogin", 'true');
        setUserPass("");
        setUseremail("");
      } else {
        toast("Please fill Correct fields");
        console.log("Login failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast("An error occurred. Please try again.");
    }
  };
  
  const showResponse=()=>{
    toast("Login Successful");
    console.log("Login Successful");
    setTimeout(()=>{
      Naviagte("/user_Account");

    },500)
  }

  return (
    <>
      <ToastContainer />
     
      <div className="bg-white-A700 flex flex-col h-screen font-montserrat items-center justify-start mx-auto w-full overflow-x-hidden">
      <div className="  relative w-full">
      <div className=" md:px-5 relative w-full">
          <div className=" w-full">
            <Img
              className="h-[532px] m-auto object-cover w-full"
              src="images/img_bgcremationjpg.png"
              alt="bgcremationjpg"
            />
            <div className="absolute bg-gradient  flex flex-col h-full inset-[0] justify-center m-auto pb-[132px] w-full">
             <div  >
             <Header />
             </div>
           
              <Text
                className="ml-40 md:ml-[0] mt-[183px] md:text-4xl text-5xl text-gray-50"
                size="txtPlayfairDisplayRomanMedium60"
              >
                Login
              </Text>
              <Text
                className="md:ml-[0] ml-[166px] mt-3 text-3xl sm:text-[26px] md:text-[28px] text-black-900"
                size="txtPlayfairDisplayRomanMedium30"
              >
                <span className="text-white-A700 font-playfairdisplay text-left font-medium">
                  Home
                </span>
                <span className="text-black-900 font-playfairdisplay text-left font-medium">
                  {" "}
                </span>
                <span className="text-white-A700 font-playfairdisplay text-left font-medium">
                  /
                </span>
                <span className="text-black-900 font-playfairdisplay text-left font-medium">
                  {" "}
                </span>
                <span className="text-light_blue-A400 font-playfairdisplay text-left font-medium">
                 Login
                </span>
              </Text>
            </div>
          </div>
        </div>
        
        </div>
        <div className="bg-white-A700 flex flex-col items-start justify-start max-w-[1168px] max-h-[800px] mt-[102px] mx-auto md:px-5 rounded-[20px] shadow-bs1 w-full">
          <div className="flex md:flex-row sm:flex-col flex-row md:gap-10  items-center justify-start w-full md:w-full">
            <div className="md:h-[862px] sm:h-[300px]  w-[48%] sm:w-full max-h-[1000px]">
              {" "}
              <Img
                className="  object-cover rounded-bl-[20px] rounded-tl-[20px] h-[508px] md:h-full  sm:h-full w-full sm:rounded-[20px]"
                src="images/img_rectangle88.png"
                alt="rectangleEightyEight"
              />
            </div>
            <div className="flex flex-col gap-[19px]  mx-6px m-auto w-[48%] sm:w-full lg:w-full ">
              <div className=" sm:w-full sm:pr-0 md:pr-[30px] ">
                <Text
                  className="text-4xl text-center sm:text-[32px] md:text-[34px] text-blue_gray-700"
                  size="txtPlayfairDisplayBold36"
                >
                  Login
                </Text>
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col items-start justify-start w-full">
                    <Text
                      className="text-blue_gray-700_01 mb-[10px] popinsfont text-xl"
                      size="txtPoppinsRegular20"
                    >
                      Email
                    </Text>
                    <input
                      placeholder="Enter your email"
                      className="popinsfont px-[25px] text-xl h-[47px] border-solid border-2 rounded-[10px] border-gray-600 leading-[normal] p-0 placeholder:text-gray-500_01 text-left text-xl w-full"
                      wrapClassName="border border-gray-600 border-solid mt-1.5 w-full"
                      type="text"
                      name="email"
                      onChange={handleChange}
                      shape="round"
                      size="sm"
                    />
                    <Text
                      className="mt-[13px] popinsfont mb-[10px] text-blue_gray-700_01 text-xl"
                      size="txtPoppinsRegular20"
                    >
                      Password
                    </Text>
                    <input
                      placeholder="Enter your Password"
                      className="popinsfont px-[25px] text-xl h-[47px] border-solid border-2 rounded-[10px] border-gray-600 leading-[normal] p-0 placeholder:text-gray-500_01 text-left text-xl w-full"
                      wrapClassName="border border-gray-600 border-solid mt-1.5 w-full"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      shape="round"
                      size="md"
                    />
                    <button
                      className="common-pointer text-xl rounded-[10px] mont-regular bg-light_blue-A400 h-[47px] cursor-pointer flex items-center justify-center w-full sm:min-w-full mt-[14px] "
                      color="light_blue_A400"
                      size="xl"
                      variant="fill"
                      type="submit"
                    >
                      Login
                    </button>
                    <div className="flex sm:flex-col flex-row font-montserrat sm:gap-5 items-center justify-center w-full   mt-4  md:w-full">
                      {/* <Line className="bg-black-900 h-px sm:mt-0 my-3.5 w-[11%]" /> */}
                      {/* <Text
                        className="italic ml-2.5 mont-regular sm:ml-[0] text-2xl md:text-[22px] text-gray-700 sm:text-xl"
                        size="txtMontserratItalicMedium24"
                      >
                        With Your Social Profile
                      </Text> */}
                      {/* <Line className="bg-black-900 h-px ml-2 sm:ml-[0] sm:mt-0 my-3.5 w-[11%]" /> */}
                    </div>
                    {/* <div className="bg-blue-A400 flex flex-col font-poppins mx-auto items-center justify-end mt-[25px] p-3.5 rounded-[10px] shadow-bs w-full">
                      <div className="flex flex-col items-center justify-center mt-1 w-[70%] ">
                        <div className="flex flex-row gap-[18px] items-start justify-start w-full">
                          <Img
                            className="h-[30px] md:h-auto object-cover w-[30px]"
                            src="images/img_facebook11.png"
                            alt="facebookEleven"
                          />
                          <Text
                            className="text-[22px] mont-regular sm:text-lg text-white-A700 md:text-xl"
                            size="txtPoppinsMedium22"
                          >
                            Sign in with Facebook
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white-A700 flex flex-col font-poppins items-center justify-end mt-3.5 p-3.5 rounded-[10px] shadow-bs4 w-full">
                      <div className="flex flex-row gap-[18px] items-start  justify-center mt-1 w-[70%] ">
                        <Img
                          className="h-[30px]  md:h-auto object-cover w-[30px]"
                          src="images/img_googlelogopng.png"
                          alt="googlelogopng"
                        />
                        <div className="flex flex-col items-center justify-start w-[84%]">
                          <div className="flex flex-col items-center justify-start w-full">
                            <Text
                              className="text-[22px] mont-regular text-black-900 sm:text-lg md:text-xl"
                              size="txtPoppinsMedium22Black900"
                            >
                              Sign in with Facebook
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <Button
                      className="common-pointer text-xl rounded-[10px] mont-regular bg-red-600 h-[47px] cursor-pointer flex items-center justify-center w-full sm:min-w-full mt-[14px] "
                      onClick={() => Naviagte("/signup")}
                    >
                      Reset your Password
                    </Button>
                    <Button
                      className="common-pointer text-xl rounded-[10px] mont-regular bg-light_blue-A400 h-[47px] cursor-pointer flex items-center justify-center w-full sm:min-w-full mt-[14px] mb-[8px] "
                      onClick={() => Naviagte("/signup")}
                    >
                      Sign Up
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
       <Footer/>
      </div>
    
    </>
  );
}