import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { useState  , useEffect} from "react";
import './page.css'
import { ToastContainer , toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Button , Checkbox, Label, TextInput , Img , Text } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ApiEndPoints from "../../ApiEndpoints";
import { useLocation } from 'react-router-dom';



const Page = () => {
    const Naviagte = useNavigate()
    const { id } = useParams();
    // const location = useLocation();
    // const selectedItem = location.state.selectedItem;
    // console.log(selectedItem);

    const [data , setData] = useState([]);
 
   
    // const fetchData = async () => {
    //   try {
    //     const response = await fetch(`${ApiEndPoints.getMemorialbyMeoreilId}/${id}`);
    //     if (!response.ok) {
    //       throw new Error('Network response was not ok');
    //     }
    //     const datas = await response.json();
    //     console.log(datas)
    //     setData(datas);
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };
   
    // useEffect(() => {
    //   fetchData();
    // }, []);
   

    
  const [selectedImage, setSelectedImage] = useState(null);
  const [step , setStep] = useState(1);
  const [isMemorialFormSelected, setIsMemorialFormSelected] = useState(null);
  const [custumClass, setCustumClass] = useState("hidden");
  const [custumClass1, setCustumClass1] = useState("hidden");
  const [formData, setFormData] = useState({
    Firstname: "",
    MiddleName: "",
    LastName: "",
    Gender: "",
    Relationship: "",
    MemorialDesignation: "",
    MemorialAddress: "",
    PrivacyOptions: "",
  });


  const handleChange=()=>{
    console.log("first")
  }

  const handleFormSubmit1=()=>{
    console.log("first")
  }

  const handleMemNext = (e)=>{
    setIsMemorialFormSelected('Mem')
    // handleNext(e)
  }

  const toggleClass = () => {
    setCustumClass((prevClass) => (prevClass === "hidden" ? "" : "hidden"));
  };

  const toggleClass1 = () => {
    setCustumClass1((prevClass) => (prevClass === "hidden" ? "" : "hidden"));
  };


  return (
    <>
    <ToastContainer/>
      <Header />
      <>
      <div className="mt-[20px]">
      <h1 className="text-center">You will get it in the next @version.</h1>

      </div>
      </>
     <Footer/>
    </>
    
  );
};

export default Page;








